import { Direccion } from "../interfaces/direccion.interface";
import { DireccionNormalizadaMerlin } from "../interfaces/merlin/direccionNormalizada.interface";
import { LOCALIDADES_EXCLUIDAS } from "./constants";

/**
 * Metodo encargado de darle formato a la direccion completa que se va a mostrar
 * @param direccion
 */
export const formatDirection = (direccion) => {
    let departamento = direccion.departamento ? `${direccion.altura || ''} ${direccion.departamento || ''},` : `${direccion.altura || ''},`
    let piso = direccion.piso ? `Piso ${direccion.piso},` : '';
    return `${direccion.calle} ${departamento} ${piso} ${direccion.barrio}`
}

/**
 * Metodo encargado de darle formato a la direccion normalizada para persistir en bdd
 * @param direccionNormalizada
 */
export const formatDirectionNormalizada = (direccionNormalizada: DireccionNormalizadaMerlin) => {
    let direccion = {} as Direccion;
    direccion.altura =  String(direccionNormalizada.numero);
    direccion.barrio = "";
    if (direccionNormalizada.barrio !== undefined || direccionNormalizada.barrio !== null || direccionNormalizada.barrio !== "") {
        direccion.barrio = direccionNormalizada.barrio;
    }
    direccion.calle = direccionNormalizada.calle;
    direccion.codigoPostal = direccionNormalizada.codigoPostal;
    direccion.latitud = direccionNormalizada.latitud;
    direccion.longitud = direccionNormalizada.longitud;
    direccion.entreCalleUno = direccionNormalizada.primeraCalleDelimitante;
    direccion.entreCalleDos = direccionNormalizada.segundaCalleDelimitante;
    direccion.localidad = direccionNormalizada.localidad;
    direccion.posicionExacta = true;
    return direccion;
}

/**
 * Metodo que agrega los atributos restantes a la direccion luego de ser normalizada por
 * el mago merlin utilizando los valores del input
 * @param direccion 
 * @returns 
 */
export const completeDirection = (direccion, direccionInput) => {
    return direccion = {
        ...direccion,
        provincia: direccionInput.provincia, 
        piso: direccionInput.piso,
        observaciones: direccionInput.observaciones,
        departamento: direccionInput.departamento,
        seleccionado : true
    }
}

/**
 * Encargada de eliminar la localidad Capital Federal y Ciudad Autonoma de Buenos Aires del storage
 */
export const eliminarLocalidadStorage = (storageManager) => {
    storageManager.getDataStorage("provincias").then( provinciasFromStorage => {
        let provinciasParse = JSON.parse(provinciasFromStorage);
        const provincia = provinciasParse.find((provincia) => {
            return (provincia.nombre === 'BUENOS AIRES')
        });
        const localidadesFiltradas = provincia.localidades.filter((localidad) => {
            return !(LOCALIDADES_EXCLUIDAS.includes(localidad.nombre))
        });
        const provinciaModificada = { ...provincia, localidades: localidadesFiltradas };
        const provinciasActualizadas = provinciasParse.map((provincia) =>
            provincia.nombre === "BUENOS AIRES" ? provinciaModificada : provincia
        );
        storageManager.setDataStorage("provincias", JSON.stringify(provinciasActualizadas));
    })
}