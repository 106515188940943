import { AxiosError } from "axios";
import { ApiError } from "../interfaces/api.error.interface";

/**
 * Crea un objeto ApiError, en base al error de Axios pasado como argumento.
 * @param error El error devuelto al consumir una API haciendo uso de la libreria Axios.
 * @returns Un objeto conteniendo los datos relevantes de la petición ejecutada con error.
 */
export const formatearError = (error: AxiosError): ApiError => {
    return {
        request: {
            url: error.config.url,
            method: error.config.method,
            body: error.config.data,
            headers: error.config.headers,
            sended: error.request ? true : false
        },
        response: {
            status: error.response?.status,
            body: error.response?.data
        },
    };
}