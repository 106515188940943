import { IonCol, IonContent, IonFooter, IonGrid, IonPage, IonRow, IonToolbar } from '@ionic/react'
import { Button, InputText, Modal, Subtitulo, TextLink, Titulo } from 'app-principal-componentes-visuales'
import React, { useContext, useState } from 'react'
import ModalEditarDatos from '../../components/Modals/EditarDatos/EditarDatos'
import { AsistenciaMedicaContext } from '../../contexts/AsistenciaMedicaContext'
import { AsistenciaMedicaActionType } from '../../interfaces/global-state/asistencia.medica.action.type.enum'
import { VERIFICAR_DATOS_CONTACTO, VERIFICAR_DATOS_UBICACION } from '../../utils/constants'
import { capitalizeString, formatearNumeroInputCelular } from '../../utils/utils'
import './VerificarDatosContacto.scss'
import SessionHeader from '../../components/Headers/SessionHeader/SessionHeader'
import Footer from '../../components/Footer/Footer'
import PageWrapper from '../../components/PageWrapper/PageWrapper'

const VerificarDatosContacto = ({ history }) => {
    
    const { asistenciaMedica, dispatchAsistenciaMedica, isWeb } = useContext(AsistenciaMedicaContext);
    const socio = asistenciaMedica.socioSeleccionado;
    const { telefono, email } = socio;
    const [showEditarDatosContactoModal, setShowEditarDatosContactoModal] = useState(false);
    const isPrincipal = socio.contratos !== undefined;

    const openModalContacto = () => {
        setShowEditarDatosContactoModal(!showEditarDatosContactoModal);
    };

    const handleUpdate = () => {
        openModalContacto();
    };

    const handleContinuar = () => {
        history.push(VERIFICAR_DATOS_UBICACION);
    }

    return (
        <>
            <PageWrapper pageClassName='asistencia-verificar-datos-contacto' className='verificar-datos-grid' socio={asistenciaMedica.usuario} path={VERIFICAR_DATOS_CONTACTO} >
                <div>
                    <IonRow>
                        <IonCol size='12'>
                            <Titulo
                                className='titulo-confirma-datos'
                                titulo={isPrincipal
                                    ? 'Confirmá los datos para esta asistencia'
                                    : `Confirmá los datos de contacto de ${capitalizeString(socio.nombre)} para esta asistencia`
                                }
                                id="titulo-asistencia-confirma-datos"
                            />
                            <Subtitulo
                                titulo="Nos permitirán contactarte en caso de que sea necesario para resolver esta solicitud."
                                txt16
                                id="subtitulo-solicitud"
                            />
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size='12'>
                            <InputText
                                id='verificar-datos-input-telefono'
                                label='Teléfono celular'
                                type='text'
                                value={!socio.telefono ? " - " : formatearNumeroInputCelular(socio.telefono)}
                                disable
                            />
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size='12'>
                            <InputText
                                type="text"
                                label="Email"
                                value={!socio.email ? " - " : socio.email}
                                id='verificar-datos-input-email'
                                disable
                            />
                            <TextLink
                                id="verificar-datos-editar"
                                onClick={openModalContacto}
                                right
                                texto="Editar"
                            />
                        </IonCol>
                    </IonRow>
                </div>
                <Button
                    id='datos-contacto-continuar'
                    label="Continuar"
                    onClick={handleContinuar}
                    primary
                    className={ isWeb && 'verificar-datos-contactos_button'}
                    disable={!email || !telefono ? true : false}
                />
            </PageWrapper>
            <Modal
                className='modal_web'
                hideCloseBtn={false}
                //@ts-ignore
                component={<ModalEditarDatos selectedAssociate={socio} modifyAssociate={handleUpdate} dispatchAsistenciaMedica={dispatchAsistenciaMedica}/>}
                setShowModal={setShowEditarDatosContactoModal}
                showModal={showEditarDatosContactoModal}
                id="datos-contacto-modal"
            />
        </>
    )
}
export default VerificarDatosContacto