import { getServicioFromBackend } from "../api/servicio";

export const serviciosHabilitados = (apiRequest) => {
    return new Promise<any>(async (resolve, reject) => { 
        try {
            let serviciosHabilitados = await getServicioFromBackend(apiRequest);
            let serviciosHabilitadosFiltrados = []

            serviciosHabilitados.forEach((servicio) => {
            servicio.clasificacion.forEach((clasificacion) => {
                if (clasificacion.disponibilidad.activa === true) {
                    serviciosHabilitadosFiltrados.push(clasificacion);
                } 
            });
            resolve(serviciosHabilitadosFiltrados);
        })
        } catch(error) {
            reject(error);
        }
    })
}