import React from 'react'
import { IonCol, IonRow } from '@ionic/react'
import { Skeleton } from 'app-principal-componentes-visuales'
import './AtencionesFinalizadasSkeleton.scss'

const AtencionesFinalizadasSkeleton = () => {
    return (
        <div className='atenciones__finalizadas__skeleton'>
            <IonRow>
                <IonCol className="atencion" size="12">
                    <Skeleton height={130} />
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol className="atencion" size="12">
                    <Skeleton height={130} />
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol className="atencion" size="12">
                    <Skeleton height={130} />
                </IonCol>
            </IonRow>
        </div>
    )
}

export default AtencionesFinalizadasSkeleton