import React from "react"
import { IonCol, IonRow } from "@ionic/react"
import { Separador, Skeleton } from "app-principal-componentes-visuales"
import "./AntecedentesSkeleton.scss"

const AntecedentesSkeleton = () => (
	<>
		<IonCol size="12" className="asistencia-antecedentes-skeleton">
			<IonRow>
				<IonCol className="title__col" size="12">
					<Skeleton height={35} />
				</IonCol>
				<IonCol className="grid__col" size="12">
					<Skeleton className="radio-item" height={50} />
                    <Skeleton className="radio-item" height={50} />
                    <Skeleton className="radio-item" height={50} />
                    <Skeleton className="radio-item" height={50} />
                    <Skeleton className="radio-item" height={50} />
                    <Skeleton className="radio-item" height={50} />
                    <Skeleton className="radio-item" height={50} />
                    <Skeleton className="radio-item" height={50} />
				</IonCol>
				<IonCol size="12">
					<Separador />
				</IonCol>
				<IonCol size="12">
					<Skeleton height={50} />
				</IonCol>
			</IonRow>
		</IonCol>
	</>
)

export default AntecedentesSkeleton