import { IonCol, IonRow } from '@ionic/react';
import { Modal, Subtitulo, TextArea } from 'app-principal-componentes-visuales';
import React, { useState } from 'react';
import ObservacionesAdicionales from '../Modals/ObservacionesAdicionales/ObservacionesAdicionales';

const PreguntaObservacion = ({ handleObservacion, observaciones }) => {

    const [observacionText, setObservacionText] = useState(observaciones);
    const [showObservacionModal, setShowObservacionModal] = useState(false);

    const onFocusTextArea = () => {
        setShowObservacionModal(true);
    }

    const changeTextHandler = (text) => {
        handleObservacion(text)
    }

    return (
        <IonRow>
            <IonCol size='12'>
                <Subtitulo
                    txt18
                    titulo={'¿Hay algo adicional que quieras comentarnos?'}
                    id="subtitulo-adicional"
                />
                <Subtitulo
                    txt16
                    titulo={'Toda información extra nos ayudará a brindar una mejor asistencia.'}
                    id="subtitulo-info"
                />
                <div onClick={onFocusTextArea}>

                
                <TextArea
                    label="Observaciones adicionales"
                    maxLength={200}
                    placeholder="Ingresá otros síntomas u observaciones"
                    value={observacionText}
                    disabled={true}
                    id="text-area-observaciones-adicionales"
                />

                </div>
                <Modal
                    className='modal_web'
                    hideCloseBtn={false}
                    //@ts-ignore
                    component={<ObservacionesAdicionales 
                        setShowObservacionModal={setShowObservacionModal} 
                        observacionText={observacionText} 
                        setObservacionText={setObservacionText}
                        changeTextHandler={changeTextHandler}
                        />}
                    setShowModal={setShowObservacionModal} 
                    showModal={showObservacionModal}
                    id="preguntas-observacion-modal"
                />
            </IonCol>
        </IonRow>
    )
}

export default PreguntaObservacion;