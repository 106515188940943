import { sendCarritoAbandonadoMessage } from "../api/mensajeriaService";
import { Whatsapp } from "../interfaces/whatsapp.interface";
import { serviciosEMAceptados, serviciosICAceptados, serviciosVMAceptados, SERVICIO_EM, SERVICIO_IC, SERVICIO_VM } from "../utils/constants";
import { armadoMensajeCarritoAbandonado, mensajeEsMenor } from "../utils/whatsappUtils";

export const carritoAbandonado = (asistenciaMedica, preguntas, apiRequest, storageManager, esMenor) => {
    const { socioSeleccionado } = asistenciaMedica;

    let casosEnCurso = [];
    let flag = true;
    
    storageManager.getDataStorage("casosEnCurso").then(casos => {
        casosEnCurso = JSON.parse(casos);        

        if(casosEnCurso?.length > 0) {
            casosEnCurso.forEach(caso => {
                if (
                    caso.numeroSocio === socioSeleccionado?.nroContrato &&
                        (
                            (caso.codigoTipoServicio === SERVICIO_VM && serviciosVMAceptados.includes(caso.codigoSubTipoServicio)) ||
                            (caso.codigoTipoServicio === SERVICIO_IC && serviciosICAceptados.includes(caso.codigoSubTipoServicio)) ||
                            (caso.codigoTipoServicio === SERVICIO_EM && serviciosEMAceptados.includes(caso.codigoSubTipoServicio))
                        )
                ) {
                    flag = false;
                }
            });
        };
                
        if(flag) {
            let mensaje: Whatsapp = armadoMensajeCarritoAbandonado(asistenciaMedica, preguntas);
            mensaje.esMenor = esMenor;
            sendCarritoAbandonadoMessage(mensaje, apiRequest)
                .then(() => {
                    console.info("Mensaje saliente persistido en la db exitosamente");
                }).catch(error => {
                    console.error("Error al persistir mensaje saliente en la db", error);
                });
        };
    })
}

export const whatsappEsMenor = (socioSeleccionado, socioPrincipal, apiRequest) => {
    let mensaje: Whatsapp = mensajeEsMenor(socioSeleccionado, socioPrincipal);
    sendCarritoAbandonadoMessage(mensaje, apiRequest)
        .then(() => {
            console.info("Mensaje saliente persistido en la db exitosamente");
        }).catch(error => {
            console.error("Error al persistir mensaje saliente en la db", error);
        });
}