import { IonCol, IonContent, IonFooter, IonGrid, IonPage, IonRow } from "@ionic/react";
import { Button, Checkbox, Modal, Separador, Subtitulo, Titulo } from "app-principal-componentes-visuales";
import React, { useContext, useEffect, useState } from "react";
import { getSintomas } from "../../api/asistenciaMedicaService";
import Contactanos from "../../components/Modals/Contactanos/Contactanos";
import SintomasSkeleton from "../../components/Skeletons/SintomasSkeleton/SintomasSkeleton";
import { AsistenciaMedicaContext } from "../../contexts/AsistenciaMedicaContext";
import { AsistenciaMedicaActionType } from "../../interfaces/global-state/asistencia.medica.action.type.enum";
import { Sintoma } from "../../interfaces/sintoma.interface";
import { FACTOR_CLAVE_ID, PREGUNTAS, TITULO_INFORMACION_IMPORTANTE, SINTOMAS } from "../../utils/constants";
import { capitalizeString, formatearTelefono } from "../../utils/utils";
import "./Sintomas.scss";
import { GA_KEYS, mapGA } from "../../helpers/GTM";
import SessionHeader from "../../components/Headers/SessionHeader/SessionHeader";
import Footer from "../../components/Footer/Footer";
import PageWrapper from "../../components/PageWrapper/PageWrapper";

const Sintomas = ({ history }) => {
	const { useRequest, asistenciaMedica, storageManager, dispatchAsistenciaMedica, setupData } = useContext(AsistenciaMedicaContext);
    const { apiRequest } = useRequest();
	const { datosParaTriage } = asistenciaMedica;
	const socio = asistenciaMedica.socioSeleccionado;
	const direccionSeleccionada = asistenciaMedica?.direccionSeleccionada;
	const isPrincipal = socio.contratos !== undefined;
	const sintomasContexto = !datosParaTriage ? [] : datosParaTriage.sintomasSeleccionados;
	const [selectedSymtoms, setSelectedSymtoms] = useState([]);
	const [symptoms, setSymptoms] = useState([]);
	const [noEncuentraSintoma, setNoEncuentraSintoma] = useState(null);
	const [showContactanosModal, setShowContactanosModal] = useState(false);
	const [sintomasSkeleton, setSintomasSkeleton] = useState(true);

	/**
     * Realiza la peticion al servicio de sintomas.
     * En caso que dicha peticion falle, se recuperan del storage
     */
	useEffect(() => {

		getSintomas(apiRequest).then( sints => {
			inicializarSintomas(sints);
		}).catch( error => {
			console.error("ASIS-MED - Error al inicializar listado de sintomas", error);
			storageManager.getDataStorage("sintomas").then( sintomasFromStorage => {
				if ( sintomasFromStorage !== null ) {
					inicializarSintomas(JSON.parse(sintomasFromStorage));
				}
			});
		});

	}, []);

	useEffect(() => {

		if(selectedSymtoms.length > 0){

			let sintomasSeleccionados = selectedSymtoms.map((symp) => ({ descripcion: symp.descripcion, prioridad: symp.prioridad }));
	
			dispatchAsistenciaMedica({
				type : AsistenciaMedicaActionType.actualizarSintomas,
				sintomas: sintomasSeleccionados
			})
		}
	  

	}, [selectedSymtoms])
	
	let depto = direccionSeleccionada.departamento ? direccionSeleccionada.departamento : ' - ';
    let piso = direccionSeleccionada.piso ? direccionSeleccionada.piso : ' - ';
    let barrio = direccionSeleccionada.barrio ? direccionSeleccionada.barrio + ", " : '';
    let entrecalles = "";
	if (direccionSeleccionada.entreCalleUno && direccionSeleccionada.entreCalleDos) {
		entrecalles = `*Entrecalles:* ${direccionSeleccionada.entreCalleUno} y ${direccionSeleccionada.entreCalleDos}.`;
	}
	const mensajeFactores = 
		socio.factores[0].id !== FACTOR_CLAVE_ID
			? "*Antecedentes:* " + socio.factores.map(factor => factor.descripcion).join(", ") + "."
			: `Sin Antecedentes de Salud.`;
	let mensaje = `Ingresé a la App de OSDE para solicitar una asistencia m\u00e9dica de Urgencias para ` +
	`*${socio.nombre}*, Credencial Nro. ${socio.nroContrato}\r\n. `+ mensajeFactores + 
	` NO encontr\u00e9 el s\u00edntoma.\r\n` +
	`*Domicilio:* ${direccionSeleccionada.calle}, ${direccionSeleccionada.altura}, *Piso:* ${piso}, *Depto:* ${depto}, ${barrio} ${direccionSeleccionada.localidad}, ${direccionSeleccionada.provincia}. ${entrecalles}\r\n` +
	`*Datos de Contacto*: ${socio.email}, N\u00famero de celular: ${formatearTelefono(socio.telefono)}`;

	/**
     * Encargado de filtrar los sintomas visibles y agregar el campo checked a los radio buttons del listado de sintomas con los seleccionados retornados por el contexto
     */
	const inicializarSintomas = (sints: Sintoma[]) => {
		let auxList = []; 
		let sintomasPrincipales = sints
		.filter( sintoma => sintoma.id !== 9 )
		.map( sintoma => {
			if (sintomasContexto.length > 0) {
				const sintomaEnContexto = Boolean(sintomasContexto.find((sintomasContexto) => (sintomasContexto.descripcion == sintoma.descripcion && sintoma.visible))); 
				if (sintomaEnContexto) {
					auxList.push(sintoma);
					return { ...sintoma, checked: true }
				}
			}
			return { ...sintoma, checked: false }
		})
		.sort((a,b) => (a.descripcion > b.descripcion) ? 1 : ((b.descripcion > a.descripcion) ? -1 : 0));
		setSelectedSymtoms(auxList);
		setSymptoms(sintomasPrincipales);			
		let sintomaNoEncontrado = sints.find((sintoma) => {
			return sintoma.id === 9;
		});
		setNoEncuentraSintoma(sintomaNoEncontrado);
		setSintomasSkeleton(false);
	}

	const onItemCheck = (e, sintoma) => {

		if (e.detail.checked) {
			let auxArray = symptoms.map((symp) => {
				if (symp.descripcion === e.detail.value) {
					return {
						...symp,
						checked: true,
					};
				}

				return symp;
			});
			setSymptoms(auxArray);
			setSelectedSymtoms([...selectedSymtoms, sintoma]);
		} else {
			let auxArray = symptoms.map((symp) => {
				if (symp.descripcion === e.detail.value) {
					return {
						...symp,
						checked: false,
					};
				}

				return symp;
			});

			setSymptoms(auxArray);

			const filteredArray = selectedSymtoms.filter((symp) => {
				return symp.descripcion !== e.detail.value;
			});
			setSelectedSymtoms(filteredArray);
		}

	};

	const onButtonClick = () => {

		let sintomas = ''
		
        selectedSymtoms.forEach( (sint, i) => {
			if(i + 1 < selectedSymtoms.length ){
				
				sintomas += sint.id + ','
            }else {
				sintomas += sint.id + ''
            }
        })
		
		const configGA = {
			sintomas
		}

		mapGA(GA_KEYS.SINTOMAS_SCREEN_ACTION, setupData, configGA)

		let prioridad = 0;
		let preguntas;
		let primeraIteracion = true;
		let sintomaConPrioridad = ""
		for (let i = 0; i < selectedSymtoms.length; i++) {
			const sintoma = selectedSymtoms[i];
			if(primeraIteracion){
				primeraIteracion = false;
				prioridad = sintoma.prioridad;
				preguntas = sintoma.preguntas;
			} else if (sintoma.prioridad < prioridad) {
				prioridad = sintoma.prioridad;
				preguntas = sintoma.preguntas;
				sintomaConPrioridad = sintoma.descripcion
			}
		}

		let sintomasSeleccionados = selectedSymtoms.map((symp) => ({ descripcion: symp.descripcion, prioridad: symp.prioridad }));
		
		dispatchAsistenciaMedica({
			type : AsistenciaMedicaActionType.actualizarSintomas,
			sintomas: sintomasSeleccionados
		})

		history.push(PREGUNTAS, {preguntas, sintomaConPrioridad});
	};

	const onNoSymptomsClick = () => {

		setShowContactanosModal(!showContactanosModal);
		let auxArray = symptoms.map((symp) => {
			return {
				...symp,
				checked: false,
			};
		});
		setSymptoms(auxArray)
		setSelectedSymtoms([])
	};

	return (
		<>
			<PageWrapper pageClassName='asistencia-sintomas' socio={asistenciaMedica.usuario} path={SINTOMAS}>
				{sintomasSkeleton ? 
					( <SintomasSkeleton /> )
					:
					<>
						<IonRow>
							<Titulo
								className="seleccionar-socio__title"
								titulo={`Por favor, seleccioná los sintomas${!isPrincipal ? ` de ${capitalizeString(socio.nombre)} ` : ""}`}
								id="titulo-asitencia-solicitar-socio"
							/>
						</IonRow>
						<IonRow>
							<Subtitulo 
								txt16 
								titulo={"Tené en cuenta que solo podés seleccionar hasta 3."} 
								id="subtitulo-cuenta"
							/>
						</IonRow>
						<IonRow>
							<IonCol size="12">
								{symptoms.map((sintoma) => {
									if (sintoma.visible) {
										return (
											<Checkbox
												btnBlue
												label={sintoma.descripcion}
												onIonChange={(e) => {
													onItemCheck(e, sintoma);
												}}
												value={sintoma.descripcion}
												checked={sintoma.checked}
												key={sintoma.id}
												disabled={ sintoma.checked ? false : selectedSymtoms.length === 3}
											/>
										);
									} else {
										return null;
									}
								})}
							</IonCol>
						</IonRow>
						<IonRow>
							<Separador />
							<IonCol size="12">
								{noEncuentraSintoma && (
									<Checkbox
										btnBlue
										label={noEncuentraSintoma.descripcion}
										onIonChange={onNoSymptomsClick}
										checked={showContactanosModal}
										id="checkbox-sin-sintomas"
									/>
								)}
							</IonCol>
						</IonRow>
						<IonRow className='footer__sintomas ion-no-border'>
							<IonCol>
								<Button 
									id="sintomas-continuar"
									className='footer__button' 
									disable={selectedSymtoms.length === 0 || selectedSymtoms.length > 3} 
									primary 
									label="Continuar" 
									onClick={onButtonClick} />
							</IonCol>
						</IonRow>
					</>
				}
			</PageWrapper>
			
			<Modal
				className="modal_web"
				hideCloseBtn={false}
				//@ts-ignore
				component={<Contactanos setupData={setupData} screen={'sintomas'} menorSeisMeses={false} sintomas texto={mensaje} titulo={TITULO_INFORMACION_IMPORTANTE}/>}
				setShowModal={setShowContactanosModal}
				showModal={showContactanosModal}
				id="contactanos"
			/>
		</>
	);
};

export default Sintomas;
