import React, { useEffect, useState } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import Antecedentes from '../../pages/Antecedentes/Antecedentes';
import { AsistenciaMedica } from '../../pages/AsistenciaMedica/AsistenciaMedica';
import Cancelar from '../../pages/Cancelar/Cancelar';
import SeleccionarSocio from '../../pages/SeleccionarSocio/SeleccionarSocio';
import VerificarDatosContacto from '../../pages/DatosContacto/VerificarDatosContacto';
import { HOME, SELECCIONAR_SOCIO, FACTORES_RIESGO, VERIFICAR_DATOS_CONTACTO, VERIFICAR_DATOS_UBICACION, SINTOMAS, PREGUNTAS, CANCELAR_SOLICITUD, RESUMEN, PEDIDO_SERVICIO, ASISTENCIA_CREADA, SERVICIO_EN_CURSO, HISTORIAL_ATENCIONES, DETALLE_CASO, TELEFONOS_PAGE, FEEDBACK_SERVICIO_EN_CURSO} from '../../utils/constants';
import SeleccionarDomicilio from '../../pages/SeleccionarDomicilio/SeleccionarDomicilio';
import Sintomas from '../../pages/Sintomas/Sintomas';
import Preguntas from '../../pages/Preguntas/Preguntas';
import Resumen from '../../pages/Resumen/Resumen';
import PedidoServicio from '../../pages/PedidoServicio/PedidoServicio';
import AsistenciaSolicitada from '../../pages/AsistenciaSolicitada/AsistenciaSolicitada';
import ServicioEnCurso from '../../pages/ServicioEnCurso/ServicioEnCurso';
import { AsistenciaMedicaContext } from '../../contexts/AsistenciaMedicaContext';
import { storageManager } from '../../helpers/dataProvider';
import { useRequest } from '../../hooks/webUseRequest';
import { useAsistenciaMedica } from '../../hooks/useAsistenciaMedica';
import ErrorPage from '../../pages/ErrorPage/ErrorPage';
import HistorialAtenciones from '../../pages/HistorialAtenciones/HistorialAtenciones';
import DetalleCaso from '../../pages/DetalleCaso/DetalleCaso';
import SplashScreen from '../../pages/SplashScreen/SplashScreen';
import {setupData} from '../../utils/setupGA'
import TelefonosPage from '../../pages/TelefonosPage/TelefonosPage';
import { sendTracks } from '../../helpers/GA';
import { instanciarGoogleServices } from '../../helpers/googleApi';
import FeedbackServicioEnCurso from '../../pages/FeedbackServicioEnCurso/FeedbackServicioEnCurso';

//historyTo deberia equivaler a la constante
const ProtectedRoutes = ({authInfo, setSessionInfo}) => {
    const history = useHistory()
    const [error, setError] = useState(false)
    const {apiRequest} = useRequest(history, setSessionInfo)
    const {
		asistenciaMedica,
		dispatchAsistenciaMedica
	} = useAsistenciaMedica(authInfo, true, storageManager,  useRequest, setError);
    const [page, setPage] = useState("")

    useEffect(() => {

        if(page.length === 0){

            const configGA = {
                origin: 'router',
                page_location: 'asistenciamedica',
                page_referrer: ''
            }

            sendTracks(configGA)

            setPage('asistenciamedica')
        }

        history.listen((param)=> {

            const screen_name =  param.pathname.replace(/\//g, "_").replace("_", "")
            setPage((prev)=>{

                const configGA = {
                    origin: 'router',
                    page_location: screen_name,
                    page_referrer: prev
                }

                sendTracks(configGA)
                return screen_name
            } )

        } )
    }, [])
    
    useEffect(() => {
        instanciarGoogleServices()
    }, [])

    if(error){
        return <ErrorPage setError={setError} isWebProp={true}/>
    }
    
    //TODO pantalla de error si no hay usuario por falla del servicio
    if(!asistenciaMedica.usuario){
        return <SplashScreen/>
    }

    return (
        <AsistenciaMedicaContext.Provider value={{
            asistenciaMedica,
            dispatchAsistenciaMedica,
            storageReady: true,
            storageManager,
            dataControlDeVersiones: {},
            useRequest: () => ({apiRequest: (options, retry) => apiRequest(options, retry)}),
            setupData,
            authInfo,
            isWeb: true
        }}>
            <Switch>
				<Route exact path={HOME} component={ AsistenciaMedica } />
				<Route exact path={SELECCIONAR_SOCIO} component={ SeleccionarSocio } />
                <Route exact path={FACTORES_RIESGO} component={ Antecedentes } />
                <Route exact path={VERIFICAR_DATOS_CONTACTO} component={ VerificarDatosContacto }/>
                <Route exact path={VERIFICAR_DATOS_UBICACION} component={SeleccionarDomicilio}/>
                <Route exact path={SINTOMAS} component={ Sintomas } />
                <Route exact path={PREGUNTAS} component={ Preguntas }/>
				<Route exact path={RESUMEN} component={ Resumen } />
				<Route exact path={PEDIDO_SERVICIO} component={PedidoServicio}/>
                <Route exact path={FEEDBACK_SERVICIO_EN_CURSO} component={ FeedbackServicioEnCurso } />
				<Route exact path={ASISTENCIA_CREADA} component={AsistenciaSolicitada}/>
				<Route exact path={CANCELAR_SOLICITUD} component={ Cancelar } />
				<Route exact path={SERVICIO_EN_CURSO} component={ ServicioEnCurso } />
				<Route exact path="/" render={() => <Redirect to={HOME} />} />
				<Route exact path={HISTORIAL_ATENCIONES} component={ HistorialAtenciones } />
				<Route exact path={DETALLE_CASO} component={ DetalleCaso } />
				<Route exact path={TELEFONOS_PAGE} component={TelefonosPage} />
			</Switch>
        </AsistenciaMedicaContext.Provider>
    )
}

export default ProtectedRoutes
