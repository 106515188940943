import { IonCol, IonGrid, IonRow } from '@ionic/react'
import { Button, Modal, TextLink } from 'app-principal-componentes-visuales'
import React, { useContext, useEffect, useState } from 'react'
import DatosUbicacion from '../../components/Cards/DatosUbicacion/DatosUbicacion'
import EliminarDomicilio from '../../components/Modals/EliminarDomicilio/EliminarDomicilio'
import FormularioDomicilio from '../../components/Modals/FormularioDomicilio/FormularioDomicilio'
import Normalizacion from '../../components/Modals/Normalizacion/Normalizacion'
import { AsistenciaMedicaContext } from '../../contexts/AsistenciaMedicaContext'
import { normalizarDireccion } from '../../helpers/direccionHelper'
import { Direccion } from '../../interfaces/direccion.interface'
import { CORREGIDO, DUDOSO, NO_ENCONTRADO, VERIFICAR_DATOS_UBICACION } from '../../utils/constants'
import { completeDirection, formatDirectionNormalizada } from '../../utils/ubicacionUtils'
import Geolocalizacion from '../Geolocalizacion/Geolocalizacion'
import './Direcciones.scss'
import SessionHeader from '../../components/Headers/SessionHeader/SessionHeader'
import Footer from '../../components/Footer/Footer'
import { inicioLocationWeb } from '../../helpers/geolocalizacion'
import PageWrapper from '../../components/PageWrapper/PageWrapper'

const Direcciones = ({ direccionesSocioPrincipal, updateDirection, setSeleccionarDomicilio, handleContinueClick, localidadError } : {[ key: string ]: any }) => {

    const [ showFormularioDomicilioModal, setFormularioDomicilioModal ] = useState(false);
    const [ showDeleteDirectionModal, setDeleteDirectionModal ] = useState(false);
    const [ selectedDirection, setSelectedDirection ] = useState({});
    const [ direccionesSocio, setDireccionesSocio ] = useState(direccionesSocioPrincipal);
    const [ editPlace, setEditPlace ] = useState(false);
    const [ modalDelete, setModalDelete ] = useState('');
    const [ stateMerlin, setStateMerlin ] = useState(undefined);
    const [ direccionInputState, setDireccionInputState ] = useState(null)
    const [ showNormalizacionModal, setShowNormalizacionModal ] = useState(false);
    const [ edicionDireccion, setEdicionDireccion ] = useState(false);
    const [ selectedOption, setSelectedOption ] = useState<Direccion>(null);
    const { useRequest, storageManager, asistenciaMedica, isWeb } = useContext(AsistenciaMedicaContext);
    const { apiRequest } = useRequest();
    const [ merlinWaiting, setMerlinWaiting ] = useState(false);
    const [geolocalizacion, setGeolocalizacion] = useState(null);
    // const [selectedGeolocalization, setSelectedGeolocalization] = useState(false);

    useEffect(() => {
        if(isWeb){
            inicioLocationWeb(storageManager).then( res => {
                setGeolocalizacion(res);
            } ).catch(error => {
                setGeolocalizacion(null)
                console.error("Ocurrió un problema al recuperar la geolocalización", error);
            })
        }else{
            storageManager.getDataStorage("geolocalizacion").then(geo => {
                let storageGeo = JSON.parse(geo);
                setGeolocalizacion(storageGeo);
            });
        }
        setDireccionesSocio(direccionesSocioPrincipal);
    }, [])

    useEffect(() => {
        let seleccionada = direccionesSocioPrincipal.find((direccion) => direccion.seleccionado === true);
        setSelectedOption(seleccionada);
    }, [direccionesSocioPrincipal, direccionesSocioPrincipal.length])


    const handleNormalizacion = (data) => {
        if (stateMerlin.status == DUDOSO) {
            normalizarDireccion({
                barrio: direccionInputState.barrio,
                calle: data.calle,
                localidad: direccionInputState.localidad,
                altura: direccionInputState.altura,
                provincia: direccionInputState.provincia
            }, apiRequest)
                .then((responseMerlin) => {
                    let direccionFormateada: Direccion = formatDirectionNormalizada(responseMerlin.direccionNormalizada);
                    direccionFormateada = completeDirection(direccionFormateada, direccionInputState);
                    let direccionesAux: Direccion[] = direccionesSocio;
                    direccionesAux.forEach(direccion => {
                        direccion.seleccionado = false
                    });
                    direccionesAux.push(direccionFormateada);
                    updateDirection(direccionesAux);
                    setShowNormalizacionModal(false);
                    setFormularioDomicilioModal(!showFormularioDomicilioModal);
                })
        }
        if (stateMerlin.status === NO_ENCONTRADO) {
            setShowNormalizacionModal(false);
        }
        if (stateMerlin.status === CORREGIDO) {
            let direccionFormateada = formatDirectionNormalizada(stateMerlin.direccionNormalizada);
            direccionFormateada = completeDirection(direccionFormateada, direccionInputState);
            let direccionesAux: Direccion[] = direccionesSocio;
            direccionesAux.forEach(direccion => {
                direccion.seleccionado = false
            });
            direccionesAux.push(direccionFormateada);
            setSelectedDirection(direccionInputState);
            updateDirection(direccionesAux);
            setShowNormalizacionModal(false);
            setFormularioDomicilioModal(!showFormularioDomicilioModal);
        }
    }

    /**
     * Encargado de abrir/cerrar el modal del formulario
     */
    const openFormularioModal = (selectedDirection) => {
        setSelectedDirection(selectedDirection);
        setFormularioDomicilioModal(!showFormularioDomicilioModal);
    }

    /**
     * Encargado de abrir/cerrar el modal de eliminacion de direcciones
     */
    const openDeleteDirection = (selectedDirection, modal) => {
        setModalDelete(modal);
        setSelectedDirection(selectedDirection);
        setDeleteDirectionModal(!showDeleteDirectionModal);
    }

    /**
     * Metodo que agrega al estado de direcciones del socio principal la nueva direccion
     */
    const
    addDirection = (newDireccion) => {
        setEdicionDireccion(false);
        setMerlinWaiting(true);
        setDireccionInputState(newDireccion);
        normalizarDireccion(newDireccion, apiRequest)
            .then((responseMerlin) => {
                setStateMerlin(responseMerlin);
                setMerlinWaiting(false);
                setShowNormalizacionModal(true);
            } )
            .catch((error) => console.error(error));
    }

    /**
     * Metodo que modifica al estado de direcciones del socio principal con la direccion modificada
     */
    const modifyDirection = (direccionModificada) => {
        setEdicionDireccion(true);
        setMerlinWaiting(true);
        setDireccionInputState(direccionModificada);
        normalizarDireccion(direccionModificada, apiRequest)
            .then((responseMerlin) => {
                setStateMerlin(responseMerlin);
                setMerlinWaiting(false);
                setShowNormalizacionModal(true);
            })
    }

    /**
     * Reutilización del metodo onlick del modal de normalización para la edicion de una direccion
     */
    const handleNormalizacionEditar = (data) => {
            if (stateMerlin.status == DUDOSO) {
                normalizarDireccion({
                    barrio: direccionInputState.barrio,
                    calle: data.calle,
                    localidad: direccionInputState.localidad,
                    altura: direccionInputState.altura,
                    provincia: direccionInputState.provincia
                }, apiRequest)
                    .then((responseMerlin) => {
                        let direccionFormateada: Direccion = formatDirectionNormalizada(responseMerlin.direccionNormalizada);
                        direccionFormateada = completeDirection(direccionFormateada, direccionInputState);
                        direccionFormateada.id = direccionInputState.id;
                        let direction = direccionesSocio.map(direccion => {
                            return direccion.id === direccionFormateada.id ? direccionFormateada : direccion;
                        })
                        setDireccionesSocio(direction);
                        updateDirection(direction);
                        setShowNormalizacionModal(false);
                        setFormularioDomicilioModal(!showFormularioDomicilioModal);
                    })
            }
            if (stateMerlin.status === NO_ENCONTRADO) {
                setShowNormalizacionModal(false);
            }
            if (stateMerlin.status === CORREGIDO) {
                let direccionFormateada = formatDirectionNormalizada(stateMerlin.direccionNormalizada);
                direccionFormateada = completeDirection(direccionFormateada, direccionInputState);
                direccionFormateada.id = direccionInputState.id;
                let direction = direccionesSocio.map(direccion => {
                    return direccion.id === direccionFormateada.id ? direccionFormateada : direccion;
                })
                setDireccionesSocio(direction);
                updateDirection(direction);
                setShowNormalizacionModal(false);
                setFormularioDomicilioModal(!showFormularioDomicilioModal);
            }
    }

    /**
     * Metodo que elimina la direccion seleccionada del socio principal
     */
    const deleteDirection = (direccionId, deleteDirection) => {
        let direccionSinSeleccionado = [];
        const direccionFiltrada = direccionesSocio.filter(direccion => {
            return direccionId !== direccion.id
        });

        if(direccionId === selectedOption.id && direccionFiltrada.length !== 0) {
            direccionSinSeleccionado = direccionFiltrada.map((direccion, index) => {
                if(index === direccionFiltrada.length - 1) {
                    let dirSelected = {...direccion, seleccionado: true }
                    setSelectedOption(dirSelected);
                    return dirSelected
                }
                return direccion
            });
        }

        if(deleteDirection === 'formulario') {
            setFormularioDomicilioModal(!showFormularioDomicilioModal)
        }

        if(direccionSinSeleccionado.length > 0) {
            setDireccionesSocio(direccionSinSeleccionado);
            updateDirection(direccionSinSeleccionado);
        } else {
            setDireccionesSocio(direccionFiltrada);
            updateDirection(direccionFiltrada);
        }
        setDeleteDirectionModal(!showDeleteDirectionModal);
    }

    const editLocation = () => {
        setEditPlace(!editPlace);
    }

    const returnSelectedDirection = (seleccionado) => {
        setSelectedOption(seleccionado);
    };

    const handleCloseModal = () => {
        if (stateMerlin.status == DUDOSO) {
            setShowNormalizacionModal(false);
        } else{
            let direccionesAux: Direccion[] = direccionesSocio;
            direccionesAux.forEach(direccion => {
                direccion.seleccionado = false
            });
            direccionesAux.push(direccionInputState);
            setShowNormalizacionModal(false);
            setSelectedDirection(direccionInputState);
            updateDirection(direccionesAux);
            setFormularioDomicilioModal(!showFormularioDomicilioModal);
        }
    }
    const handleCloseModalEdit = () => {
        if (stateMerlin.status == DUDOSO) {
            setShowNormalizacionModal(false);
        } else {
            let direction = direccionesSocio.map(direccion => {
                return direccion.id === direccionInputState.id ? direccionInputState : direccion;
            })
            setDireccionesSocio(direction);
            updateDirection(direction);
            setShowNormalizacionModal(false);
            setFormularioDomicilioModal(!showFormularioDomicilioModal)
        }
    }

    const handleBack = () => {
        direccionSeleccionada();
        setSeleccionarDomicilio(false);
    }

    const handleContinuarClick = () => {
        direccionSeleccionada();
        handleContinueClick();
    }

    const direccionSeleccionada = () => {
        let direccionAux:Direccion[] = direccionesSocio;
        direccionAux.forEach(direccion => {
            direccion.seleccionado = (direccion.id === selectedOption.id)
        });

        setDireccionesSocio(direccionAux);
        updateDirection(direccionAux);
    }

    // Se oculta temporalmente la geolocalizacion
    //
    // const handleClickGeo = () => {
    //     setSelectedGeolocalization(true);
    // }
    
    // if(selectedGeolocalization) {
    //     return (
    //         <>
    //             <Geolocalizacion geolocalizacion={geolocalizacion}
    //                             setSelectedGeolocalization={setSelectedGeolocalization}
    //                             direccionesSocioPrincipal = {direccionesSocio}
    //                             updateDirection = {updateDirection}
    //             />
    //         </>
    //     )
    // } else {
        return (
            <>
                { direccionesSocio.length === 0 ?
                    <PageWrapper 
                        pageClassName='asistencia-medica-direcciones'
                        path={VERIFICAR_DATOS_UBICACION}
                        socio={asistenciaMedica.usuario}
                        handlerHandleBack={handleBack}
                        >
                        <IonRow>
                            <IonCol size='12' className='sin-domicilio'>
                                <Button
                                    className='button--ripple'
                                    colorBlack
                                    label="No tenés domicilios registrados"
                                />
                            </IonCol>
                            <IonCol size='12' className='direcciones__col--padding'>
                                <TextLink
                                    onClick={openFormularioModal}
                                    right
                                    texto="Agregar un nuevo domicilio"
                                    id="nuevo-domicilio"
                                />
                            </IonCol>
                        </IonRow>
                    </PageWrapper>
                :
                    <PageWrapper 
                        pageClassName='asistencia-medica-direcciones'
                        path={VERIFICAR_DATOS_UBICACION}
                        socio={asistenciaMedica.usuario}
                        handlerHandleBack={handleBack}
                        className='direcciones_container'
                        noPadding={true}
                        >
                            {/* se deshabilita por requerimiento de urgencias */}
                            {/* <IonRow>
                                <IonCol size='12' className='georeferencia__col'>
                                    <CardNota
                                        address
                                        disabled = {!geolocalizacion}
                                        bgBlue
                                        id="card-nota"
                                        onClick={() => {handleClickGeo()}}
                                        texto="Usar mi ubicación actual"
                                    />
                                </IonCol>
                            </IonRow> */}
                            <IonGrid>
                                <IonRow>
                                    <IonCol size='12'>
                                        <DatosUbicacion
                                            openFormularioModal={openFormularioModal}
                                            direcciones={direccionesSocio}
                                            openDeleteDirection={openDeleteDirection}
                                            returnSelectedDirection = {returnSelectedDirection}
                                            setSelectedOption={setSelectedOption}
                                        />
                                        <TextLink
                                            className='direcciones_padding'
                                            onClick={openFormularioModal}
                                            right
                                            texto="Agregar un nuevo domicilio"
                                            id="nuevo-domicilio"
                                        />
                                    </IonCol>
                                </IonRow>
                            </IonGrid>
                            <IonRow className='direcciones_padding'>
                                <Button
                                    id="seleccionar-domicilio-continuar"
                                    className='seleccionar-domicilio__button'
                                    key="continue_button"
                                    primary
                                    onClick={handleContinuarClick}
                                    label={"Continuar"}
                                    disable={localidadError}
                                />
                            </IonRow>
                        </PageWrapper>
                }

                <Modal
                    hideCloseBtn={editPlace}
                    className='modal_web-large'
                    //@ts-ignore
                    component={
                        <FormularioDomicilio
                            showEliminar = {true}
                            openFormularioModal = {openFormularioModal}
                            merlinWaiting = { merlinWaiting }
                            selectedDirection = { selectedDirection }
                            editLocation = {editLocation}
                            addDirection = {addDirection} modifyDirection = {modifyDirection} openDeleteDirection = {openDeleteDirection} 
                            isWeb={isWeb}
                        />
                    }
                    setShowModal={setFormularioDomicilioModal}
                    showModal={showFormularioDomicilioModal}
                    id="formulario-domicilio-modal"
                />

                <Modal
                    className='modal_web_xl'
                    hideCloseBtn
                    //@ts-ignore
                    component={<EliminarDomicilio openDeleteDirection = {openDeleteDirection} selectedDirection = { selectedDirection } deleteDirection = {deleteDirection} modalDelete = {modalDelete} isWeb={isWeb} />}
                    setShowModal={setDeleteDirectionModal}
                    showModal={showDeleteDirectionModal}
                    id="eliminar-domicilio-modal"
                />
                <Modal
                    className='modal_web'
                    screen60
                    hideCloseBtn
                    //@ts-ignore
                    component={
                        <Normalizacion
                            isWeb={isWeb}
                            onClick={edicionDireccion ? handleNormalizacionEditar : handleNormalizacion}
                            closeModal={edicionDireccion ? handleCloseModalEdit : handleCloseModal}
                            data={stateMerlin}
                        />
                    }
                    setShowModal={setShowNormalizacionModal}
                    showModal={showNormalizacionModal}
                    id="modal-normalizacion"
                />
            </>
        )
    }
// }

export default Direcciones
