import React from 'react'
import { IonPage } from '@ionic/react'
import logo from "../../../assets/logos/logo_osde.svg"
import './SplashScreen.scss'

const SplashScreen = () => {
  return (
    <IonPage>
      <div className="loading-screen">
        <img className="loading-animation" src={logo} alt="logo OSDE" style={{ width: '100px', height: 'auto' }} />
      </div>
    </IonPage>
  )
}

export default SplashScreen