import { IonCol, IonGrid, IonRow } from '@ionic/react';
import { Subtitulo, Texto1Linea } from 'app-principal-componentes-visuales';
import React, { useState } from 'react';
import { capitalizeString } from '../../../utils/utils';
import './DatosPrestadorAccordion.scss';

const DatosPrestadorAccordion = ({casoEnCursoSeleccionado}) => {
    const [datosPrestadorAccordion, setDatosPrestadorAccordion] = useState(true);

    return (
        <>
            <Subtitulo
                accordion
                accordionOpen={datosPrestadorAccordion}
                onClickAccordion={() => setDatosPrestadorAccordion(!datosPrestadorAccordion)}
                titulo="Datos del profesional"
                txt16
                id="subtitulo-datos"
            />
            
            {
                datosPrestadorAccordion && 
                <>
                    <IonGrid>
                        <IonRow className='datos-ubicacion-prestador__row'>
                            <IonCol size='12'>
                                <Texto1Linea
                                    left
                                    texto="Nombre"
                                />
                                <Texto1Linea
                                    colorBlack
                                    left
                                    texto={capitalizeString(casoEnCursoSeleccionado.prestador)}
                                    txt16
                                />
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol size='12'>
                                <Texto1Linea
                                    left
                                    texto="Coseguro"
                                />
                                <Texto1Linea
                                    colorBlack
                                    left
                                    texto={`$${casoEnCursoSeleccionado.coseguro}`}
                                    txt16
                                />
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </>
            }
        </>
    )
}

export default DatosPrestadorAccordion