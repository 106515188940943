import { IonCol, IonContent, IonFooter, IonGrid, IonHeader, IonPage, IonRow } from '@ionic/react'
import { Button, Checkbox, RadioButtonGroup, Separador,  Titulo } from 'app-principal-componentes-visuales'
import React, { useEffect, useState } from 'react'
import { calcularEdad, capitalizeString } from '../../../utils/utils'
import './FiltroHistorialAtenciones.scss'

const FiltroHistorialAtenciones = ({ isWeb, tabSeleccionado, seleccionados, setSeleccionados, setShowGrupoFamiliarModal, setPlaceholder, setPlaceholderFinalizadas, grupoFamiliar, setServiciosEnCursoFilter, serviciosEnCurso, serviciosFinalizados, setServiciosFinalizados, handleHistorial, icFinalizados}) => {

    const [isCheckAll, setIsCheckAll] = useState(seleccionados.length === grupoFamiliar.length);
    const [seleccionadosAux, setSeleccionadosAux] = useState([]);
    const [filtroFinalizadoSeleccionado, setFiltroFinalizadoSeleccionado] = useState(icFinalizados);
    const [familiaresList, setFamiliaresList] = useState([]);

    useEffect(() => {
        setSeleccionadosAux(seleccionados)
        let familiaresRadioButton = [];
        //valido que el socio sea titular o conyuge
        let esTitular = grupoFamiliar[0].contratos[0].parentesco === "Titular" || grupoFamiliar[0].contratos[0].parentesco === "Conyuge";
        //agrego la posicion cero cualquiera sea el caso
        let titularAux = {
            btnBlue: false,
            onClick: optionSelectHandler,
            texto: capitalizeString(grupoFamiliar[0].nombre + ' ' + grupoFamiliar[0].apellidos),
            value: grupoFamiliar[0].ic
            }
        familiaresRadioButton.push(titularAux);
        //obtengo el grupo familiar solo si el socio principal es titular o conyuge
        if (esTitular) {
            grupoFamiliar.forEach((familiar:any) => {
                let esMenor = calcularEdad(familiar.fechaNacimiento) < 18;
                if(esMenor) {
                    let familiarAux = {
                        btnBlue: false,
                        onClick: optionSelectHandler,
                        texto: capitalizeString(familiar.nombre + ' ' + familiar.apellidos),
                        value: familiar.ic
                    }
                    familiaresRadioButton.push(familiarAux);
                }
            });
        }
        setFamiliaresList(familiaresRadioButton);
    }, [])
    
    useEffect(() => {
        setIsCheckAll(seleccionadosAux.length === grupoFamiliar.length);
    }, [seleccionadosAux, grupoFamiliar.length]);

    const handlerSeleccionarSocio =  () => {
        if(tabSeleccionado === "finalizadas") {
            setFiltroFinalizadoSeleccionado(filtroFinalizadoSeleccionado);
            //busco por ic el nombre del socio relacionado
            let nombreFamiliarSeleccionado = grupoFamiliar.filter((familiar) => {
                return familiar.ic === filtroFinalizadoSeleccionado
            });
            let nombreFamiliarCapitalize = capitalizeString(nombreFamiliarSeleccionado[0].nombre + " " + nombreFamiliarSeleccionado[0].apellidos)
            setPlaceholderFinalizadas(nombreFamiliarCapitalize);
            handleHistorial(filtroFinalizadoSeleccionado);
            setShowGrupoFamiliarModal(false);
        } else {
            let servicioEnCursoFiltrado = serviciosEnCurso.filter((servicio) => {
                return seleccionadosAux.some((seleccionado) => {
                    return seleccionado.nroContrato === servicio.principal.nroContrato
                })
            })
    
            setSeleccionados(seleccionadosAux)
            setServiciosEnCursoFilter(servicioEnCursoFiltrado)
            setPlaceholder(handlePlaceholder());
            setShowGrupoFamiliarModal(false);
        }

    }

    const handleSelectAll = () => {
        setIsCheckAll(!isCheckAll);
        isCheckAll ? setSeleccionadosAux([]) : setSeleccionadosAux(grupoFamiliar);
    };

    const isIncluded = socio => seleccionadosAux.some( seleccionado => seleccionado.nroContrato === socio.nroContrato );

    const getNombresSeleccionados = () => {
        return seleccionadosAux.map(
            seleccionado => seleccionado.nombre + ' ' + seleccionado.apellidos
        );
    };

    const handlePlaceholder = () => {
        if (seleccionadosAux.length === grupoFamiliar.length)
            return 'Todo el grupo familiar';
        else {
            const nombres = getNombresSeleccionados();
            return capitalizeString(nombres.join(', '));
        }
    };

    const handleCheck = socio => {
        if (isIncluded(socio)) {
            setSeleccionadosAux(prev => prev.filter(i => i.nroContrato !== socio.nroContrato));
        } else setSeleccionadosAux(prev => [...prev, socio]);
    };

    const handleButtonDisabled = () => {
        if(tabSeleccionado === "finalizadas") {
            return filtroFinalizadoSeleccionado === icFinalizados;
        } else {
            return seleccionadosAux.length === 0;
        }
    }

    const optionSelectHandler = (event) => {
        event.persist();
        setFiltroFinalizadoSeleccionado(event.target.value);
    }

    return (
        <IonPage className='asistencia-medica asistencia-historial-grupo-familiar-modal'>
            <IonHeader className='modal__header'>
                <IonGrid>
                    <IonRow>
                        <IonCol size="12" className="titulo">
                            <Titulo titulo="Filtrar por integrante" id="titulo-asistencia-historial" />
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonHeader>

            <IonContent fullscreen>
                <IonGrid className='modal__grid'>
                    { tabSeleccionado !== "finalizadas" ?
                        <>
                            <IonRow>
                                <IonCol size="12">
                                    <Checkbox
                                        label="Todo el grupo familiar"
                                        checked={isCheckAll}
                                        onClick={handleSelectAll}
                                        value="Todo el grupo familiar"
                                        id="checkobox-grupo-familiar"
                                    />
                                </IonCol>
                            </IonRow> 
                        <Separador />
                        </>
                    :
                        <Separador className='separador_finalizadas'/>
                    }
                    { tabSeleccionado === "finalizadas" ?
                        <IonRow>
                            <IonCol className='filtro-finalizadas__container'>
                                <RadioButtonGroup
                                    id="historial-finalizados-radio"
                                    radioList={ familiaresList }
                                    valueSelected={ filtroFinalizadoSeleccionado }
                                    className='boton-pregunta-container'
                                    titulo=''
                                />
                            </IonCol>
                        </IonRow>
                    :
                        <IonRow>
                            <IonCol className="checkbox__integrantes-grupo-familiar" size="12">
                                {grupoFamiliar.map((socio, i) => (
                                    <Checkbox
                                        key={i}
                                        label={capitalizeString(
                                            socio.nombre + ' ' + socio.apellidos
                                        )}
                                        checked={isIncluded(socio)}
                                        onClick={() => handleCheck(socio)}
                                        value={socio.nroContrato}
                                        id={`${socio.nombre} ${socio.nroContrato}`}
                                    />
                                ))}
                            </IonCol>
                        </IonRow>
                    }
                </IonGrid>
            </IonContent>
                <Button
                    className={isWeb ? 'filtro-select-button': 'filtro-select-button-mobile'}
                    label="Seleccionar"
                    onClick={handlerSeleccionarSocio}
                    primary
                    disable={handleButtonDisabled()}
                />
        </IonPage>
    )
}

export default FiltroHistorialAtenciones;