import { IonCol, IonGrid, IonRow } from '@ionic/react'
import { Subtitulo, Texto1Linea } from 'app-principal-componentes-visuales';
import React, { useState } from 'react';
import { capitalizeString } from '../../../utils/utils';
import './DatosSocioAccordion.scss';

const DatosSocioAccordion = ({casoEnCursoSeleccionado, casoBD}) => {
    const [datosSocioAccordion, setDatosSocioAccordion] = useState(true);

    return (
        <>
            <Subtitulo
                accordion
                accordionOpen={datosSocioAccordion}
                onClickAccordion={() => setDatosSocioAccordion(!datosSocioAccordion)}
                titulo="Datos del socio"
                txt16
                id="subtitulo-datos"
            />
                
            {
                datosSocioAccordion && 
                <>
                    <IonGrid>
                        <IonRow className='datos-socio-accordion__row'>
                            <IonCol size='12'>
                                <Texto1Linea
                                    left
                                    onClick={function noRefCheck(){}}
                                    texto="Nombre"
                                />
                                <Texto1Linea
                                    colorBlack
                                    left
                                    texto={capitalizeString(casoEnCursoSeleccionado.principal.nombre + ' ' + casoEnCursoSeleccionado.principal.apellidos)}
                                    txt16
                                />
                            </IonCol>
                        </IonRow>
                        {
                        casoEnCursoSeleccionado.estado === "F" ? 
                        <>
                        <IonRow>
                            <IonCol size="6">
                                <Texto1Linea
                                    left
                                    onClick={function noRefCheck(){}}
                                    texto="Número de documento"
                                />
                            </IonCol>
                            <IonCol size="6">
                                <Texto1Linea
                                    right
                                    onClick={function noRefCheck(){}}
                                    texto="Fecha de nacimiento"
                                />
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol size="6">
                                <Texto1Linea
                                    colorBlack
                                    left
                                    texto={casoEnCursoSeleccionado.principal.dni}
                                    txt16
                                />
                            </IonCol>
                            <IonCol size="6">
                                <Texto1Linea
                                    right
                                    colorBlack
                                    onClick={function noRefCheck(){}}
                                    texto={ casoEnCursoSeleccionado.principal.fechaNacimiento.split('-').reverse().join('/') }
                                    txt16
                                />
                            </IonCol>
                        </IonRow>
                        </>
                        :
                        casoBD &&
                        <>
                        <IonRow>
                            <IonCol size="6">
                                <Texto1Linea
                                    left
                                    onClick={function noRefCheck(){}}
                                    texto="Número de documento"
                                />
                            </IonCol>
                            <IonCol size="6">
                                <Texto1Linea
                                    right
                                    onClick={function noRefCheck(){}}
                                    texto="Fecha de nacimiento"
                                />
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol size="6">
                                <Texto1Linea
                                    colorBlack
                                    left
                                    texto={casoBD.familiar ? casoBD.familiar.dni : casoBD.principal.dni}
                                    txt16
                                />
                            </IonCol>
                            <IonCol size="6">
                                <Texto1Linea
                                    right
                                    colorBlack
                                    onClick={function noRefCheck(){}}
                                    texto={casoBD.familiar ? casoBD.familiar.fechaNacimiento.split('-').reverse().join('/') : casoBD.principal.fechaNacimiento.split('-').reverse().join('/') }
                                    txt16
                                />
                            </IonCol>
                        </IonRow>
                        </>
                        }
                    </IonGrid>
                </>
            }
        </>
    )
}

export default DatosSocioAccordion