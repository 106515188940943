import { Feedback } from "app-principal-componentes-visuales";
import { useHistory } from "react-router";
import React, { useContext } from "react";
import "./Cancelar.scss";
import { AsistenciaMedicaContext } from "../../contexts/AsistenciaMedicaContext";
import { IonCol, IonContent, IonFooter, IonGrid, IonPage, IonRow } from "@ionic/react";
import Footer from "../../components/Footer/Footer";

interface ILocationState {
	from: string;
}

const Cancelar = () => {
	const history = useHistory();
	const {isWeb} = useContext(AsistenciaMedicaContext)

	const cancelWithAnalytics = () => {
		history.replace("/asistenciamedica")
	}

	return (
		<IonPage>
		<div className="asistencia-error_page">
			<IonGrid className={`asistencia_content-container ${isWeb ? 'asistencia-web-mobile-padding' : ''}`}>
				<IonRow>
					<IonCol size='12'>
						<Feedback
							titulo={"¿Querés cancelar?"}
							parrafo={"En caso de hacerlo, perderás todos los datos cargados y no se realizará la solicitud de asistencia médica"}
							handsPhoneExclamation
							labelButtonCancel={"Mejor no"}
							onClickCancel={() => history.goBack()}
							labelButtonOk={"Sí, cancelar"}
							onClickOk={cancelWithAnalytics}
							id="feedback-cancelar"
						/>
					</IonCol>
				</IonRow>
			</IonGrid>
		</div>
		</IonPage>
	);
};

export default Cancelar;
