import { useReducer, useEffect  } from "react";
import { getAsistenciaMedicaInitialState } from "../helpers/getAsistenciaMedicaInitialState";
import { obtenerDatosSocio } from "../helpers/socioPrincipal";
import { obtenerCanalesStorage, obtenerFactoresStorage, obtenerProvinciasStorage, obtenerServicios, obtenerSintomasStorage } from "../helpers/storageHelper";
import { asistenciaMedicaReducer } from "../reducers/asistenciaMedicaReducer";
import { AuthInfo } from "../interfaces/ads/auth.info.interface";
import { StorageManager } from "../interfaces/ads/storage.manager.interface";
import { HttpRequestOptions } from "../interfaces/ads/http.request.options.interface";
import { AsistenciaMedicaActionType } from "../interfaces/global-state/asistencia.medica.action.type.enum";
import { ApiError } from "../interfaces/api.error.interface";
import { formatearError } from "../utils/apiUtils";
import socioPrincipal from '../mocks/socioPrincipal.json'

export const useAsistenciaMedica = (
    user: AuthInfo,
    storageReady: boolean,
    storageManager: StorageManager | any,
    useRequest: () => { apiRequest: ( options: HttpRequestOptions ) => Promise<unknown> },
    setError?: any
    ) => {

    const [ asistenciaMedica, dispatchAsistenciaMedica ] = useReducer(
        asistenciaMedicaReducer,
        getAsistenciaMedicaInitialState()
    );

    const { apiRequest } = useRequest();

    useEffect(() => {
        if(setError){
            setError(false)
        }
        obtenerDatosSocio( Number(user.userInfo.interlocutorComercial), apiRequest, user )
            .then( socio => {
                dispatchAsistenciaMedica({
                    type: AsistenciaMedicaActionType.actualizarUsuario, 
                    socio: socio
                });
            })
            .catch((error) => {
                let errorAlInicializar: ApiError = formatearError(error);
                sessionStorage.setItem("ASIS_MED_INIT_ERROR", JSON.stringify(errorAlInicializar));
                /* Workaround para cuando el usuario ingresa al modulo antes de que se finalice la llamada a la API.
                Permite que la pantalla principal se entere del error sin necesidad de salir y volver a ingresar al modulo.*/
                // TODO: Reemplazar por suscripciones al estado de sessionStorage.
                dispatchAsistenciaMedica({
                    type: AsistenciaMedicaActionType.actualizarUsuario, 
                    socio: null
                });
                if(setError){
                    setError(true)
                }
            })
    }, [ user ]);

    useEffect(() => {
        if( storageReady ) {
            Promise.all([
                obtenerProvinciasStorage( apiRequest, storageManager ),
                obtenerFactoresStorage( apiRequest, storageManager ),
                obtenerSintomasStorage( apiRequest, storageManager ),
                obtenerCanalesStorage( apiRequest, storageManager),
                obtenerServicios( apiRequest, storageManager)
            ]).then( data => {
                console.info( "ASIS-MED - Ya se encuentran inicializados en Storage, los listados globales de Provincias, Factores y Sintomas.", data );
            }).catch( error => {
                console.error( "ASIS-MED - Ocurrio un error al inicializar los listados globales de Provincias, Factores y Sintomas.", error );
                let errorAlInicializar = formatearError(error);
                sessionStorage.setItem("ASIS_MED_INIT_ERROR", JSON.stringify(errorAlInicializar));
            });
        };

    }, [ storageReady ]);

    useEffect(() => {
        dispatchAsistenciaMedica({
            type: AsistenciaMedicaActionType.actualizarAtencionesFinalizadas, 
            casosFinalizados: []
        });
        
        dispatchAsistenciaMedica({
            type: AsistenciaMedicaActionType.actualizarControlVerdes, 
            controlVerdes: []
        });
    }, []);

    return {
        asistenciaMedica,
        dispatchAsistenciaMedica
    }
}