import { IonCol, IonContent, IonGrid, IonHeader, IonPage, IonRow } from '@ionic/react';
import { Button, Subtitulo, Titulo } from 'app-principal-componentes-visuales';
import { LABEL_RIESGO, LABEL_WHATSAPP, TELEFONO_EMERGENCIAS, TELEFONO_URGENCIAS_DOS, TELEFONO_URGENCIAS_UNO, TELEFONO_WHATSAPP, TITULO_PREGUNTA } from '../../../utils/constants';
import { formatearNumeroBoton } from '../../../utils/utils';
import React, { useContext, useEffect } from 'react';
import "./Contactanos.scss";
import { GA_KEYS, mapGA } from '../../../helpers/GTM';
import { AsistenciaMedicaContext } from '../../../contexts/AsistenciaMedicaContext';

interface IContactanosProps {
    menorSeisMeses?: boolean,
    sintomas?: boolean,
    servicioEnCurso?: boolean,
    texto?: string,
    titulo: String,
    screen?: String,
    setupData?: any
}

const Contactanos = ({menorSeisMeses, sintomas, texto, titulo, servicioEnCurso, screen, setupData} : IContactanosProps ) => {

    const {isWeb} = useContext(AsistenciaMedicaContext)
    
    useEffect(() => {
        if(screen && setupData){
            const configGA = {
                salida_motivo: screen
            }
            mapGA(GA_KEYS.TELEFONOS, setupData, configGA)
        }
    }, [screen])

    const encodedText = encodeURIComponent(texto)

    let modalStyle = titulo === TITULO_PREGUNTA ? "modal__grid modal__grid-contactanos" : "modal__grid";  
    return (
        <IonPage className='asistencia-medica asistencia-contactanos'>
            <IonHeader className='ion-no-border modal__header'>
                <IonGrid>
                    <IonRow>
                        <IonCol size="12" className="titulo">
                            <Titulo titulo={titulo} id="titulo-asistencia-medica" />
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonHeader>
            <IonContent fullscreen>
                <IonGrid className={modalStyle}>
                    {
                        (menorSeisMeses || sintomas) &&
                        <IonRow>
                            <IonCol size='12'>
                                <Subtitulo txt14 titulo="Para brindarte una mejor asistencia, será necesario que te contactes directamente con nosotros." id="subtitulo-sintomas" />
                            </IonCol>
                        </IonRow>
                    }
                    {!menorSeisMeses  && 
                        <IonRow className={ 'contactanos-row' }>
                            <IonCol size='12'>
                                <Button
                                    href={
                                        !isWeb 
                                        ? texto 
                                            ? `whatsapp://send?phone=+54${TELEFONO_WHATSAPP}&text=${texto}`
                                            : `whatsapp://send?phone=+54${TELEFONO_WHATSAPP}` 
                                        : texto 
                                        ? `https://wa.me/${TELEFONO_WHATSAPP}?text=${encodedText}`
                                        : `https://wa.me/${TELEFONO_WHATSAPP}`
                                    }
                                    label={LABEL_WHATSAPP}
                                    whatsapp
                                />
                            </IonCol>
                        </IonRow>
                    }
                    {servicioEnCurso && 
                        <IonRow className={'contactanos-row'}>
                            <IonCol size='12'>
                                <Button
                                    id='whatsapp-contactanos-button'
                                    href={ !isWeb ? `whatsapp://send?phone=+54${TELEFONO_WHATSAPP}` : `https://wa.me/${TELEFONO_WHATSAPP}` }
                                    label={LABEL_WHATSAPP}
                                    whatsapp
                                />
                            </IonCol>
                        </IonRow>
                    }
                    <IonRow className={'contactanos-row'}>
                        <IonCol size='12'>
                            <Button
                                id='urgencias-uno-button'
                                href={`tel:${TELEFONO_URGENCIAS_UNO}`}
                                label={formatearNumeroBoton(TELEFONO_URGENCIAS_UNO!)}
                                phone
                            />
                        </IonCol>
                    </IonRow>
                    <IonRow className={'contactanos-row'}>
                        <IonCol size='12'>
                            <Button
                                id='urgencias-2-button'
                                href={`tel:${TELEFONO_URGENCIAS_DOS}`}
                                label={formatearNumeroBoton(TELEFONO_URGENCIAS_DOS!)}
                                phone
                            />
                        </IonCol>
                    </IonRow>
                    { !menorSeisMeses && !sintomas && 
                        <IonRow className={ 'contactanos-row' }>
                            <IonCol size='12'>
                                <Subtitulo
                                    titulo={LABEL_RIESGO}
                                    txtRed
                                    id="subtitulo-riesgo"
                                />
                                <Button
                                    colorRed
                                    id='Riesgo-button'
                                    href={`tel:${TELEFONO_EMERGENCIAS}`}
                                    label={formatearNumeroBoton(TELEFONO_EMERGENCIAS!)}
                                    primary
                                />
                            </IonCol>
                        </IonRow>
                    }
                </IonGrid>
            </IonContent>
        </IonPage>
    )
}

export default Contactanos