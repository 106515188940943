import { AxiosResponse } from "axios";
import { SocioFamiliar } from "../interfaces/socioFamiliar.interface";
import { BACKEND_ENDPOINT, SOCIO_FAMILIAR } from "../utils/constants";

export const updateSocioFamiliar = (socio: SocioFamiliar, apiRequest: any) => {
    return new Promise<AxiosResponse>((resolve, reject) => {
        const url = BACKEND_ENDPOINT + SOCIO_FAMILIAR;
        const method = 'PUT';
        const data = socio;
        const options = {
            url,
            method,
            data
        }
        apiRequest(options)
            .then((response: AxiosResponse) => resolve(response))
            .catch((error : any) => reject(error));
    });
}