import React from 'react';
import { IonCol, IonContent, IonFooter, IonGrid, IonPage, IonRow } from '@ionic/react';
import { Button, Texto1Linea, Titulo } from 'app-principal-componentes-visuales';
import './CambiarSocio.scss';

interface ICambiarSocioProps {
    acceptCambiarSocio: () => void,
    refuseCambiarSocio: () => void
}

const CambiarSocio = ({ acceptCambiarSocio, refuseCambiarSocio }: ICambiarSocioProps) => {

    return (
        <IonPage className='asistencia-cambiar-socio'>
            <IonContent fullscreen>
                <IonGrid className='cambiar-socio__grid'>
                    <IonRow>
					    <IonCol size="12">
					    	<Titulo titulo="¿Est&aacute;s seguro de que deseas cambiar de socio?" id="titulo-asistencia-cambiar-socio" />
					    </IonCol>
				    </IonRow>
                    <IonRow>
                        <IonCol size='12'>
                            <Texto1Linea
                                left
                                texto='Al cambiar el socio, deber&aacute;s completar nuevamente el formulario de solicitud con la informaci&oacute;n de la nueva persona a atender.'
                                txt16
                            />
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>

            <IonFooter className='ion-no-border'>
                <IonGrid>
                    <IonRow className='cambiar-socio__button--padding'>
                        <IonCol size='6'>
                            <Button className='cambiar-socio_button cambiar-socio__button__left' 
                                label='Mejor no' 
                                onClick={refuseCambiarSocio}
                                id="btn-cambiar-socio-no"
                            />
                        </IonCol>
                        <IonCol size='6'>
                            <Button className='cambiar-socio_button cambiar-socio__button__right' 
                                label='Sí, cambiar' 
                                primary
                                onClick={acceptCambiarSocio}
                                id="btn-cambiar-socio"
                            />
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
}

export default CambiarSocio;