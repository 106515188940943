import { IonCol, IonContent, IonGrid, IonRow } from "@ionic/react";
import { Button, CardNota, Subtitulo, Titulo } from "app-principal-componentes-visuales";
import React, { useState } from "react";

import './ProximidadUbicacion.scss';
import { capitalizeString } from "../../../utils/utils";

const ProximidadUbicacion = ( { onClickContinuar, onClickCambiarDireccion, direccionMasProxima, isWeb } : {[ key: string ]: any }) => {

    const [waitingButton, setWaitingButton] = useState(false);

    /**
     * se dirige a la pantalla de sintomas
     */
    const onClickNo = () => {
        onClickContinuar();
    }

    /**
     * edita la ubicacion seleccionada
     */
    const onClickSi = () => {
        setWaitingButton(!waitingButton);
        onClickCambiarDireccion();
        setWaitingButton(false);

    }

    return (
        <IonContent className={ isWeb ? "proximidad-ubicacion" : "proximidad-ubicacion proxidad-ubicacion-mobile"}>
            <IonGrid className="proximidad-ubicacion-grid">
                <IonRow>
                    <Titulo className="sd__title" titulo={"Detectamos que estás cerca de uno de tus domicilios guardados"} id="titulo-proximidad-ubicacion" />
                </IonRow>
                <IonRow>
                    <Subtitulo txt16 titulo={"¿Querés cambiar el domicilio para esta asistencia?"} id="subtitulo-domicilio"/>
                </IonRow>
                <IonRow>
                    <CardNota
                        id="card-nota"
                        className="localizacion__card"
                        onClick={() => {}}
                        pingBlue
                        // texto="Avenida Laprida 1234, Ciudad Autónoma de Buenos Aires."
                        texto={capitalizeString(`${direccionMasProxima.calle} ${direccionMasProxima.altura}, ${direccionMasProxima.localidad}, ${direccionMasProxima.provincia}`)}
                    />
                </IonRow>
            </IonGrid>
            <IonGrid className="proximidad-ubicacion-footer">
                <IonRow className="row-btns">
                    <IonCol size="6">
                        <Button 
                            className="ubicacion_button"
                            onClick={onClickNo}
                            label="No"
                            id="btn-ubicacion-no"
                        />
                    </IonCol>
                    <IonCol size="6">
                        <Button 
                            primary
                            className="ubicacion_button"
                            waiting={waitingButton}
                            onClick={onClickSi}
                            label="Sí, cambiar"
                            id="btn-ubicacion"    
                        />
                    </IonCol>
                </IonRow>
            </IonGrid>
        </IonContent>
    )

}

export default ProximidadUbicacion;