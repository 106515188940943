import axios, { AxiosResponse } from "axios";
import { GrupoFamiliarSalus } from "../interfaces/salus/grupofamiliarsalus.interface";
import { CasoEnCurso } from "../interfaces/salus/servicioencurso.interface";
import { BACKEND_ENDPOINT, CASOS_SALUS, GRUPO_SALUS, SOCIO_SALUS } from "../utils/constants";

export const getSocioSalusByIc = (ic: number, apiRequest: any) => {
    return new Promise<AxiosResponse>((resolve, reject) => {
        const url = BACKEND_ENDPOINT + SOCIO_SALUS + ic;
       
        const method = 'GET';
        const options = {
            method,
            url
        }
        apiRequest(options)
            .then((response : AxiosResponse) => resolve(response))
            .catch((error : any) => reject(error));
    });
}

export const getGrupoFamiliarByContrato = (contrato: number, apiRequest: any) => {
    return new Promise<GrupoFamiliarSalus[]>((resolve, reject) => {
        const url = BACKEND_ENDPOINT + GRUPO_SALUS + contrato;
        const method = 'GET';
        const options = {
            method,
            url
        }
        apiRequest(options)
        .then((response : AxiosResponse) => resolve(response.data))
        .catch((error : any) => reject(error));
    });
}

export const getCasosEnCurso = (ic: number, apiRequest: any) => {
    return new Promise<AxiosResponse>((resolve, reject) => {
        const url = BACKEND_ENDPOINT + CASOS_SALUS + ic;
        const method = 'GET';
        const options = {
            method,
            url
        }
        apiRequest(options)
        .then((response : AxiosResponse) => resolve(response))
        .catch((error : any) => reject(error));
    });
}
