import { sendTracks, tagManagerArgs } from "../helpers/GA";
import { typeSetupData } from "../interfaces/setupData.interface";

const app_version: string = process.env.ASISTENCIA_WEB_VERSION || process.env.asistencia_web_version || '2.3.0';
const environment: string = process.env.REACT_APP_ENVIRONMENT || process.env.react_app_environment || 'TEST';

let configLog = {
  status: true,
  appCronoLimit: 20,
  appIteration: true,
  limit: 100
};

const dispatchGTM = (config: any) => {
  console.log('logsito dispatchGTM')
  sendTracks(config);
}

let setupData: typeSetupData = {
  app_version: app_version,
  dispatchGTM: dispatchGTM,
  environment: environment,
  tagManagerArgs
};

export { setupData }