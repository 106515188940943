import React, { useContext, useState } from "react";
import {
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonPage,
  IonRow,
} from "@ionic/react";
import { Button, CardAsistencia, Modal, Subtitulo, Titulo } from "app-principal-componentes-visuales";
import './ServicioEnCurso.scss'
import { DETALLE_CASO, ESTADOS_ASIGNADOS, ESTADOS_CONFIRMADOS, SERVICIO_EN_CURSO, TITULO_PREGUNTA } from "../../utils/constants";
import Contactanos from "../../components/Modals/Contactanos/Contactanos";
import { capitalizeString } from "../../utils/utils";
import SessionHeader from "../../components/Headers/SessionHeader/SessionHeader";
import Footer from "../../components/Footer/Footer";
import { AsistenciaMedicaContext } from "../../contexts/AsistenciaMedicaContext";

const ServicioEnCurso = ({history})  => {

  const { asistenciaMedica } = useContext(AsistenciaMedicaContext);
  let {servicioEnCurso} = history.location.state;
  const fechaCreacion = new Date(servicioEnCurso.fechaCreacion)
  const mesActual = new Intl.DateTimeFormat('es-ES', { month: 'short'}).format(fechaCreacion);
  const [detalleCasoModal, setDetalleCasoModal] = useState(false);
  const [ showContactanosModal, setShowContactanosModal ] = useState(false);
  const {estadoSalus} = servicioEnCurso;

  /**
   * Metodo que realiza la validacion del estado para casos asignados
   */
  const validarEstadoAsignado = () => {
    return ESTADOS_ASIGNADOS.includes(estadoSalus) || estadoSalus === '' ? true : false;
  }

  /**
   * Metodo que realiza la validacion del estado para casos confirmados
   */
  const validarEstadoConfirmado = () => {
      return ESTADOS_CONFIRMADOS.includes(estadoSalus) ? true : false;
  }

  const handleVerDetalle = () => {
    let casoEnCursoSeleccionado = { casoEnCursoSeleccionado: servicioEnCurso, from: SERVICIO_EN_CURSO };
    history.push(DETALLE_CASO, casoEnCursoSeleccionado);
  }

  return (
    <IonPage className="asistencia-medica asistencia-medica-servicio-en-curso">
      <IonContent fullscreen >
      <SessionHeader socioPrincipal={asistenciaMedica.usuario} path={SERVICIO_EN_CURSO}/>
        <IonGrid className='content__grid asistencia_content-container asis-home-container'>
          <IonRow>
            <IonCol size="12">
              <Titulo className="titulo-servicio-en-curso"
                fontSm
                titulo={`Existe una solicitud en curso para ${capitalizeString(servicioEnCurso.principal.nombre)}`}
                id="titulo-asistencia-servicio-en-curso"
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="12">
              <Subtitulo className="subtitulo-servicio-en-curso"
                titulo="Si necesit&aacute;s m&aacute;s informaci&oacute;n sobre esta solicitud, pod&eacute;s escribirnos por Whatsapp o llamarnos."
                id="subtitulo-servicio"
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="12">
              <CardAsistencia
                dia={fechaCreacion.getDate().toString()}
                id="asistencia-medica-card-servicio-en-curso"
                mes={mesActual}
                turnos={[
                  {
                    creada: validarEstadoAsignado(),
                    enEspera: validarEstadoConfirmado(),
                    especialidadPara: servicioEnCurso.descripcion,
                    verDetalle: true,
                    prestador: capitalizeString(servicioEnCurso.principal.nombre + ' ' + servicioEnCurso.principal.apellidos),
                    onClick: () => {handleVerDetalle()},
                  }
                ]}
              />
            </IonCol>
          </IonRow>
        </IonGrid>
        <IonFooter className="ion-no-border">
        <IonGrid className="asistencia_content-container">
        <IonRow>
            <IonCol size="6" className="boton-volver">
              <Button
                label="Volver"
                onClick={() => {history.goBack()}}
              />
            </IonCol>
            <IonCol size="6" className="boton-contactanos">
              <Button
                label="Contactanos"
                primary
                onClick={()=>{setShowContactanosModal(true)}}
              />
            </IonCol>
          </IonRow>
        </IonGrid>
        <Footer/>
      </IonFooter>
        <Modal
          className="modal_web"
          hideCloseBtn={false}
          //@ts-ignore
          component={<Contactanos menorSeisMeses={true} titulo={TITULO_PREGUNTA} servicioEnCurso/>}
          setShowModal={setShowContactanosModal}
          showModal={showContactanosModal}
          id="contactanos"
        />
      </IonContent>

    </IonPage>
  );
};

export default ServicioEnCurso;
