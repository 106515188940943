import React from "react"
import { IonCard, IonCol, IonContent, IonFooter, IonGrid, IonRow, IonToolbar } from "@ionic/react"
import { Skeleton } from "app-principal-componentes-visuales"
import "./AsistenciaMedicaSkeleton.scss"

const AsistenciaMedicaSkeleton = () => (
	<>
		<IonContent>
            <IonGrid className='content__grid'>
				<IonCol size="12" className="asistencia-medica-skeleton">
					<IonRow>
						<IonCol>
							<IonCard className='solicitar-asistencia__card'>
        		    			<IonGrid className='content__grid'>
        		    			    <IonRow>
        		    			        <IonCol size="12">
        		    			            <Skeleton className='card__title' height={48}/>
        		    			        </IonCol>
        		    			        <IonCol size="12">
        		    			            <Skeleton className='card__subtitle' height={38}/>
        		    			        </IonCol>
        		    			        <IonCol size="12">
        		    			            <Skeleton className='card__button' height={50}/>
        		    			        </IonCol>
        		    			    </IonRow>
        		    			</IonGrid>
        					</IonCard>
						</IonCol>
						{/* Se comenta ya que por negocio no se muestran dichos segmentos 
						<IonCol className="grid__col" size="12">
							<Skeleton className="title item__skeleton--50" height={30} />
							<Skeleton className="button-link" height={48} />
							<Skeleton className="button-link" height={48} />
						</IonCol>  */}
						<IonCol className="grid__col" size="12">
        		            {/* <Skeleton className="title item__skeleton--50 item__label" height={30} /> */}
							<Skeleton className="button-link" height={48} />
						</IonCol>
					</IonRow>
				</IonCol>
			</IonGrid>
		</IonContent>
		<IonFooter className="ion-no-border">
			<IonToolbar>
				<div className="footer-terminos-condiciones">
					<Skeleton height={12} />
				</div>
			</IonToolbar>
		</IonFooter>
	</>
)

export default AsistenciaMedicaSkeleton
