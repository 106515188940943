import { IonCol, IonContent, IonGrid, IonPage, IonRow } from '@ionic/react'
import { Feedback, Modal } from 'app-principal-componentes-visuales'
import { capitalizeString } from '../../utils/utils'
import { ASISTENCIA_CREADA, HOME, TITULO_PREGUNTA } from '../../utils/constants'
import React, { useContext, useEffect, useState } from 'react'
import Contactanos from '../../components/Modals/Contactanos/Contactanos'
import { AsistenciaMedicaContext } from '../../contexts/AsistenciaMedicaContext'
import { GA_KEYS, mapGA } from '../../helpers/GTM'

const AsistenciaSolicitada = ({ history }) => {
    const { setupData } = useContext(AsistenciaMedicaContext);
    let { socio, sendCasoResponse } = history.location.state;
    const [showContactanosModal, setShowContactanosModal] = useState(false);

    useEffect(() => {
        const tipoServicio = `${sendCasoResponse.servicio.codigosubtiposervicio}-${sendCasoResponse.servicio.codigotiposervicio}`

        const configGA = {
            tipo_de_servicio: tipoServicio
        }

        mapGA(GA_KEYS.NUEVO_CASO_ASISTENCIA, setupData, configGA)
    }, [])
    

    const handleVolverInicio = () => {
        history.push(HOME, { seguimiento : false, from: ASISTENCIA_CREADA });
    }
    
    const handleIrASeguimiento = () => {
        history.push(HOME, { seguimiento : true,  from: history.location.pathname });
    }

    return (
        <IonPage>
        <div className="asistencia-error_page">
                <IonGrid className='content__grid asistencia_content-container asistencia-error_page'>
                    <IonRow>
                        <IonCol size='12'>
                            <Feedback
                                asistencia 
                                handsPhoneReady
                                labelButtonCancel="Volver al inicio"
                                labelButtonOk='Ir a seguimiento'
                                onClickCancel={() => handleVolverInicio()}
                                onClickOk= {() => handleIrASeguimiento()}
                                parrafo={`Estamos gestionando tu solicitud para ${capitalizeString(socio.nombre)}`}
                                titulo='Asistencia médica '
                                titulo2='solicitada'
                                id="feedback-asistencia-solicitada-seguimiento"
                            />
                        </IonCol>
                    </IonRow>
                </IonGrid>
         
            <Modal
                className='modal_web'
                hideCloseBtn={false}
                //@ts-ignore
                component={<Contactanos setupData={setupData} screen={'servicio_vigente'} sintomas texto={null} titulo={TITULO_PREGUNTA} />}
                setShowModal={setShowContactanosModal}
                showModal={showContactanosModal}
                id='contactanos'
            />
        </div>
        </IonPage>
    )
}

export default AsistenciaSolicitada