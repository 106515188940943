import React from 'react'
import {
    Feedback,
    Modal,
} from "app-principal-componentes-visuales";
import "./CerrarSesionModal.scss"

export type ModalConfirmacionCierreCompletoSesionArgs = {
    showModal: any,
    setShowModal: any,
    handleCerrarSesion: any,
    handleCancelarCerrarSesion: any
}

export const ModalConfirmacionCierreCompletoSesion =
    (
        {
            showModal,
            setShowModal,
            handleCerrarSesion,
            handleCancelarCerrarSesion
        }: ModalConfirmacionCierreCompletoSesionArgs
    ) => {

        return (
            <Modal
                hideCloseBtn
                showModal={showModal}
                setShowModal={setShowModal}
                actionClose={handleCancelarCerrarSesion}
                screen60
                className='modal_web'
                //@ts-ignore
                component={
                    <Feedback
                        id="confirm-cierre-sesion-feedback"
                        notOsde
                        bgWhite
                        handsPhoneQuestionSmall
                        titulo="¿Confirmas que querés cerrar sesión en este navegador?"
                        parrafo={'Eliminaremos todos los datos almacenados en este navegador.'}
                        labelButtonOk="Si, cerrar sesión"
                        labelButtonCancel="Mejor no"
                        onClickOk={handleCerrarSesion}
                        onClickCancel={handleCancelarCerrarSesion}
                        className="confirm-cierre-sesion-feedback"
                    />}
                id="modal-confirm-cierre-sesion"
            />

        );
    }
