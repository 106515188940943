import { IonCol, IonContent, IonGrid, IonHeader, IonPage, IonRow } from '@ionic/react'
import { Button, InputText, ListAutocomplete, Separador, Subtitulo, TextArea, TextLink, Titulo } from 'app-principal-componentes-visuales'
import React, { useState, useEffect, useContext } from 'react'
import { AsistenciaMedicaContext } from '../../../contexts/AsistenciaMedicaContext'
import { obtenerProvinciasStorage } from '../../../helpers/storageHelper'
import { Direccion } from '../../../interfaces/direccion.interface'
import { Localidad } from '../../../interfaces/localidad.interface'
import { Provincia } from '../../../interfaces/provincia.interface'
import { textIsValid } from '../../../utils/utils'
import { CABA, CAPITAL } from '../../../utils/constants'
import './FormularioDomicilio.scss'

const FormularioDomicilio = ({ addDirection, selectedDirection, editLocation, modifyDirection, openDeleteDirection, openFormularioModal, showEliminar, merlinWaiting, agregarGeo = false, isWeb } : {[ key: string ]: any }) => {

    const initialState_EditPlace = { edit: false, campo: ''};
    const initialState_Direccion = { barrio: '', calle: '', altura: '', piso: '', departamento: '', codigoPostal: '', 
        latitud: 0, longitud: 0, entreCalleUno: '', entreCalleDos: '', localidad: '', provincia: '', posicionExacta: false, observaciones: '', seleccionado: true};
    const [ direccion, setDireccion ] = useState<Direccion>(initialState_Direccion);
    const [ editPlace, setEditPlace] = useState(initialState_EditPlace);
    const [ provinciasEncontradas, setProvinciasEncontradas ] = useState([]);
    const [ provinciasInicializadas, setProvinciasInicializadas ] = useState([]);
    const [ localidadesEncontradas, setLocalidadesEncontradas ] = useState([]);
    const [ localidadesInicializadas, setlocalidadesInicializadas ] = useState([]);
    const [ barriosEncontrados, setBarriosEncontrados ] = useState([]);
    const [ barriosInicializados, setbarriosInicializados ] = useState([]);
    const [ newPlace, setNewPlace ] = useState(false);
    const [ provinciaSearch, setProvinciaSearch ] = useState('');
    const [ localidadSearch, setLocalidadSearch ] = useState('');
    const [ barrioSearch, setBarrioSearch ] = useState('');
    const [ localidadConBarrios, setLocalidadConBarrios ] = useState(false);
    const [requiereBarrio, setRequiereBarrio] = useState(false)

    const { useRequest, storageManager, setupData } = useContext( AsistenciaMedicaContext );
    const { apiRequest } = useRequest();

    const [ provincias, setProvincias ] = useState<Provincia[]>();
    const [ localidades, setLocalidades ] = useState<Localidad[]>();

    useEffect(() => {
        obtenerProvinciasStorage( apiRequest, storageManager ).then( provs => {
            // Se cambia el string Capital Federal para que sea Ciudad Autonoma de Buenos Aires
            // luego para enviar a salus se debe cambiar nuevamente a Capital Federal
            let index = provs.findIndex(prov => prov.nombre.toUpperCase() == CAPITAL);
            provs[index].nombre = CABA;
            setProvincias(provs);
            let nombresProvincias = provs.map( provincia => provincia.nombre );
            setProvinciasInicializadas(nombresProvincias);
        }).catch( error => {
            console.error("ASIS-MED - Error al inicializar listado de provincias", error);
        });

    }, [])

    useEffect(() => {

        if( selectedDirection && selectedDirection.calle ) {
            setNewPlace(false) 
            if(!(selectedDirection.provincia === 'BUENOS AIRES' && (selectedDirection.localidad === 'CIUDAD AUTONOMA BUENOS AIRES' || selectedDirection.localidad === 'CAPITAL FEDERAL'))) {
                setProvinciaSearch(selectedDirection.provincia === CAPITAL ? CABA : selectedDirection.provincia)
                setLocalidadSearch(selectedDirection.localidad)
                setBarrioSearch(selectedDirection.barrio)
                setDireccion(selectedDirection);
                selectedDirection.barrio !== '' ? setLocalidadConBarrios(true) : setLocalidadConBarrios(false);
            } else {
                let direccionErronea = {...selectedDirection, provincia:'', localidad:''}
                setDireccion(direccionErronea);
            }
        } else {
            setNewPlace(true)
        }
        
    }, [ selectedDirection ])

    useEffect(() => {
        if( provincias && direccion.provincia ) {
            direccion.provincia = direccion.provincia === CAPITAL ? CABA : direccion.provincia;
            let prov = provincias.find( prov => prov.nombre === direccion.provincia );
            setLocalidades(prov.localidades);
            let nombresLocalidades = prov.localidades.map( loc => loc.nombre);
            setlocalidadesInicializadas(nombresLocalidades);
        }

    }, [ direccion.provincia, provincias ])

    useEffect(() => {
        
        if( localidades && direccion.localidad ) {
            let localidad = localidades.find( loc => loc.nombre === direccion.localidad );
            if ( localidad?.barrios.length > 0 ) {
                let nombresBarrios = localidad.barrios.map( loc => loc.nombre);
                setLocalidadConBarrios(true);
                setbarriosInicializados(nombresBarrios);
            } else {
                setDireccion({...direccion, barrio: ''});
                setLocalidadConBarrios(false);
                setBarriosEncontrados([]);
            }
        }
        
    }, [ direccion.localidad, localidades ])

    useEffect(() => {
        if(!newPlace && direccion.localidad && localidades && barriosEncontrados.length === 0 ){
            let localidad = localidades.find( loc => loc.nombre === direccion.localidad );
            if ( localidad?.barrios.length > 0 ) {
                let nombresBarrios = localidad.barrios.map( loc => loc.nombre);
                setLocalidadConBarrios(true);
                setbarriosInicializados(nombresBarrios);
            } else {
                setDireccion({...direccion, barrio: ''});
            }
        }

    }, [newPlace, direccion.localidad])
    

    /**
     * Metodo encargado de ocultar el formulario para mostrar el modulo de searchbar para provincias, localidades, barrios
     * @param campo indica cual de los searchbars se va a renderizar
     */
    const handleEditPlace = (campo: string) => {
        editLocation();
        setEditPlace({ edit:!editPlace.edit, campo:campo });
    }

    /**
     * Metodo que cambia el estado de la direccion con la provincia que se selecciono del listado
     */
    const handleAcceptProvince = ( provinciaSeleccionada ) => {
        
        setProvinciaSearch(provinciaSeleccionada);
        setLocalidadesEncontradas([]);
        setLocalidadSearch("");
        setBarriosEncontrados([]);

        if(provinciaSeleccionada === CABA){
            setLocalidadConBarrios(true)
            setRequiereBarrio(true)
            setDireccion({...direccion, provincia:provinciaSeleccionada, localidad:'CIUDAD AUTONOMA BUENOS AIRES', barrio:''});
        }else{
            setLocalidadConBarrios(false)
            setRequiereBarrio(false)
            setDireccion({...direccion, provincia:provinciaSeleccionada, localidad:'', barrio:''});
        }
        handleEditPlace('');
    }

    /**
     * Metodo que setea en el state el input de provincias al realizarse una modificacion y filtra las provincias mostradas en pantalla
     */
    const handleInputChangeProvince = ({target}) => {
        let {value} = target;
        if(provinciaSearch !== value) {
            if (value.length < 3) {
                setProvinciasEncontradas([]);
            } else { 
                let provinciasBuscadas = provinciasInicializadas.filter((item) => {return item.toUpperCase().includes(value.toUpperCase())});
                setProvinciasEncontradas(provinciasBuscadas);
            }
            setProvinciaSearch(value);
        }
    }

    /**
     * Metodo que cambia el estado de la direccion con la localidad que se selecciono del listado
     */
    const handleAcceptLocalities = ( localidadSeleccionada ) => {
        setDireccion({...direccion, localidad:localidadSeleccionada, barrio:''});
        setLocalidadSearch(localidadSeleccionada);
        setBarrioSearch('');
        handleEditPlace('');
    }

    /**
     * Metodo que setea en el state el input de localidades al realizarse una modificacion y filtra las localidades mostradas en pantalla
     */
    const handleInputChangeLocalities = ({target}) => {
        let {value} = target;
        if(localidadSearch !== value) {
            if (value.length < 3) {
                setLocalidadesEncontradas([]);
            } else { 
                let localidadesBuscadas = localidadesInicializadas.filter((item) => {return item.toUpperCase().includes(value.toUpperCase())})
                setLocalidadesEncontradas(localidadesBuscadas);
            }
            setLocalidadSearch(value);
        }
    }

    /**
    * Metodo que cambia el estado de la direccion con el barrio que se selecciono del listado
    */
    const handleAcceptNeighborhood = ( barrioSeleccionado ) => {
        setDireccion({...direccion, barrio:barrioSeleccionado});
        setBarrioSearch(barrioSeleccionado);
        handleEditPlace('');
    }

    /**
     * Metodo que setea en el state el input del barrio al realizarse una modificacion y filtra los barrios mostrados en pantalla
     */
    const handleInputChangeNeighborhood = ({target}) => {
        let {value} = target;
        if(barrioSearch !== target) {
            if (value.length < 3) {
                setBarriosEncontrados([]);
            } else { 
                let barriosBuscados = barriosInicializados.filter((item) => {return item.toUpperCase().includes(value.toUpperCase())})
                setBarriosEncontrados(barriosBuscados)
            }
            setBarrioSearch(value);
        }
    }

    /**
     * Metodo que permite mostrar el formulario al cancelarse el ListAutocomplete
     */
    const handleCancel = (direccionCancel) => {
        if (direccionCancel === "provincia") {
            if(direccion.provincia !== '') {
                setProvinciaSearch(direccion.provincia);
            } else {
                setProvinciaSearch('')
                setProvinciasEncontradas([]);
            }
        }
        else if (direccionCancel === "localidad") {
            if(direccion.localidad !== '') {
                setLocalidadSearch(direccion.localidad);
            } else {
                setLocalidadesEncontradas([]);
                setLocalidadSearch('');
            }
        }
        else if (direccionCancel === "barrio") {
            if(direccion.barrio !== '') {
                setBarrioSearch(direccion.barrio);
            } else {
                setBarriosEncontrados([]);
                setBarrioSearch('');
            }
        }
        editLocation();
        setEditPlace(initialState_EditPlace)
    }


    /**
     * Metodo que setea en el state el input de la calle al realizarse una modificacion
     */
    const handleCalleChange = (calle) => {
        
        const noSpacesValue = calle.target.value.replace(/^\s+/, '');

        const isValid = textIsValid(calle.target.value)

        if(isValid){
            setDireccion({...direccion, calle: noSpacesValue})
        } 
    }

    /**
     * Metodo que setea en el state el input de la altura al realizarse una modificacion
     */
    const handleAlturaChange = (altura) => {
    
        const isValid = textIsValid(altura.target.value)

        if(isValid){
            setDireccion({...direccion, altura:altura.target.value})
        } 
    }

    /**
     * Metodo que setea en el state el input del piso al realizarse una modificacion
     */
    const handlePisoChange = (piso) => {

        const noSpacesValue = piso.target.value.replace(/^\s+/, '');

        const isValid = textIsValid(piso.target.value)

        if(isValid){
            setDireccion({...direccion, piso: noSpacesValue});
        } 
    }

    /**
     * Metodo que setea en el state el input del departamento al realizarse una modificacion
     */
    const handleDepartamentoChange = (departamento) => {

        const noSpacesValue = departamento.target.value.replace(/^\s+/, '');

        const isValid = textIsValid(departamento.target.value)

        if(isValid){
            setDireccion({...direccion, departamento: noSpacesValue});
        } 
    }

    /**
     * Metodo que setea en el state el input de las observaciones al realizarse una modificacion
     */
    const handleObservacionesChange = (observaciones) => {

        const noSpacesValue = observaciones.target.value.replace(/^\s+/, '');

        const isValid = textIsValid(observaciones.target.value)

        if(isValid){
            setDireccion({...direccion, observaciones: noSpacesValue});
        } 
    }
    
    /**
     * Metodo que realiza el llamado a la modificacion o alta de una direccion
     */
    const handleSubmit = () => {
        if (direccion.provincia === CABA)
            direccion.provincia = CAPITAL
        if(newPlace){

            addDirection(direccion)
        }else{
            modifyDirection(direccion)
        }
    }

    /**
     * Metodo que realiza la validacion del disable del boton guardar
     */
    const validPlace = () => {
        let valid: boolean;
        if(requiereBarrio && direccion.localidad !== '') {
            valid = (direccion.calle !== '' ? direccion.provincia === '' || direccion.localidad === '' || direccion.altura === '' || direccion.barrio === '' : true );
        } else {
            valid = (direccion.calle !== '' ? direccion.provincia === '' || direccion.localidad === '' || direccion.altura === '' : true )
        }
        return valid || direccion.observaciones?.length > 100;
    }

    const handleDelete = () => {
        openDeleteDirection(selectedDirection, 'formulario');
    }

    return (
        <>
            { !editPlace.edit ? 
                <>
                    <IonContent className={ isWeb ? 'asistencia-formulario':'asistencia-formulario asistencia-formulario-mobile'}>
                        <IonGrid className='asistencia-formulario__header'>
                            <IonRow>
                                { (newPlace || agregarGeo) ? 
                                    <IonCol size='12' className='titulo'>
                                        <Titulo titulo='Nuevo domicilio' id="titulo-asitencia-formulario-nuevo-domicilio"/>
                                    </IonCol>
                                    :
                                    <>
                                        <IonCol size='9' className='titulo'>
                                            <Titulo titulo='Editar domicilio' id="titulo-asitencia-formulario-editar-domicilio"/>
                                        </IonCol>
                                        <IonCol size='2' className='col__align'>
                                            { showEliminar && 
                                                <TextLink
                                                    id='asistencia__formulario-domicilio'
                                                    onClick={handleDelete}
                                                    right
                                                    texto="Eliminar"
                                                />
                                            }
                                        </IonCol>
                                    </>
                                }
                            </IonRow>
                        </IonGrid>
                        <IonGrid className='modal__grid'>
                            <IonRow>
                                <IonCol size='12'>
                                    <InputText
                                        id='formulario-input-calle'
                                        label='Calle'
                                        normalLink
                                        placeholder='Ingres&aacute; solo el nombre de la calle'
                                        type='text'
                                        value={direccion.calle} 
                                        onIonChange={handleCalleChange}
                                    />
                                </IonCol>
                                <IonCol size='12' className='col--padding-top'>
                                    <InputText
                                        id='formulario-input-numero'
                                        label='Número'
                                        normalLink
                                        placeholder='Ingres&aacute; la altura'
                                        type="number"
                                        inputmode="number"
                                        value={direccion.altura} 
                                        onIonChange={handleAlturaChange}
                                    />
                                </IonCol>
                                <IonCol size='6' className='col--padding-right col--padding-top'>
                                    <InputText
                                        id='formulario-input-piso'
                                        label='Piso'
                                        normalLink
                                        placeholder='Opcional'
                                        type='text'
                                        value={direccion.piso} 
                                        onIonChange={handlePisoChange}
                                    />
                                </IonCol>
                                <IonCol size='6' className='col--padding-left col--padding-top'>
                                    <InputText
                                        id='formulario-input-departamento'
                                        label='Departamento'
                                        normalLink
                                        placeholder='Opcional'
                                        type='text'
                                        value={direccion.departamento}
                                        onIonChange={handleDepartamentoChange}
                                    />
                                </IonCol>
                            </IonRow>
                            <Separador />
                            <IonRow>
                                <IonCol size='12'>
                                    <InputText
                                        id='formulario-input-provincia'
                                        elegirLink
                                        label='Provincia'
                                        onClick={() => handleEditPlace('provincia')}
                                        placeholder='Eleg&iacute; la provincia'
                                        type='text'
                                        value={direccion.provincia === CAPITAL ? CABA : direccion.provincia} 
                                    />
                                </IonCol>
                                <IonCol size='12' className='col--padding-top'>
                                    <InputText
                                        id='formulario-input-localidad'
                                        disable = { direccion.provincia === undefined || direccion.provincia === '' }
                                        elegirLink
                                        label='Localidad'
                                        onClick={() => handleEditPlace('localidad')}
                                        placeholder='Eleg&iacute; la localidad'
                                        type='text'
                                        value={direccion.localidad} 
                                    />
                                </IonCol>
                                { localidadConBarrios && (
                                    <IonCol size='12' className='col--padding-top'>
                                        <InputText
                                            id='formulario-input-barrio'
                                            disable = { direccion.localidad === undefined || direccion.localidad === ''}
                                            elegirLink
                                            label='Barrio'
                                            onClick={() => handleEditPlace('barrio')}
                                            placeholder='Eleg&iacute; el barrio'
                                            type='text'
                                            value={direccion.barrio} 
                                        />
                                    </IonCol>
                                )}
                            </IonRow>
                            <Separador />
                            <IonRow>                               
                                <IonCol size='12'>
                                        <Subtitulo titulo='¿Hay informaci&oacute;n adicional que quieras comentarnos?' id="subtitulo-info" />
                                </IonCol>
                                <IonCol size='12'>
                                    {/* // TODO cambiar assistiveText error */}
                                    <TextArea
                                        label='Información adicional'
                                        maxLength={100}
                                        onIonChange={handleObservacionesChange}
                                        placeholder='Ingres&aacute; tu manzana, country, lote, etc.'
                                        value={direccion.observaciones} 
                                        id="text-area-info-adicional"
                                    />
                                </IonCol>
                                <IonCol size='12' className='guardar-cambios'>
                                    {/* TODO revisar disable */}
                                    <Button 
                                        id='formulario-domicilio-guardar'
                                        className='button--margin' 
                                        label = { newPlace ? 'Agregar':'Guardar cambios' } 
                                        onClick = { handleSubmit }
                                        primary 
                                        disable = { validPlace() }
                                        waiting = { Boolean(merlinWaiting) }
                                    />
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </IonContent>

                </>
                : 
                <>
                    <IonContent className='asistencia-formulario'>
                        <IonGrid className='asistencia-formulario__header'>
                            <IonRow>
                                <IonCol size='12' className='titulo'>
                                    <Titulo titulo={'Nuevo domicilio'} id="titulo-asitencia-formulario-nuevo-domicilio" />
                                </IonCol>
                            </IonRow>
                        </IonGrid>

                        <IonGrid className='modal__grid'>    
                            { editPlace.campo === 'provincia' && (
                                <IonRow>
                                    <IonCol size='12'>
                                        <ListAutocomplete
                                            id='provincias-list'
                                            name='provinciaSearch'
                                            label='Provincia'
                                            placeholder='Eleg&iacute; la provincia'
                                            list={provinciasEncontradas}
                                            valueInputDefault={provinciaSearch}
                                            aceptarValor={handleAcceptProvince}
                                            onClickCancelar={()=> handleCancel("provincia")}
                                            onHandleInputChange={handleInputChangeProvince}
                                        />
                                    </IonCol>
                                </IonRow>
                            )}

                            { editPlace.campo === 'localidad' && (
                                <IonRow>
                                    <IonCol size='12'>
                                        <ListAutocomplete
                                            id='localities-list'
                                            name='localidadSearch'
                                            label='localidad'
                                            placeholder='Eleg&iacute; la localidad'
                                            list={localidadesEncontradas}
                                            valueInputDefault={localidadSearch}
                                            aceptarValor={handleAcceptLocalities}
                                            onClickCancelar={() => handleCancel("localidad")}
                                            onHandleInputChange={handleInputChangeLocalities}
                                        />
                                    </IonCol>
                                </IonRow>
                            )}

                            { editPlace.campo === 'barrio' && (
                                <IonRow>
                                    <IonCol size='12'>
                                        <ListAutocomplete
                                            name='barrioSearch'
                                            label='Barrio'
                                            placeholder='Eleg&iacute; el barrio'
                                            list={barriosEncontrados}
                                            aceptarValor={handleAcceptNeighborhood}
                                            valueInputDefault={barrioSearch}
                                            id='barrio-list'
                                            onClickCancelar={() => handleCancel("barrio")}
                                            onHandleInputChange={handleInputChangeNeighborhood}
                                        />
                                    </IonCol>
                                </IonRow>
                            )}
                        </IonGrid>
                    </IonContent>
                </>
            }
        </>
    )
}

export default FormularioDomicilio