import { IonCol, IonGrid, IonRow } from '@ionic/react';
import { Button, Subtitulo, TextLink, TurnosSlider } from 'app-principal-componentes-visuales';
import React, { useContext, useEffect, useState } from 'react';
import { AsistenciaMedicaContext } from '../../../contexts/AsistenciaMedicaContext';
import { capitalizeString } from '../../../utils/utils';
import './HistorialCasos.scss';
import { DETALLE_CASO, HOME } from '../../../utils/constants';
import { useHistory } from 'react-router-dom';

interface HistorialCasosProps {
    handleServiciosEnCurso: () => void
}

const HistorialCasos = ({handleServiciosEnCurso}: HistorialCasosProps) => {

    const { asistenciaMedica } = useContext(AsistenciaMedicaContext);
    const { asistenciasFinalizadas, usuario } = asistenciaMedica;
    const [finalizadas, setFinalizadas] = useState(asistenciasFinalizadas);
    const [casosFinalizados, setCasosFinalizados] = useState([]);
    const history = useHistory();

    useEffect(() => {
        setFinalizadas(asistenciasFinalizadas);
        let casosMap = asistenciasFinalizadas.map((asistencia, index) => {
            let fechaCreacion = new Date(asistencia.fechaCreacion);
            let mesActual = new Intl.DateTimeFormat('es-ES', { month: 'short'}).format(fechaCreacion);
            let fechaAsistencia = fechaCreacion.getDate().toString()
            return {
                dia: fechaAsistencia,
                especialidad: asistencia.descripcion,
                familiar: 'Para ' + capitalizeString(asistencia.principal.apellidos + ' ' + asistencia.principal.nombre),
                mes: mesActual,
                prestador: capitalizeString(asistencia.prestador),
                onClick: () => {verDetalleFinalizados(index)},
                verDetalle: true
            }
        });
        setCasosFinalizados(casosMap);
    }, [asistenciasFinalizadas, asistenciasFinalizadas.length])

    const verDetalleFinalizados = (index) => {
        let asistenciaFinalizada = asistenciasFinalizadas[index];
        let casoSeleccionado = { casoEnCursoSeleccionado: asistenciaFinalizada, from: HOME };
        history.push(DETALLE_CASO, casoSeleccionado);        
    }

    return (
        <> 
            { finalizadas.length !== 0 &&
                <>  
                    <IonGrid>
                        <IonRow>
                            <IonCol size='9'>
                                <Subtitulo titulo='Historial de asistencias' id="subtitulo-historial" />
                            </IonCol>
                            <IonCol size='3'>
                                <TextLink
                                    id='asistencia-medica-text-link-historial'
                                    onClick={() => {handleServiciosEnCurso()}}
                                    texto='Ver todas'
                                    right
                                />
                            </IonCol>
                            <IonCol size='12'>
                                <TurnosSlider
                                    asistencia
                                    id="historial-casos"
                                    className='asistencia-medica-historial-casos-finalizados'
                                    // turnos={casosFinalizados}
                                    turnos={[...casosFinalizados]}
                                />
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </>
            }           
        </> 
    )
}

export default HistorialCasos