import React from "react";
import { IonCol, IonFooter, IonLabel, IonToolbar } from "@ionic/react";
import comodoSecure from "../../../assets/icon/comodo-secure.png"
import rnbd from "../../../assets/icon/RNBD.png"
import "./Footer.scss";

const Footer = () => {
    return(
    <div className="footer-asistencia ion-no-border no-mobile">
      <div className="tool-bar-footer">
        <div>
          <div className="row-principal-footer footer-row">
              <IonCol className="title-footer" size="3">
                <IonLabel>
                  INFORMACIÓN PRINCIPAL
                </IonLabel>
              </IonCol>
              <IonCol className="content-footer" size="9">
                  <div className=" footer-row" >
                      <IonCol size="4">
                        <a className="external-link-footer block-footer" href="https://www.osde.com.ar/informacion-adicional/acuerdo-con-el-usuario">Acuerdo con el usuario</a>
                        <a className="external-link-footer block-footer" href="https://www.osde.com.ar/informacion-adicional/politica-de-privacidad">Política de privacidad</a>
                        <a className="external-link-footer block-footer" href="https://www.osde.com.ar/informacion-adicional/defensa-al-consumidor">Defensa del consumidor</a>
                      </IonCol>
                      <IonCol size="4">
                        <a className="external-link-footer block-footer" href="https://www.osde.com.ar/informacion-adicional/defensa-de-las-los-consumidores">Defensa de las y los consumidores</a>
                        <a className="external-link-footer block-footer" href="https://www.osde.com.ar/informacion-adicional/direccion-nacional-de-datos-personales">Dirección Nacional de Datos Personales</a>
                        <a className="external-link-footer block-footer" href="https://www.osde.com.ar/informacion-adicional/informacion-para-socios">Información para socios</a>
                      </IonCol>
                      <IonCol size="4">
                        <a className="external-link-footer block-footer" href="https://www.osde.com.ar/informacion-adicional/libro-de-quejas-online">Libro de quejas online</a>
                        <a className="external-link-footer block-footer" href="https://www.osde.com.ar/tmt_init_tramite.asp?intTipoTramite=16">Baja de servicio</a>
                        <a className="external-link-footer block-footer" href="https://www.osde.com.ar/tmt_tramite_arrepentimiento.asp">Trámite de arrepentimiento</a>
                      </IonCol>
                  </div>
                  <div className="copyright-footer footer-row">
                      <IonCol className="text-footer" size="8">
                      ©OSDE 2015 - Todos los derechos reservados - CUIT: 30-54674125-3 - Av. Leandro N. Alem 1067 - Piso 9 - C1001AAF - 
                      Buenos Aires - 0800 555 OSDE (6733). Superintendencia de Servicios de Salud - Órgano de Control de Obras Sociales 
                      y Entidades de Medicina Prepaga - 0800-222-SALUD (72583) - 
                  <a className="external-link-footer" href="https://www.sssalud.gob.ar/">www.sssalud.gob.ar</a> 
                  - R.N.E.M.P. (Prov.) 1408 - R.N.O.S. 4-0080-0
                      </IonCol>
                      <IonCol className="icon-col-footer" size="auto">
                          <a href="https://ssl.comodo.com/">
                              <img src={comodoSecure} alt="Logo Secure Socket Layer" className="icon-footer"/>
                              </a>
                      </IonCol>
                      <IonCol className="icon-col-footer" size="auto">
                          <a href="https://www.osde.com.ar/informacion-adicional/direccion-nacional-de-datos-personales">
                              <img src={rnbd} alt="Logo Registro Nacional de Base de Datos" className="icon-footer"/>
                          </a>
                      </IonCol>
                  </div>
              </IonCol>
            </div>
        </div>
      </div>
    </div>
    )
}

export default Footer;