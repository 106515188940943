import { Contrato } from "../interfaces/contrato.interface";
import { Direccion } from "../interfaces/direccion.interface";
import { Factor } from "../interfaces/factor.interface";
import { SocioFamiliar } from "../interfaces/socioFamiliar.interface";

export class Socio{
    id?:number;
    nombre?: string;
    apellidos?: string;
    nroContrato?: string;
    ic?: number;
    dni?: string;
    telefono?: string;
    email?: string;
    fechaNacimiento?: string;
    plan?: string;
    img?: string;
    sexo?: string;
    direcciones?: Direccion[];
    factores?: Factor[];
    terms?: boolean;
    familiares?: SocioFamiliar[] = [];
    contratos?: Contrato[] = [];  

    constructor() { 
    }
}
