import { IonCol, IonGrid, IonRow } from '@ionic/react';
import { Subtitulo, Texto1Linea } from 'app-principal-componentes-visuales';
import React, { useState } from 'react';
import './DatosAtencionAccordion.scss'

const DatosAtencionAccordion = ({casoEnCursoSeleccionado, casoBD}) => {
    const [datosAtencionAccordion, setDatosAtencionAccordion] = useState(true);

    return (
        <>
            <Subtitulo
                accordion
                accordionOpen={datosAtencionAccordion}
                onClickAccordion={() => setDatosAtencionAccordion(!datosAtencionAccordion)}
                titulo="Datos de la atención"
                txt16
                id="subtitulo-datos"
            />
            {
                datosAtencionAccordion && 
                <>
                    <IonGrid>
                        <IonRow className='datos-atencion-accordion__row'>
                            <IonCol size='12'>
                                <Texto1Linea
                                    left
                                    onClick={function noRefCheck(){}}
                                    texto="Tipo de servicio"
                                />
                                <Texto1Linea
                                    colorBlack
                                    left
                                    texto={casoEnCursoSeleccionado.descripcion}
                                    txt16
                                />
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol size="6">
                                <Texto1Linea
                                    left
                                    onClick={function noRefCheck(){}}
                                    texto="Solicitada el"
                                />
                            </IonCol>
                            <IonCol size="6">
                                <Texto1Linea
                                    right
                                    onClick={function noRefCheck(){}}
                                    texto="Solicitada vía"
                                />
                            </IonCol>
                        </IonRow>
                        <IonRow className='datos-atencion-accordion__row'>
                            <IonCol size="6">
                                <Texto1Linea
                                    colorBlack
                                    left
                                    texto={new Date(casoEnCursoSeleccionado.fechaCreacion).toLocaleDateString('es-ar')}
                                    txt16
                                />
                            </IonCol>
                            <IonCol size="6">
                                <Texto1Linea
                                    right
                                    colorBlack
                                    onClick={function noRefCheck(){}}
                                    texto={casoEnCursoSeleccionado.canalEntrada}
                                    txt16
                                />
                            </IonCol>
                        </IonRow>
                        <IonRow className='datos-atencion-accordion__row'>
                            <IonCol size='12'>
                                <Texto1Linea
                                    left
                                    onClick={function noRefCheck(){}}
                                    texto="Síntomas"
                                />
                                <Texto1Linea
                                    colorBlack
                                    left
                                    texto={casoBD?.sintomas ? casoBD.sintomas : '-'}
                                    txt16
                                />
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol size='12'>
                                <Texto1Linea
                                    left
                                    onClick={function noRefCheck(){}}
                                    texto="Observaciones adicionales"
                                />
                                {/* TODO ver porque no llegan las observacion adicionales */}
                                <Texto1Linea
                                    colorBlack
                                    left
                                    texto={casoBD?.observacionSintoma ? casoBD.observacionSintoma : '-'}
                                    txt16
                                />
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </>
            }
        </>
    )
}

export default DatosAtencionAccordion