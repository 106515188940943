import React from 'react'
import { Pregunta } from '../../interfaces/pregunta.interface';
import PreguntaItem from '../Pregunta/Pregunta';

const PreguntasList = ({ preguntas, handlerOptions }) => {

    const optionListHandler = (option: any, index) => {
        handlerOptions(option, index)
    }
    
    return (
        <>
            {
                preguntas.map((pregunta: Pregunta) => {
                    return (
                        <PreguntaItem key={pregunta.id} pregunta={pregunta} index={pregunta.id} optionListHandler={optionListHandler}/>
                    )
                })
            }
        </>
    )
}

export default PreguntasList;