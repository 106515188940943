import { clearAllStorage } from "./dataProvider";
import { Environment } from '../environment/Environment';
import { AUTH_SERVICE_URL, REVOKE, redirectTo } from "../utils/constants";
import axios from 'axios';

export const cerrarSesionHelper = async (history) => {
  
    const urlRevoke = `${Environment.ENV.BACKEND_ENDPOINT}${AUTH_SERVICE_URL}${REVOKE}`
    const accessToken = sessionStorage.getItem('access_token')
    const headers = {
      "Content-Type": "application/json"
    }
    try{
        const responseData = await axios.post(urlRevoke, {token: accessToken}, {headers: headers})
        if (responseData.status === 200) {
            console.info("Token revocado");
        }
    }
    catch(error){
        console.error("Error: revocarToken", { error });
    }
    finally{
        clearAllStorage()
        sessionStorage.removeItem('expires_in');
        sessionStorage.removeItem('refresh_token');
        sessionStorage.removeItem('id_token');
        sessionStorage.removeItem('access_token');
        sessionStorage.removeItem('codeVerifier')
        sessionStorage.removeItem('codeChallenge')
        
        const urlAuthIAMLogout = Environment.ENV.LOGOUT_URL + Environment.ENV.THEME_ID + redirectTo
        window.location.replace(urlAuthIAMLogout);
    }
};