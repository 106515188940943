import { IonCol, IonRow } from '@ionic/react';
import { RadioButtonGroup, Subtitulo } from 'app-principal-componentes-visuales';
import React from 'react'
import './Pregunta.scss'

const PreguntaItem = ({ pregunta, index, optionListHandler }) => {

    const optionSelectHandler = (event) => {
        event.persist();
        optionListHandler(event.target.value, index)
    }

    return (
        <IonRow>
            <IonCol className='pregunta__container'>
                <Subtitulo
                    txt18
                    titulo={pregunta.descripcion}
                    className='subtitulo-pregunta-container'
                    id="subtitulo-pregunta"
                />
                <RadioButtonGroup
                    radioList={[
                        {
                            btnBlue: true,
                            onClick: optionSelectHandler,
                            texto: 'Sí',
                            value: 'si'
                        },
                        {
                            btnBlue: true,
                            onClick: optionSelectHandler,
                            texto: 'No',
                            value: 'no'
                        }
                    ]}
                    titulo=""
                    valueSelected={ pregunta.respuesta }
                    className='boton-pregunta-container'
                    id="radio-button-preguntas"
                />
            </IonCol>
        </IonRow>
    )
}

export default PreguntaItem;