import { obtenerCasoByNroCaso } from "../api/casoService";
import { getHistorialFinalizados } from "../api/historialFinalizadoService";
import { HistorialFinalizado } from "../interfaces/historialFinalizado.interface";
import { InfoCaso } from "../models/caso.model";
import { Socio } from "../models/socioPrincipal.model";
import { ASISTENCIA_MEDICA } from "../utils/constants";

/**
 * Carga el historial de atenciones finalizados con la informacion proveniente de SALUS
 */
export const cargarHistorialFinalizado = (ic, apiRequest, socio, storageManager ) => {
    return new Promise< any[] >((resolve, reject) => {
        getHistorialFinalizados(ic, apiRequest)
            .then( atenciones => {
                let atencionesMappeadas = formatearHistorialFinalizadoSalus(atenciones, socio, storageManager);
                resolve(atencionesMappeadas);
        })
        .catch(error => {
            reject(error);
            console.error("No se pudieron recuperar las atenciones finalizados de SALUS.", error);
        });
    });
}

/**
 * Se encarga de mappear las atenciones finalizadas
 */
const formatearHistorialFinalizadoSalus = async (historialFinalizadosSalus: HistorialFinalizado[], socio, storageManager) => {
        let arrayAtencionesMapper: any = [];
        if (historialFinalizadosSalus.length > 0) {
            let datosExtras = await obtenerClasificacionYcanal(storageManager);
            //Filtra las atenciones que no deseamos mostrar en el listado de atenciones
            let arrayFiltrado = historialFinalizadosSalus.filter((atencionSalus)=> atencionSalus.recurso.codigoTipoServicio !== "GT")
            arrayAtencionesMapper = arrayFiltrado.map((atencion)=>{
                                            let atencionInfoCaso =  transformaCasoSalusAInfoCaso(atencion, socio, datosExtras.clasificaciones, datosExtras.servicios, datosExtras.canales);
                                            return atencionInfoCaso;
                                        });
        }  
        return arrayAtencionesMapper;
}

/**
 * Transforma el CasoSalus a InfoCaso
 */
const transformaCasoSalusAInfoCaso = (casoSalus: HistorialFinalizado, socioPrincipal, clasificaciones, servicios, canales) => {
    let infoCasoFinalizado = new InfoCaso();
    let socio = new Socio();
    let clasificacionSeleccionada = clasificaciones.find((clasificacion) => {
        if (clasificacion.descripcion === casoSalus.recurso.codigoSubtipoServicio &&
             clasificacion.servicio.descripcion === casoSalus.recurso.codigoTipoServicio) {
            return clasificacion;
        }
    })
    let canalSeleccionado = canales.find((canal) => {
        if (canal.codigo === casoSalus.recurso.codigoCanalEntrada) {
            return canal;
        }
    })
    socio.nombre = casoSalus.asistencia.nombre;
    socio.apellidos = casoSalus.asistencia.apellidos;
    socio.nroContrato = casoSalus.asistencia.numeroSocio;
    socio.fechaNacimiento = socioPrincipal.fechaNacimiento;
    socio.dni = socioPrincipal.dni;
    socio.email = socioPrincipal.email;
    socio.telefono = socioPrincipal.telefono;
    infoCasoFinalizado.principal = socio;
    infoCasoFinalizado.descripcion = clasificacionSeleccionada ? clasificacionSeleccionada?.clasificacionDetalle?.detalleListado : ASISTENCIA_MEDICA;
    infoCasoFinalizado.coseguro = casoSalus.coseguro;
    infoCasoFinalizado.prestador = casoSalus.recurso.prestador;
    infoCasoFinalizado.fechaCreacion = casoSalus.horaCreacion;
    infoCasoFinalizado.nroCaso = casoSalus.codigo;
    infoCasoFinalizado.estado = casoSalus.asistencia.estado;
    infoCasoFinalizado.estadoSalus = casoSalus.lCodigoUltimoEstatus; 
    infoCasoFinalizado.codigoTipoServicio = casoSalus.recurso.codigoTipoServicio;
    infoCasoFinalizado.codigoSubTipoServicio = casoSalus.recurso.codigoSubtipoServicio;
    infoCasoFinalizado.canalEntrada = canalSeleccionado.descripcion;
    infoCasoFinalizado.canalEntradaSalus = casoSalus.recurso.codigoCanalEntrada;
    return infoCasoFinalizado;
}

const obtenerClasificacionYcanal = (storageManager) => {
    return new Promise<any>((resolve,rejected) =>{
    //obtengo las clasificaciones, servicios y canales de entrada del storage
    storageManager.getDataStorage("clasificaciones")
        .then(async (clasificaciones)=>{
            storageManager.getDataStorage("servicios")
                .then(async (servicios)=>{
                    storageManager.getDataStorage("canales")
                        .then(async (canales)=>{
                            let datos = {
                                clasificaciones: [],
                                servicios: [],
                                canales: []
                            }
                            let clasificacionesParse = JSON.parse(clasificaciones);
                            let serviciosParse = JSON.parse(servicios);
                            let canalesParse = JSON.parse(canales);
                            datos = {...datos, clasificaciones: clasificacionesParse, servicios: serviciosParse, canales: canalesParse}
                            resolve(datos);                
                        })
                })                
        })
    })
}