import { IonPage } from '@ionic/react'
import { Feedback, Modal } from 'app-principal-componentes-visuales'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom';
import Contactanos from '../../components/Modals/Contactanos/Contactanos';
import { HOME, TITULO_INFORMACION_IMPORTANTE } from '../../utils/constants';

const ErrorReintentar = ({setReintentarError, setReintentarRequest}) => {
    const history = useHistory();
    const [showContactanosModal, setShowContactanosModal] = useState(false);

    const handleReintentar = () => {
        setReintentarError(false);
        setReintentarRequest(true);
        history.replace(HOME);
    }

    const handleContactanos = () => {
        setShowContactanosModal(true);
    }

    const handleVolverInicio = () => {
        history.push('/');
    }

    return (
        <IonPage>
            <Feedback
                handsPhoneError
                asistencia
                labelButtonCancel="Reintentar"
                labelButtonOk="Contactanos"
                labelLink="Volver al inicio"
                onClickCancel={handleReintentar}
                onClickOk={handleContactanos}
                onClickLink={handleVolverInicio}
                parrafo="Estamos trabajando para resolverlo. Podés contactarnos a través de WhatsApp o llamada telefónica."
                titulo="Hubo un problema técnico"
                id="feedback-errorconexion"
            />

            <Modal
                className='modal_web'
				hideCloseBtn={false}
                //@ts-ignore
				component={<Contactanos menorSeisMeses={false} titulo={TITULO_INFORMACION_IMPORTANTE}/>}
				setShowModal={setShowContactanosModal}
				showModal={showContactanosModal}
				id="error_page-modal"
			/>
        </IonPage>
    )
}

export default ErrorReintentar