import { IonCol, IonContent, IonFooter, IonGrid, IonHeader, IonItem, IonLabel, IonPage, IonRow, IonText } from '@ionic/react'
import { Button, Titulo } from 'app-principal-componentes-visuales'
import React from 'react'
import './TerminosYCondiciones.scss'

const TerminosYCondiciones = ({ onClickAccept, terminosAceptados } : {[ key: string ]: any }) => {
    return (
        <IonPage className='asistencia-medica asistencia-terminos-condiciones'>
            <IonHeader className='modal__header ion-no-border'>
                <IonGrid>
                    <IonRow>
					    <IonCol size="12">
					    	<Titulo titulo="Términos y condiciones" id="aistencia-terminos-condiciones" />
					    </IonCol>
				    </IonRow>
                </IonGrid>
            </IonHeader>
            
            <IonContent fullscreen>
                <IonGrid className='modal__grid'>
                    <IonRow>
                        <IonCol size='12'>
                            <IonItem lines='none'>
                                <IonText className='text__label'>
                                    <IonLabel className='ion-text-wrap text__label--color'>
                                    OSDE Organización de Servicios Directos Empresarios pone a disposición esta aplicación para
                                    facilitar tu acceso al servicio de Urgencias. <br/><br/>
                                    Su utilización es voluntaria y, en consecuencia, prestás el debido consentimiento en forma
                                    expresa para que OSDE pueda utilizar los datos que brindes -tanto en esta como en futuras
                                    consultas- para coordinar en forma efectiva las prestaciones de salud que requieras.<br/><br/>
                                    A través de ella podrás solicitar una asistencia médica e indicar si la consulta es para el titular o
                                    para un integrante de tu grupo familiar y completar toda la información que se solicita relativa a la
                                    persona. Los socios menores de 13 años de edad no podrán utilizar la aplicación en forma
                                    directa.<br/><br/>
                                    Tené en cuenta que si el paciente pertenece a los grupos de riesgo, se deberá continuar la
                                    comunicación en forma telefónica llamando al 0810-888-7788 o al 0810-999-6300.<br/><br/>
                                    Este medio de comunicación no fue creado para atender a personas con riesgo de vida y, por lo
                                    tanto, si considerás que te encontrás en una situación en la que tu vida corre peligro, llamanos al
                                    0810-666-1111.<br/><br/>
                                    En caso de que se requiera continuar con asistencia domiciliaria deberás indicar la dirección a
                                    donde se enviará la atención de Urgencias. Siendo dicha información suficiente para que OSDE
                                    gestione el servicio.<br/><br/>
                                    Toda información que ingreses a la aplicación será considerada como una declaración jurada de
                                    salud en cuanto a su veracidad y exactitud, así como respecto a sus datos de identificación
                                    personal. En consecuencia, asumís íntegramente las responsabilidades que puedan derivarse
                                    por daños y perjuicios derivados de la inexactitud de la información proporcionada, tanto hacia tu
                                    persona como hacia los profesionales intervinientes y/o terceros que puedan o deban tomar
                                    decisiones basándose en ella.<br/><br/>
                                    El Cuerpo Médico de Urgencias de OSDE, podrá requerir mayor detalle cuando considere que
                                    los datos ingresados son incompletos o no se corresponden con los patrones habituales de
                                    evolución de la enfermedad declarada.<br/><br/>
                                    Esta información será reunida en una base de datos debidamente registrada conforme Articulo
                                    3, 21, sgtes y CC de la Ley 25326, para ser utilizada por OSDE no solo para acercarte un mejor
                                    servicio, sino para poder mejorar nuestras formas de contención y atención médico asistencial
                                    para todos los afiliados que utilicen la app Asistencia médica.<br/><br/>
                                    Todos los datos que se aporten a esta aplicación se encuentran protegidos por la Ley 25.326 de
                                    Protección de Datos Personales y su normativa reglamentaria, complementaria y concordante.
                                    En virtud de ello y dado que la información suministrada relativa a tu salud tiene carácter 
                                    sensible, OSDE compromete guardar estricta confidencialidad a efectos de evitar su divulgación indebida.<br/><br/>
                                    Para más información sobre nuestras políticas de privacidad, ingresá a <a className='text__label' href="https://www.osde.com.ar/informacion-adicional/acuerdo-con-el-usuario">
                                    https://www.osde.com.ar/informacion-adicional/acuerdo-con-el-usuario</a>.<br/><br/>
                                    En caso de que decidas continuar con la aplicación, solicitamos aceptes en forma expresa los
                                    términos y condiciones referidos a su funcionamiento, su objeto y todo lo concerniente a la
                                    Política de Protección de Datos y de Confidencialidad.                                       
                                    </IonLabel>
                                </IonText>
                            </IonItem>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>

            {/* 
            //no se visibiliza el boton, aunque el terminos y condiciones este en false
            { !terminosAceptados && 
                <IonFooter className='footer ion-no-border'>
                    <Button className='footer_button' label="Aceptar" primary 
                        onClick={onClickAccept}
                    />
                </IonFooter>
            } */}
        </IonPage>
    )
}

export default TerminosYCondiciones