import { IonCol, IonRow } from '@ionic/react';
import { CardAsistencia, InputText, Modal, Solapas, Texto1Linea, Titulo } from 'app-principal-componentes-visuales'
import React, { useState, useContext, useEffect} from 'react'
import { getCasosEnCurso } from '../../api/salusService';
import FiltroHistorialAtenciones from '../../components/Modals/FiltroHistorialAtenciones/FiltroHistorialAtenciones';
import HistorialSkeleton from '../../components/Skeletons/HistorialSkeleton/HistorialSkeleton';
import { AsistenciaMedicaContext } from '../../contexts/AsistenciaMedicaContext';
import { filtrarCasosEnCurso, filtrarCasosEnHistorial } from '../../helpers/serviciosEnCursoHelper';
import { AsistenciaMedicaActionType } from '../../interfaces/global-state/asistencia.medica.action.type.enum';
import { DETALLE_CASO, ESTADOS_ASIGNADOS, ESTADOS_CONFIRMADOS, HISTORIAL_ATENCIONES } from '../../utils/constants';
import { capitalizeString } from '../../utils/utils';
import { cargarHistorialFinalizado } from '../../helpers/historialFinalizadoHelper';
import AtencionesFinalizadasSkeleton from '../../components/Skeletons/AtencionesFinalizadasSkeleton/AtencionesFinalizadasSkeleton';
import PageWrapper from '../../components/PageWrapper/PageWrapper';
import './HistorialAtenciones.scss'

const HistorialAtenciones = ({ history }) => {

    const { asistenciaMedica, useRequest, dispatchAsistenciaMedica, storageManager, isWeb } = useContext(AsistenciaMedicaContext);
    const { apiRequest } = useRequest();
    const { asistenciasFinalizadas } = asistenciaMedica;
    const {serviciosEnCurso} = history.location.state;
    const socio = asistenciaMedica.usuario;
    const isPrincipal = socio.contratos !== undefined;
    const [showGrupoFamiliarModal, setShowGrupoFamiliarModal] = useState(false);
    const [grupoFamiliar, setGrupoFamiliar] = useState([])
    const [placeholder, setPlaceholder] = useState('Todo el grupo familiar');
    const [placeholderFinalizadas, setPlaceholderFinalizadas] = useState(capitalizeString(socio.nombre + ' ' + socio.apellidos));
    const [serviciosEnCursoFilter, setServiciosEnCursoFilter] = useState(serviciosEnCurso)
    const [serviciosFinalizados, setServiciosFinalizados] = useState(asistenciasFinalizadas);
    const [seleccionados, setSeleccionados] = useState([]); 
    const [historialSkeleton, setHistorialSkeleton] = useState(true);
    const [finalizadosSkeleton, setFinalizadosSkeleton] = useState(false);
    const [icFinalizados, setIcFinalizados] = useState(socio.ic);
    const [tabSeleccionado, setTabSeleccionado] = useState('en-curso');
    const [casoFinalizadoPorFecha, setCasoFinalizadoPorFecha] = useState([]);
    const [casoFinalizadosOrdenados, setCasoFinalizadosOrdenados] = useState([]);

    useEffect(() => {
        const grupoFamiliarAux = socio.familiares.length > 0 ? [...socio.familiares] : []
        grupoFamiliarAux.unshift(socio)
        setGrupoFamiliar(grupoFamiliarAux)
        setSeleccionados(grupoFamiliarAux);   
        cargarHistorial();
    }, []);

    const openSelectGrupoFamiliarModal = () => {
        setShowGrupoFamiliarModal(true);
    };

    const handleDetalleCaso = (servicioEnCurso) => {
        let casoEnCursoSeleccionado = { casoEnCursoSeleccionado: servicioEnCurso, from: HISTORIAL_ATENCIONES };
        history.push(DETALLE_CASO, casoEnCursoSeleccionado);
    }

    /**
   * Obtiene la lista de casos basado en el numero de contrato guardado en cada caso.
   */
    const cargarHistorial = async () => {
        setHistorialSkeleton(true);
        try {
            const casos = await getCasosEnCurso(socio.ic, apiRequest);
            if(casos?.status === 200) {
                storageManager.setDataStorage("casosEnCurso", JSON.stringify(casos?.data));
                const casosHistorial = await filtrarCasosEnHistorial(casos?.data, storageManager);
                casosHistorial.sort((a, b) => new Date(b.fechaCreacion).getTime() - new Date(a.fechaCreacion).getTime())
                const casosFiltrados = await filtrarCasosEnCurso(casosHistorial);
                //TODO ¿es necesario filtrar con el metodo de filtrarAtenciones encargado de filtrar los servicios diferentes a GT?
                setServiciosEnCursoFilter(casosFiltrados);
                dispatchAsistenciaMedica({
                    type: AsistenciaMedicaActionType.actualizarCasosEnCurso, 
                    casosEnCurso: casosFiltrados
                });
            } else {
                setServiciosEnCursoFilter(serviciosEnCurso);
            }
        } catch(error) {
            console.error(`ASIS-MED - Hubo un problema asociado al llamado de servicios en curso ${error}`);
        } 
        
        historialAtencionesFinalizadas(socio.ic);
    
    }

    /**
     * Encargado de obtener las atenciones finalizadas, y guardarlas en el contexto
     */
    const historialAtencionesFinalizadas = async (ic) => {
        try {
            let socioFinalizadas;
            if (ic === socio.ic) {
                socioFinalizadas = socio;
            } else {
                let auxiliar = grupoFamiliar.filter(familiar => {
                    if (ic === familiar.ic) { 
                        return familiar;
                    }
                })
                socioFinalizadas = auxiliar[0];
            }
            const casosHistorialFinalizados = await cargarHistorialFinalizado(ic, apiRequest, socioFinalizadas, storageManager);
            casosHistorialFinalizados.sort((a, b) => new Date(b.fechaCreacion).getTime() - new Date(a.fechaCreacion).getTime())
            setServiciosFinalizados(casosHistorialFinalizados);
            setIcFinalizados(ic);
            if(ic === socio.ic) {
                dispatchAsistenciaMedica({
                    type: AsistenciaMedicaActionType.actualizarAtencionesFinalizadas, 
                    casosFinalizados: casosHistorialFinalizados
                });
            }
            setFinalizadosSkeleton(false);
            filtrarAtencionesPorFecha(casosHistorialFinalizados);
            setHistorialSkeleton(false);
        } catch (error: any) {
            console.error(`ASIS-MED - Hubo un problema asociado al llamado del servicio de Salus ${error}`);
            setFinalizadosSkeleton(false);
            setHistorialSkeleton(false);
        }
    }

    /**
     * Encargado de separar las fecha de las atenciones finalizadas por año 
     * @param casosSinDuplicados
     */
    const filtrarAtencionesPorFecha = (casosSinDuplicados) => {
        let casosFinalizadosPorFecha = [];

        casosFinalizadosPorFecha = casosSinDuplicados.reduce((resultado, caso) => {
            const fechaCreacion = new Date(caso.fechaCreacion);
            const year = fechaCreacion.getFullYear().toString();

            if (!resultado[year]) {
                resultado[year] = [];
            }
            resultado[year].push(caso);
            return resultado;
        }, {});

        const finalizadosOrdenados = Object.keys(casosFinalizadosPorFecha).sort((a, b) => {
            const fechaA = new Date(casosFinalizadosPorFecha[a][0].fechaCreacion).getTime();
            const fechaB = new Date(casosFinalizadosPorFecha[b][0].fechaCreacion).getTime();
            return fechaB - fechaA;
        });

        setCasoFinalizadosOrdenados(finalizadosOrdenados)
        setCasoFinalizadoPorFecha(casosFinalizadosPorFecha);
    }
    
    const handleTabSelected = (seleccionado) => {
        setTabSeleccionado(seleccionado);
    }

    const handleHistorial = (socioSeleccionado) => {
        setFinalizadosSkeleton(true);
        historialAtencionesFinalizadas(socioSeleccionado);
    }

    return (
        <>
            { historialSkeleton ?
                <PageWrapper 
                pageClassName='asistencia-medica asistencia-historial-atenciones'
                socio={asistenciaMedica.usuario}
                path={HISTORIAL_ATENCIONES}
                notCancel
                tituloHeader={'Historial de asistencias'}
                className={isWeb ? 'h-100 mt-40' : ''}
            >
                <HistorialSkeleton/>
            </PageWrapper>
            :
            <>
                <PageWrapper 
                    pageClassName='asistencia-medica asistencia-historial-atenciones'
                    socio={asistenciaMedica.usuario}
                    path={HISTORIAL_ATENCIONES}
                    notCancel
                    tituloHeader={'Historial de asistencias'}
                    className={isWeb ? 'h-100 mt-40' : ''}
                >
                    <IonRow className='tabs__padding'>
                        <IonCol size='12'>
                            <Solapas
                                className=""
                                activeValue={tabSeleccionado}
                                id="asistencia-medica-historial-tabs"
                                onClick={function noRefCheck() { }}
                                onIonChange={function noRefCheck() { }}
                                segmentButton={[
                                    {
                                        cantidad: serviciosEnCursoFilter.length,
                                        onClick: () => handleTabSelected('en-curso'),
                                        titulo: 'En curso',
                                        value: 'en-curso'
                                    },
                                    {
                                        cantidad: serviciosFinalizados.length,
                                        onClick: () => handleTabSelected('finalizadas'),
                                        titulo: 'Finalizadas',
                                        value: 'finalizadas'
                                    }
                                ]}
                            />
                        </IonCol>
                    </IonRow>

                    { tabSeleccionado === 'en-curso' ? 
                        <>
                            <IonRow className='input-padding'>
                                <IonCol>
                                    <InputText
                                        id="datos-contacto-historial-input"
                                        cambiarLink
                                        label="¿De qué integrante?"
                                        onClick={openSelectGrupoFamiliarModal}
                                        type="text"
                                        value={placeholder}
                                        placeholder={placeholder}
                                        disable={isPrincipal ? 
                                        (socio.familiares.length > 0 ? false : true)
                                        : false}
                                    />
                                </IonCol>
                            </IonRow>
                            { serviciosEnCursoFilter.length === 0 ?
                                <>
                                    <IonRow className = "lista-atenciones-vacio">
                                        <IonCol size='12'>
                                            <Texto1Linea
                                                colorBlack
                                                left
                                                texto="No tenés asistencias en curso"
                                                txt16
                                            />
                                            
                                        </IonCol>
                                        <IonCol size='12'>
                                            <Texto1Linea
                                                left
                                                texto="Cuando pidas una, la podrás visualizar acá."
                                            />
                                        </IonCol>
                                    </IonRow>
                                </>
                            :
                            <IonRow className = "card-atenciones-historial">
                                <IonCol size='12'>
                                    
                                    { serviciosEnCursoFilter.map((servicioEnCurso) => {
                                        let fechaCreacion = new Date(servicioEnCurso.fechaCreacion)
                                        let mesActual = new Intl.DateTimeFormat('es-ES', { month: 'short'}).format(fechaCreacion);
                                        return (
                                            (servicioEnCurso.estado !== 'F') && (
                                                <IonRow className='card-atenciones-historial'>
                                                    <IonCol>
                                                        <CardAsistencia
                                                            dia={fechaCreacion.getDate().toString()}
                                                            id="asistencia-medica-card-asistencia"
                                                            mes={mesActual}
                                                            turnos={[
                                                                {
                                                                    creada: (ESTADOS_ASIGNADOS.includes(servicioEnCurso.estadoSalus) || servicioEnCurso.estadoSalus === '') ? true : false,
                                                                    enEspera: ESTADOS_CONFIRMADOS.includes(servicioEnCurso.estadoSalus) ? true : false,
                                                                    onClick: () => {handleDetalleCaso(servicioEnCurso)},
                                                                    especialidadPara: servicioEnCurso.descripcion,
                                                                    prestador: capitalizeString(servicioEnCurso.principal.nombre + ' ' + servicioEnCurso.principal.apellidos),
                                                                    verDetalle: true
                                                                }
                                                            ]}
                                                        />
                                                    </IonCol>
                                                </IonRow>
                                            )
                                        )
                                    })}
                                </IonCol>
                            </IonRow>
                            }
                        </>
                    :
                        <>
                        { finalizadosSkeleton ?
                            <AtencionesFinalizadasSkeleton/> :
                            <>
                                <IonRow className='input-padding'>
                                    <IonCol>
                                        <InputText
                                            id="datos-contacto-historial-input"
                                            cambiarLink
                                            label="¿De qué integrante?"
                                            onClick={openSelectGrupoFamiliarModal}
                                            type="text"
                                            value={placeholderFinalizadas}
                                            placeholder={placeholderFinalizadas}
                                            disable={isPrincipal ? 
                                            (socio.familiares.length > 0 ? false : true)
                                            : false}
                                        />
                                    </IonCol>
                                </IonRow>
                                { serviciosFinalizados.length === 0 ?
                                    <>
                                        <IonRow className = "lista-atenciones-vacio">
                                            <IonCol size='12'>
                                                <Texto1Linea
                                                    colorBlack
                                                    left
                                                    texto="No tenés asistencias finalizadas"
                                                    txt16
                                                />
                                                
                                            </IonCol>
                                            <IonCol size='12'>
                                                <Texto1Linea
                                                    left
                                                    texto="Cuando tengas una, la podrás visualizar acá."
                                                />
                                            </IonCol>
                                        </IonRow>
                                    </>
                                :
                                    <IonRow>
                                        <IonCol size='12'>
                                            {casoFinalizadosOrdenados.map((year) => (
                                                <div key={year}>
                                                    <IonRow>
                                                        <IonCol size='12'>
                                                            <Titulo
                                                                fontXs
                                                                className='year'
                                                                titulo={year}
                                                                id="titulo-asistencia-year"
                                                            />
                                                        </IonCol>
                                                    </IonRow>
                                                {casoFinalizadoPorFecha[year].map((servicioFinalizado) => {
                                                    const fechaCreacion = new Date(servicioFinalizado.fechaCreacion);
                                                    const mesActual = new Intl.DateTimeFormat('es-ES', { month: 'short'}).format(fechaCreacion);
                                                    
                                                    return (
                                                        <IonRow className='card-atenciones-historial' key={servicioFinalizado.id}>
                                                            <IonCol>
                                                                <CardAsistencia
                                                                    dia={fechaCreacion.getDate().toString()}
                                                                    id="asistencia-medica-card-asistencia"
                                                                    mes={mesActual}
                                                                    turnos={[
                                                                        {
                                                                        onClick: () => {handleDetalleCaso(servicioFinalizado)},
                                                                        especialidadPara: servicioFinalizado.descripcion,
                                                                        familiar: capitalizeString(servicioFinalizado.principal.apellidos + ' ' + servicioFinalizado.principal.nombre),
                                                                        prestador: capitalizeString(servicioFinalizado.prestador),
                                                                        verDetalle: true
                                                                        }
                                                                    ]}
                                                                />
                                                            </IonCol>
                                                        </IonRow>
                                                    );
                                                })}
                                            </div>
                                            ))}
                                        </IonCol>
                                    </IonRow>
                                }
                            </>
                        }
                        </>
                }
                </PageWrapper>
                <Modal
                    className='modal_web-large'
                    hideCloseBtn={false}
                    setShowModal={setShowGrupoFamiliarModal} 
                    showModal={showGrupoFamiliarModal}
                    id="historial-grupo-familiar-modal"
                    //@ts-ignore
                    component={
                        <FiltroHistorialAtenciones
                            isWeb={isWeb}
                            tabSeleccionado={tabSeleccionado}
                            seleccionados={seleccionados}
                            setSeleccionados={setSeleccionados}
                            setShowGrupoFamiliarModal={setShowGrupoFamiliarModal}
                            setPlaceholder={setPlaceholder}
                            setPlaceholderFinalizadas={setPlaceholderFinalizadas}
                            grupoFamiliar={grupoFamiliar}
                            setServiciosEnCursoFilter={setServiciosEnCursoFilter}
                            serviciosEnCurso={serviciosEnCurso}
                            serviciosFinalizados={asistenciasFinalizadas}
                            setServiciosFinalizados={setServiciosFinalizados}
                            handleHistorial={handleHistorial} 
                            icFinalizados={icFinalizados}
                        />
                    }
                />  
            </>
            }
        </>
    )
}

export default HistorialAtenciones;