import { getDireccionNormalizada } from "../api/direccionService";
import { DireccionNormalizadaMerlin } from "../interfaces/merlin/direccionNormalizada.interface";
import { DireccionANormalizar } from "../interfaces/merlin/direccionANormalizar.interface";
import { ResponseMerlin } from "../models/responseMerlin";
import { ALTURA_INVALIDA, CALLE_INVALIDA, CORREGIDO, DUDA_ESTANDAR, DUDOSO, NO_ENCONTRADO } from "../utils/constants";
import { Direccion } from "../interfaces/direccion.interface";

export const normalizarDireccion = (direccion: DireccionANormalizar, apiRequest: any) => {
    return new Promise<ResponseMerlin>((resolve,reject) => {
        // TODO BARRIO no coincide  
        let params = {  
            filter : `barrio=${direccion.barrio}, calle=${direccion.calle}, localidad=${direccion.localidad}, numero=${direccion.altura}, provincia=${direccion.provincia}`
        }
        let responseMerlin = new ResponseMerlin();
        let direccionNormalizada = {} as Direccion;
        getDireccionNormalizada(params, apiRequest)
            .then((direccionMerlin: DireccionNormalizadaMerlin) => {
                switch (direccionMerlin.estado) {
                    case CORREGIDO: {
                        direccionNormalizada.posicionExacta = true;
                        direccionNormalizada.calle = direccionMerlin.calle;
                        direccionNormalizada.entreCalleUno =
                            direccionMerlin.primeraCalleDelimitante;
                        direccionNormalizada.entreCalleDos =
                            direccionMerlin.segundaCalleDelimitante;
                        direccionNormalizada.latitud = direccionMerlin.latitud;
                        direccionNormalizada.longitud = direccionMerlin.longitud;
                        responseMerlin.direccionNormalizada = direccionMerlin;
                        responseMerlin.status = direccionMerlin.estado;
                        break;
                    }
                    case NO_ENCONTRADO: {
                        responseMerlin.status = direccionMerlin.estado;
                        if (direccionMerlin.motivo === ALTURA_INVALIDA) {
                            responseMerlin.datoInvalido!.nombre = 'altura';
                            responseMerlin.datoInvalido!.valor = direccion.altura;
                        } else if (direccionMerlin.motivo === CALLE_INVALIDA) {
                            responseMerlin.datoInvalido!.nombre = 'calle';
                            responseMerlin.datoInvalido!.valor = direccion.calle;
                        }
                        break;
                    }
                    case DUDOSO: {
                        responseMerlin.status = direccionMerlin.estado;
                        if (direccionMerlin.motivo === DUDA_ESTANDAR) {
                            responseMerlin.opciones =  direccionMerlin.dudas!;
                            responseMerlin.datoInvalido!.nombre = 'calle';
                            responseMerlin.datoInvalido!.valor = `${direccionMerlin.calle} ${direccionMerlin.numero}`;
                        }
                        break;
                    }
                }
                return resolve(responseMerlin);
            })
            .catch((error) => reject(error));
    })
}