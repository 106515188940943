import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
 
const container: any = document.getElementById("root");
const root = createRoot(container);
root.render( <App />);

// Call the element loader after the platform has been bootstrapped
defineCustomElements(window);