import { IonCol, IonContent, IonFooter, IonGrid, IonPage, IonRow } from '@ionic/react';
import { Button, Modal, Separador, Titulo } from 'app-principal-componentes-visuales';
import React, { useContext, useEffect, useState } from 'react';
import './Preguntas.scss';
import PreguntasList from '../../components/PreguntasList/PreguntasList';
import PreguntaObservacion from '../../components/PreguntaObservacion/PreguntaObservacion';
import { HOME, PREGUNTAS } from '../../utils/constants';
import { CABA, CAPITAL, NACIONAL, PANTALLA_TELEFONO, PEDIDO_SERVICIO, RESUMEN, SERVICIO_EM, SERVICIO_IC, SERVICIO_VM, TELEFONOS_PAGE, TITULO_INFORMACION_IMPORTANTE, serviciosEMAceptados, serviciosICAceptados, serviciosVMAceptados } from '../../utils/constants';
import { AsistenciaMedicaContext } from '../../contexts/AsistenciaMedicaContext';
import { AsistenciaMedicaActionType } from '../../interfaces/global-state/asistencia.medica.action.type.enum';
import { GA_KEYS, mapGA } from '../../helpers/GTM';
import SessionHeader from '../../components/Headers/SessionHeader/SessionHeader';
import Footer from '../../components/Footer/Footer';
import Contactanos from '../../components/Modals/Contactanos/Contactanos';
import { getCoseguro, getDisponibilidad, getReglas } from '../../api/asistenciaMedicaService';
import { Regla } from '../../interfaces/regla/regla.interface';
import { ReglaAlternativa } from '../../interfaces/regla/reglaAlternativa.interface';
import { ClasificacionModel } from '../../interfaces/clasificacion.interface';
import { Direccion } from '../../interfaces/direccion.interface';
import { calcularEdad } from '../../utils/utils';
import { Alternativa } from '../../interfaces/regla/alternativa.interface';
import { Servicio } from '../../interfaces/servicio.interface';
import { Disponibilidad } from '../../interfaces/disponibilidad.interface';
import { sendWhatsAppMessage } from '../../api/mensajeriaService';
import ErrorPage from '../ErrorPage/ErrorPage';
import PageWrapper from '../../components/PageWrapper/PageWrapper';
import { carritoAbandonado } from '../../helpers/whatsappSalienteHelper';
import { whatsappCarritoAbandonado } from '../../utils/whatsappUtils';

const Preguntas = ({history}) => {

    const { useRequest, asistenciaMedica, dispatchAsistenciaMedica, setupData, storageManager } = useContext(AsistenciaMedicaContext);
    
    const { apiRequest } = useRequest();
    
    const [preguntasHelper, setPreguntasHelper] = useState([]);
    const [showContactanosModal, setShowContactanosModal] = useState(false)
    const [waitingButton, setWaitingButton] = useState(false)
    const [error, setError] = useState(false)
    const [direccionSocio, setDireccionSocio] = useState(asistenciaMedica.direccionSeleccionada);
    const [socioSeleccionado, setSocioSeleccionado ] = useState(asistenciaMedica.socioSeleccionado);
    const [errorDisponibilidad, setErrorDisponibilidad] = useState(false);
    const [mensaje, setMensaje] = useState('');
    const [carritoAbandonadoDisable, setCarritoAbandonadoDisable] = useState(false);
    const [observaciones, setObservaciones] = useState("")
    
    const {preguntas, sintomaConPrioridad} = history.location.state;

    let disponibilidadFinal = [];
    let coseguroFinal: any;
    let clasificaciones: ClasificacionModel[] = [];
    let tarjetaTelefono = false;
    let ubicacionSocio = NACIONAL;
    let admiteDisponibilidadEnZona = true;

    useEffect(() => {
        const preguntasAux = preguntas.map((pregunta, index) => {
            let preguntasRespondidas = null;
            if(history.action === 'POP'){
                preguntasRespondidas = asistenciaMedica?.datosParaTriage?.preguntasRespondidas;
            }
            
            if(preguntasRespondidas){
                return {...pregunta, respuesta: preguntasRespondidas[index].respuesta}
            } else {
                return { ...pregunta, respuesta: null }
            }
        })
        setPreguntasHelper(preguntasAux);

        if(asistenciaMedica?.datosParaTriage?.observaciones){
            setObservaciones(asistenciaMedica?.datosParaTriage?.observaciones)
        }

        storageManager.getDataStorage("deshabilitacion").then(deshabilitarModulos => {
            let deshabilitarParse = JSON.parse(deshabilitarModulos);
            setCarritoAbandonadoDisable(deshabilitarParse?.deshabilitarDisponibilidad);
        })
    }, [preguntas])

    const handlerOptions = (option, index) => {
        const preguntas = preguntasHelper.map(pregunta => {
            if (pregunta.id == index) {
                return { ...pregunta, respuesta: option }
            } else {
                return pregunta
            }
        })

        setPreguntasHelper(preguntas)
    }

    const handleDisableButton = () => {
        return preguntasHelper.some( p => {
           return p.respuesta === null
        } )
    }

    const handleObservacion = (observacion) => {
        setObservaciones(observacion)
    }

    /**
     * Metodo encargado de ordenar las reglas alternativas por su prioridad y realizar la consulta a disponibilidad en zona  
     */
    const validacionDeClasificacion = (reglaAlternativa: ReglaAlternativa[]) => {
        return new Promise(async (resolve, reject) => {
            reglaAlternativa.sort((a, b) => (a.prioridad < b.prioridad) ? -1 : 1);
            let pantalla = await consultoDisponibilidadEnZona(reglaAlternativa);
        
        resolve(pantalla);
        });
    }

    /**
     * Metodo que realiza filtra las reglas alternativas por prioridad y luego realiza el analisis de cuales realizar dicho request a disponibilidad en zona
     */
    const consultoDisponibilidadEnZona = async (reglaAlternativa) => {
        let direccion: Direccion = direccionSocio;
        let provincia = direccion.provincia === CABA ? CAPITAL : direccion.provincia;
        let localidad = direccion.localidad;
        let barrio = direccion.barrio;
        let esMenor = calcularEdad(socioSeleccionado.fechaNacimiento) < 18;
        let prioridadesFiltradas;
        let pantalla;
        for (let i = 0; i < reglaAlternativa.length; i++) {
            prioridadesFiltradas = reglaAlternativa.filter(ra => ra.prioridad == reglaAlternativa[i].prioridad);
            pantalla = await analisisDisponibilidadPorPrioridad(prioridadesFiltradas, provincia, localidad, barrio, esMenor);
            if(tarjetaTelefono || clasificaciones.length > 0){
                break;
            } else {
                reglaAlternativa = reglaAlternativa.filter(ra => ra.prioridad != reglaAlternativa[i].prioridad);
                i=-1;
            }
        }
    
        return pantalla;
    }

    /**
     * Metodo que realiza la peticion a disponibilidad en zona para las reglas que contengan segun su clasificacion una disponibilidad activa
     */
    const analisisDisponibilidadPorPrioridad = async (reglaAlternativa, provincia, localidad, barrio, esMenor) => {
        let pantalla = PANTALLA_TELEFONO;
        for (let i = 0; i < reglaAlternativa.length; i++) {
            let alternativa: Alternativa = reglaAlternativa[i].alternativa;
            let clasificacion: ClasificacionModel = alternativa.clasificacion;
            let servicio: Servicio = alternativa.servicio;
            let disponibilidad: Disponibilidad = clasificacion.disponibilidad;
            if (alternativa.salida != 'servicio') {
                tarjetaTelefono = true;
                break;
            } else if (disponibilidad.activa) {
                if (ubicacionSocio === NACIONAL && (disponibilidad.ubicacion === NACIONAL || disponibilidad.ubicacion === 'Ambas')) {
                    if(clasificacion.consultaDisponibilidad && admiteDisponibilidadEnZona) {
                        try {
                            const disponibilidad = await getDisponibilidad(provincia, localidad, barrio, servicio.descripcion, clasificacion.descripcion, esMenor, apiRequest);
                            let disponibilidades = [];
                            disponibilidades.push(disponibilidad);
                            disponibilidadFinal.push(disponibilidad)
                            if(disponibilidad.disponibilidad){
                                pantalla = await cargarCoseguro(provincia, localidad, servicio.descripcion, clasificacion, pantalla);
                            } else {
                                salidaCarritoAbandonado();
                            }
                        } catch(error) {
                            salidaCarritoAbandonado();
                            console.error("Ocurrio un problema al realizar la peticion a disponibilidad en zona:", error);
                        };
                    
                    } else {
                        pantalla = await cargarCoseguro(provincia, localidad, servicio.descripcion, clasificacion, pantalla);
                    }
                }
            }
            // Se deja comentado ya que por el momento no se utilizara la opcion internacional del socio. Se mantendra solamente la nacional.
            // else if (this.ubicacionSocio === 'Internacional' && (disponibilidad.ubicacion === 'Internacional' || disponibilidad.ubicacion === 'Ambas')) {
            //   this.clasificaciones.push(clasificacion);
            //   pantalla = PANTALLA_SERVICIO;
            //   break;
            // }
        }
        return pantalla;
    }

    const cargarCoseguro = async (provincia, localidad, servicio, clasificacion, pantalla) => {

        try {
            const coseguroResponse = await getCoseguro(socioSeleccionado.nroContrato, servicio, clasificacion.descripcion, provincia, localidad, apiRequest)
            clasificacion.coseguro = coseguroResponse.valorFinal;
            clasificacion.errorCoseguro = '';
            coseguroFinal = {
                coseguroValorFinal: coseguroResponse.valorFinal,
                errorCoseguro: ''
            }
        } catch(error) {
            clasificacion.errorCoseguro = error;
            coseguroFinal = {
                coseguroValorFinal: 0,
                errorCoseguro: clasificacion.errorCoseguro
            }
            setWaitingButton(false);
            console.error('Ocurrio un error con el request de coseguro:', error);
        } finally {
            clasificaciones.push(clasificacion);
        }   
        return pantalla = PEDIDO_SERVICIO;
    }

    /**
     * Metodo que realiza los llamados en caso de ser necesario enviar mensaje de whatsapp
     */
    const handleWhatsappMessage = () => {
        let casosEnCurso = [];

        storageManager.getDataStorage("casosEnCurso").then(casos => {
            casosEnCurso = JSON.parse(casos);        
            let flag = true;

            if(casosEnCurso.length > 0) {
                casosEnCurso.forEach(caso => {
                    if (
                        caso.numeroSocio === asistenciaMedica?.socioSeleccionado?.nroContrato &&
                        (
                        (caso.codigoTipoServicio === SERVICIO_VM && serviciosVMAceptados.includes(caso.codigoSubTipoServicio)) ||
                        (caso.codigoTipoServicio === SERVICIO_IC && serviciosICAceptados.includes(caso.codigoSubTipoServicio)) ||
                        (caso.codigoTipoServicio === SERVICIO_EM && serviciosEMAceptados.includes(caso.codigoSubTipoServicio))
                        )
                        ) {
                            flag = false;
                        }
                });
            };
        
            if(flag) {
                sendWhatsAppMessage(socioSeleccionado, apiRequest)
                    .then(resp => {
                        console.info("Mensaje saliente persistido en la db exitosamente");
                    }).catch(error => {
                        console.error("Error al persistir mensaje saliente en la db", error);
                    });
            }
        });
    };

    const botonContinuarHandler = () => {
        
        setWaitingButton(!waitingButton);
        
        let preguntas = ''

        preguntasHelper.forEach((pregunta, i) => {
            if(i + 1 < preguntasHelper.length) {
                preguntas += pregunta.id + '-' + pregunta.respuesta + ","
            }else {
                preguntas += pregunta.id + '-' + pregunta.respuesta
            }
        })
        
        const configGA = {
            triage_respuestas: preguntas,
        }
        
        mapGA(GA_KEYS.PREGUNTAS_SCREEN_ACTION, setupData, configGA)

        dispatchAsistenciaMedica({
            type: AsistenciaMedicaActionType.actualizarPreguntas,
            preguntas: preguntasHelper
        })

        dispatchAsistenciaMedica({
            type: AsistenciaMedicaActionType.actualizarObservaciones,
            observaciones
        })
    
        let preguntasRequest:any[] = preguntasHelper;

        getReglas(preguntasRequest, apiRequest)
            .then((reglas:Regla[]) => {
                if(!reglas || reglas.length <= 0) {
                    //no existen reglas
                    setShowContactanosModal(true);
                    setErrorDisponibilidad(false);
                    handleWhatsappMessage();
                    setWaitingButton(false);
                } else {
                    reglas.forEach((regla: Regla)=>{
                        if (regla.visible) {
                            validacionDeClasificacion(regla.reglaAlternativa)
                                .then((pantalla)=>{
                                    if (pantalla === PANTALLA_TELEFONO) {
                                        setShowContactanosModal(true);
                                        setWaitingButton(false);
                                    } else {
                                        let serviciosDisponibles = { disponibilidad: disponibilidadFinal, clasificacionesHistory: clasificaciones, coseguro: coseguroFinal, direccionPredefinida: direccionSocio };
                                        let serviciosDisponiblesArray = serviciosDisponibles.clasificacionesHistory.map((servicio)=>{
                                            return servicio.descripcion;
                                        });
                                        let serviciosPosiblesSinRestriccion = asistenciaMedica?.restriccionesPlan.map((servicio)=>{
                                            return servicio.descripcion;
                                        });
                                        //validacion entre restricciones y servicios disponibles 
                                        let ofrecerServicios = false;
                                        serviciosDisponiblesArray.forEach((servicio, index)=>{
                                            if(serviciosPosiblesSinRestriccion.includes(servicio)) {
                                                ofrecerServicios = true;
                                            }
                                        })
                                        if (ofrecerServicios) {
                                            try {
                                                let servicios = JSON.stringify(serviciosDisponibles);
                                                history.push(RESUMEN, servicios);
                                                setWaitingButton(false);                
                                            } catch (error) {
                                                console.error('Error al utilizar history push a resumen:', error);
                                                setError(true);
                                            }
                                        } else {
                                            //no existen servicios que se pueden ofrecer
                                            history.push(TELEFONOS_PAGE, { screen: 'triage' })
                                            setWaitingButton(false);
                                        }
                                    }
                                })
                        } else {
                            setShowContactanosModal(true);
                            setErrorDisponibilidad(false);
                            handleWhatsappMessage();
                            setWaitingButton(false);
                        }
                    })
                }
                })
            .catch(error => {
                setWaitingButton(false);
                console.error(`Ocurrio un error al realizar la peticion a reglas: ${error}`);
                setError(true)
            })

    }

    const salidaCarritoAbandonado = () => {
        if(carritoAbandonadoDisable) {
            setErrorDisponibilidad(false);
            setShowContactanosModal(true);
            setWaitingButton(false);
        } else {
            let mensajeCarritoAbandonado = whatsappCarritoAbandonado(asistenciaMedica, preguntasHelper);
            setErrorDisponibilidad(true);
            setMensaje(mensajeCarritoAbandonado);
            setShowContactanosModal(true);
            setWaitingButton(false);
            carritoAbandonado(asistenciaMedica, preguntasHelper, apiRequest, storageManager, false);
        }
    }

    if(error){
        return <ErrorPage setError={setError}/>
    } else {
    return (
        <>
            <PageWrapper
                socio={asistenciaMedica.usuario} 
                pageClassName="asistencia-preguntas"
                path={PREGUNTAS}>
                <IonRow>
                        <IonCol size='12'>
                            <Titulo
                                className='seleccionar-socio__title'
                                titulo={'Necesitamos que respondas algunas preguntas'}
                                id="titulo-asisntecia-preguntas"
                            />
                        </IonCol>
                    </IonRow>
                    <PreguntasList preguntas={preguntasHelper} handlerOptions={handlerOptions} />
                    <Separador />
                    <PreguntaObservacion handleObservacion={handleObservacion} observaciones={observaciones}/>
                    <IonRow className='preguntas__row_button_continuar'>
                        <IonCol>
                            <Button
                                id='preguntas-continuar'
                                className='preguntas__button_preguntas'
                                primary
                                disable={handleDisableButton()}
                                label='Continuar'
                                waiting={waitingButton}
                                onClick={botonContinuarHandler}
                                />
                        </IonCol>
                    </IonRow>
            </PageWrapper>
            {
                    errorDisponibilidad ?
                        <Modal
                            className='modal_web'
                            hideCloseBtn={false}
                            actionClose={ ()=> {
                                history.push(HOME)
                            }}
                            //@ts-ignore
                            component={<Contactanos menorSeisMeses={false} sintomas texto={mensaje} titulo={TITULO_INFORMACION_IMPORTANTE}/> }
                            setShowModal={setShowContactanosModal}
                            showModal={showContactanosModal}
                            id="contactanos"
                        />
                    :
                        <Modal
                            className='modal_web'
                            hideCloseBtn={false}
                            actionClose={ ()=> {
                                history.push(HOME)
                            }}
                            //@ts-ignore
                            component={<Contactanos menorSeisMeses = {true} titulo={TITULO_INFORMACION_IMPORTANTE}/>}
                            setShowModal={setShowContactanosModal}
                            showModal={showContactanosModal}
                            id="contactanos"
                        />
            }
        </>
    )
    }

}

export default Preguntas;