import { AxiosResponse } from "axios";
import { Whatsapp } from "../interfaces/whatsapp.interface";
import { Socio } from "../models/socioPrincipal.model";
import { BACKEND_ENDPOINT, CARRITO_ABANDONADO, MENSAJERIA } from "../utils/constants";

export const sendWhatsAppMessage = (socio: Socio, apiRequest: any) => {
    return new Promise<AxiosResponse>((resolve, reject) => {
        const url = BACKEND_ENDPOINT + MENSAJERIA;
        const method = 'POST';

        const data = {
            telefono: socio.telefono,
            nombre: socio.nombre,
            apellido: socio.apellidos,
            ic: socio.ic
        }
        
        const options = {
            url,
            method,
            data
        };
        
        apiRequest(options)
            .then((response : AxiosResponse) => resolve(response))
            .catch((error : any) => reject(error));
    });
}

export const sendCarritoAbandonadoMessage = (mensaje: Whatsapp, apiRequest) => {
    return new Promise<AxiosResponse>((resolve, reject) => {
        const url = BACKEND_ENDPOINT + CARRITO_ABANDONADO;
        const method = 'POST';

        const data: Whatsapp = { 
            ic: mensaje.ic,
            antecedentes: mensaje.antecedentes,
            credencial: mensaje.credencial,
            datosContacto: mensaje.datosContacto,
            domicilio: mensaje.domicilio,
            nombre: mensaje.nombre,
            preguntas: mensaje.preguntas,
            sintomas: mensaje.sintomas,
            esMenor: mensaje.esMenor
        }
        
        const options = {
            url,
            method,
            data
        };
        
        apiRequest(options)
            .then((response : AxiosResponse) => resolve(response))
            .catch((error : any) => reject(error));
    });
}