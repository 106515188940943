import { AxiosResponse } from 'axios';
import { Canal } from '../interfaces/canal.interface';
import { ClasificacionModel } from '../interfaces/clasificacion.interface';
import { Coseguro } from '../interfaces/coseguro.interface';
import { DisponibilidadServicio } from '../interfaces/disponibilidadServicio.interface';
import { Factor } from '../interfaces/factor.interface';
import { Pregunta } from '../interfaces/pregunta.interface';
import { Regla } from '../interfaces/regla/regla.interface';
import { Sintoma } from '../interfaces/sintoma.interface';
import { ANTECEDENTES, BACKEND_ENDPOINT, CANAL, CLASIFICACION, COSEGURO, DISPONIBILIDAD, REGLA, TRIAGE } from '../utils/constants';

export const getFactores = (apiRequest: any) => {
    return new Promise<Factor[]>((resolve, reject) => {
        const url = BACKEND_ENDPOINT + ANTECEDENTES;
        const method = 'GET';
        const options = {
            url,
            method 
        }
        apiRequest(options)
            .then((response: AxiosResponse) => resolve(response.data))
            .catch((error : any) => reject(error));
        });
}

export const getSintomas = (apiRequest: any) => {
    return new Promise<Sintoma[]>((resolve, reject) => {
        const url = BACKEND_ENDPOINT + TRIAGE;
        const method = 'GET';
        const options = {
            url,
            method 
        }
        apiRequest(options)
            .then((response: AxiosResponse) => resolve(response.data))
            .catch((error : any) => reject(error));
    });
}

export const getListaCanales = (apiRequest: any) => {
    return new Promise<Canal[]>((resolve, reject) => {
        const url = BACKEND_ENDPOINT + CANAL;
        const method = 'GET';
        const options = {
            url,
            method 
        }
        apiRequest(options)
            .then((response: AxiosResponse) => resolve(response.data))
            .catch((error : any) => reject(error));
    });
}

export const getClasificacion = (apiRequest: any) => {
    return new Promise<ClasificacionModel[]>((resolve, reject) => {
        const url = BACKEND_ENDPOINT + CLASIFICACION;
        const method = 'GET';
        const options = {
            url,
            method 
        }
        apiRequest(options)
            .then((response: AxiosResponse) => resolve(response.data))
            .catch((error : any) => reject(error));
    });
}

export const getCoseguro = (numeroSocio, servicio, clasificacion, provincia, localidad, apiRequest: any) => {
    return new Promise<Coseguro>((resolve, reject) => {
        const url = BACKEND_ENDPOINT + COSEGURO
        + 'numero-socio/' + numeroSocio
        + '/servicio/'+ servicio 
        + '/clasificacion/' + clasificacion 
        + '/provincia/'+ provincia 
        + '/localidad/' + localidad;
        const method = 'GET';
        const options = {
            url,
            method 
        }
        apiRequest(options)
            .then((response: AxiosResponse) => resolve(response.data))
            .catch((error : any) => reject(error));
    });
}

export const getDisponibilidad = (provincia, localidad, barrioSocio, servicio, clasificacion, esMenor, apiRequest: any) => {
    return new Promise<DisponibilidadServicio>((resolve, reject) => {
        const url = BACKEND_ENDPOINT + DISPONIBILIDAD 
        + 'provincia/' + provincia 
        + '/localidad/' + localidad 
        + '/servicio/' + servicio 
        + '/clasificacion/' + clasificacion 
        + '/menor/' + esMenor;
        const method = 'GET';
        const params = {
            barrio : barrioSocio
        }

        const options = barrioSocio ? {
            url,
            method,
            params
        } : { url, method }

        apiRequest(options)
            .then((response: AxiosResponse) => resolve(response.data))
            .catch((error : any) => reject(error));
    });
}

export const getReglas = (preguntas: any[], apiRequest: any) => {
    return new Promise<Regla[]>((resolve, reject) => {
        const url = BACKEND_ENDPOINT + REGLA;
        let preguntasString = JSON.stringify(preguntas);
        const params = {
            preguntasString
        }
        const method = 'GET';
        const options = {
            url,
            method,
            params
        }
        apiRequest(options)
            .then((response: AxiosResponse) => resolve(response.data))
            .catch((error: any) => reject(error));
    });
}