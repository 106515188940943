interface AsistenciaMedicaEnvironment {
    BACKEND_ENDPOINT: string,
    NORMALIZADOR_ENDPOINT: string,
    ASISTENCIA_WEB_ENDPOINT: string,
    GOOGLE_MAPS_API_KEY: string,
    TELEFONO_WHATSAPP: string,
    TELEFONO_EMERGENCIAS: string,
    TELEFONO_URGENCIAS_UNO: string,
    TELEFONO_URGENCIAS_DOS: string,
    REFRESH_TOKEN: string,
    LOGIN_URI: string,
    AUTHORIZATION: string,
    THEME_ID: string,
    LOGOUT_URL: string
}

const useEnvironmentVariables = () : { ENV: AsistenciaMedicaEnvironment } => {

    const processEnv = { ...process.env };

    const ASISTENCIA_WEB_VERSION = ""

    const environmentObject = {
        DEV: {
            ASISTENCIA_WEB_VERSION,
            REACT_APP_ENVIRONMENT: 'dev',
            BACKEND_ENDPOINT: "https://gateway.apid-osde.com.ar/urgencias/os-asistenciamedicadeurgenciascliente/",
            NORMALIZADOR_ENDPOINT: "https://gateway.apit-osde.com.ar/os-normalizador/direcciones",
            GOOGLE_MAPS_API_KEY: "AIzaSyBVulZvv94oL3hFufHCyh3yPmXoZ88sYsQ",
            ASISTENCIA_WEB_ENDPOINT: "https://app-socios-frontend-jmmsnlcpta-uc.a.run.app",
            TELEFONO_WHATSAPP: "1138541243",
            TELEFONO_EMERGENCIAS: "08107772222",
            TELEFONO_URGENCIAS_UNO: "08109998899",
            TELEFONO_URGENCIAS_DOS: "08108887311",
            REFRESH_TOKEN: "https://gateway.apid-osde.com.ar/urgencias/os-asistenciamedicadeurgenciascliente/auth/refresh-token-oauth",
            LOGIN_URI: "https://idosde-test.ice.ibmcloud.com/oauth2/authorize?client_id=c5bd7722-b287-4de5-bf77-befa69e3095d&redirect_uri=https://app-socios-frontend-jmmsnlcpta-uc.a.run.app/tabs/inicio&response_type=code&scope=openid&response_mode=query",
            AUTHORIZATION: 'https://gateway.apid-osde.com.ar/urgencias/os-asistenciamedicadeurgenciascliente/auth/token',
            THEME_ID: "?themeId=c329a247-25f0-4029-93fe-1cb4cb42de64",
            LOGOUT_URL: "https://idosde-test.ice.ibmcloud.com/idaas/mtfim/sps/idaas/logout"
        },
        TEST: {
            ASISTENCIA_WEB_VERSION,
            REACT_APP_ENVIRONMENT: 'test',
            BACKEND_ENDPOINT: "https://gateway.apit-osde.com.ar/urgencias/os-asistenciamedicadeurgenciascliente/",
            NORMALIZADOR_ENDPOINT: "https://gateway.apit-osde.com.ar/os-normalizador/direcciones",
            ASISTENCIA_WEB_ENDPOINT: "https://app-socios-frontend-web-zrvedpo66q-uc.a.run.app",
            GOOGLE_MAPS_API_KEY: "AIzaSyD5p6LHWEGQ4h3Gp0o19SmX57iqeIJLWyA",
            TELEFONO_WHATSAPP: "1138541243",
            TELEFONO_EMERGENCIAS: "08107772222",
            TELEFONO_URGENCIAS_UNO: "08109998899",
            TELEFONO_URGENCIAS_DOS: "08108887311",
            REFRESH_TOKEN: "https://gateway.apit-osde.com.ar/urgencias/os-asistenciamedicadeurgenciascliente/auth/refresh-token-oauth",
            LOGIN_URI: "https://idosde-test.ice.ibmcloud.com/oauth2/authorize?client_id=b7320259-1f95-4899-8cdb-f0d628901fee&redirect_uri=https://app-socios-frontend-web-zrvedpo66q-uc.a.run.app/tabs/inicio&response_type=code&scope=openid&response_mode=query",
            AUTHORIZATION: "https://gateway.apit-osde.com.ar/urgencias/os-asistenciamedicadeurgenciascliente/auth/token",
            THEME_ID: "?themeId=b02dc991-7d77-42e3-a7b2-c3ef2426eebb",
            LOGOUT_URL: "https://idosde-test.ice.ibmcloud.com/idaas/mtfim/sps/idaas/logout"
            
        },
        PROD: {
            ASISTENCIA_WEB_VERSION,
            REACT_APP_ENVIRONMENT: 'prod',
            BACKEND_ENDPOINT: "https://gateway.api-osde.com.ar/urgencias/os-asistenciamedicadeurgenciascliente/",
            NORMALIZADOR_ENDPOINT: "https://gateway.api-osde.com.ar/os-normalizador/direcciones",
            ASISTENCIA_WEB_ENDPOINT: "https://asistenciamedica.osde.com.ar",
            GOOGLE_MAPS_API_KEY: "AIzaSyDt7c7g_42Ry1Im84-EoiJ_QhAIjmCo5pU",
            TELEFONO_WHATSAPP: "1148729100",
            TELEFONO_EMERGENCIAS: "08106661111",
            TELEFONO_URGENCIAS_UNO: "08108887788",
            TELEFONO_URGENCIAS_DOS: "08109996300",
            REFRESH_TOKEN: "https://gateway.api-osde.com.ar/urgencias/os-asistenciamedicadeurgenciascliente/auth/refresh-token-oauth",
            LOGIN_URI: "https://id.osde.com.ar/oauth2/authorize?client_id=2cf082b2-5eca-406c-a70c-c411b0406cf1&redirect_uri=https://asistenciamedica.osde.com.ar/tabs/inicio&response_type=code&scope=openid&response_mode=query",
            AUTHORIZATION: "https://gateway.api-osde.com.ar/urgencias/os-asistenciamedicadeurgenciascliente/auth/token",
            THEME_ID: "?themeId=c329a247-25f0-4029-93fe-1cb4cb42de64",
            LOGOUT_URL: "https://id.osde.com.ar/idaas/mtfim/sps/idaas/logout"
        }
    }
        
    const ENV: AsistenciaMedicaEnvironment = environmentObject[processEnv.REACT_APP_ENVIRONMENT] || environmentObject[processEnv.react_app_environment];
    
    return {
        ENV
    }

}

const Environment = useEnvironmentVariables();

export { Environment }