import { getGrupoFamiliarByContrato, getSocioSalusByIc } from "../api/salusService";
import { getSocioPrincipalByIc, saveSocioPrincipal, updateSocioPrincipal } from "../api/socioPrincipalService";
import { Contrato } from "../interfaces/contrato.interface";
import { SocioSalus } from "../interfaces/salus/sociosalus.interface";
import { Socio } from "../models/socioPrincipal.model";
import { SocioFamiliar } from "../interfaces/socioFamiliar.interface";
import jwt from "jwt-decode";
import { calcularEdad, formatearTelefono, getUltimoContrato, parentescoUniforme } from "../utils/utils";
import { CONCUBINA_O, CONYUGE, CONYUGE_ADICIONAL, TITULAR } from "../utils/constants";
import { GrupoFamiliarSalus } from "../interfaces/salus/grupofamiliarsalus.interface";
import { updateSocioFamiliar } from "../api/socioFamiliarService";

const obtenerOGuardarSocioPrincipal = (socioSalus: SocioSalus, apiRequest: any, user: any) => {
    return new Promise<Socio>((resolve, reject) => {
        getSocioPrincipalByIc(socioSalus.ic, apiRequest).then((response) => {
            if (response.status === 204) {
                // Completar socio para poder persistirlo
                completarSocio(socioSalus, user, apiRequest)
                    .then(socioCompletado => {
                        // Una vez completo lo intento guardar en la bdd
                        saveSocioPrincipal(socioCompletado, apiRequest)
                            .then(responseSaveSocio => {
                                // Si se guardo el socio, lo obtengo de la bdd para luego devolverlo con el resolve
                                getSocioPrincipalByIc(socioCompletado.ic!, apiRequest)
                                    .then(socioObtenido => resolve(socioObtenido.data))
                                    .catch(error => reject(error));
                            })
                            .catch((error) => reject(error));
                    })
                    .catch((error) => reject(error));
            } else {
                // En caso de que ya exista el socio en la bdd, se obtiene del servicio y se retorna en el
                let socioConCambios = verificarCambios(socioSalus, response.data, apiRequest);
                resolve(socioConCambios);
            }
        })
    })
}

const completarSocio = (socioSalus: SocioSalus, user: any, apiRequest: any) => {
    return new Promise<Socio>((resolve,rejected) =>{
        let socioACompletar = new Socio();
        socioACompletar.apellidos = socioSalus.apellidos;
        socioACompletar.nombre = socioSalus.nombre;
        socioACompletar.dni = socioSalus.documento;
        socioACompletar.fechaNacimiento = socioSalus.fechaNacimiento;
        socioACompletar.sexo = socioSalus.sexo;
        socioACompletar.ic = socioSalus.ic;
        socioSalus.contratos.forEach((contratoSalus, index) => {
            let contrato = <Contrato>{};
            contrato.estado = contratoSalus.estado;
            contrato.fechaFinContrato = contratoSalus.fechaFinContrato;
            contrato.fechaFinVigencia = contratoSalus.fechaFinVigencia;
            contrato.fechaInicioContrato = contratoSalus.fechaInicioContrato;
            contrato.fechaInicioVigencia = contratoSalus.fechaInicioVigencia;
            contrato.numeroSocio = contratoSalus.numeroSocio;
            contrato.parentesco = contratoSalus.parentesco;
            socioACompletar.contratos?.push(contrato);
        });
        socioACompletar.terms = false;
        let ultimoContrato = getUltimoContrato(socioSalus);
        let numSocio = ultimoContrato.numeroSocio.slice(0, 9);
        socioACompletar.nroContrato = ultimoContrato.numeroSocio;
        //guardamos en storage el contrato activo
        localStorage.setItem('contratoActivo', JSON.stringify(ultimoContrato));
        
        socioACompletar = getDataFromIdToken(socioACompletar, user);
        let parentesco = parentescoUniforme(ultimoContrato.parentesco);
        switch (parentesco) {
            case TITULAR:
                getGrupoFamiliarByContrato(numSocio, apiRequest)
                    .then(response => {
                        socioACompletar.plan = response[0].plancontrato
                        response.forEach(familiarSalus => {
                            if (socioACompletar.ic !== familiarSalus.ic) {
                                socioACompletar.familiares?.push(instanciarSocioFamiliar(familiarSalus));
                            }
                        });
                        resolve(socioACompletar);
                    })
                    .catch((error) => {
                        console.error(`Error al intentar obtener grupo familiar de Salus: ${JSON.stringify(error)}`);
                        rejected(error);
                    });
                break;
            case CONYUGE:
            case CONYUGE_ADICIONAL:
            case CONCUBINA_O:
                getGrupoFamiliarByContrato(numSocio, apiRequest)
                    .then( response => {
                        socioACompletar.plan = response[0].plancontrato
                        response.forEach(familiarSalus => {
                            let esMenor = calcularEdad(familiarSalus.fechaNacimiento) < 18;
                            if (socioACompletar.ic !== familiarSalus.ic && esMenor) {
                                socioACompletar.familiares!.push(instanciarSocioFamiliar(familiarSalus));
                            }
                        });
                        resolve(socioACompletar);
                    })
                    .catch((error) => {
                        console.error(`Error al intentar obtener grupo familiar de Salus: ${JSON.stringify(error)}`);
                        rejected(error);
                    })
                break;
            default: 
                getGrupoFamiliarByContrato(numSocio, apiRequest)
                .then((response)=>{
                    socioACompletar.plan = response[0].plancontrato;
                    resolve(socioACompletar);
                })
                .catch((error)=>{
                    console.error(`Error al intentar obtener grupo familiar de Salus: ${JSON.stringify(error)}`);
                    rejected(error);
                })
                break;
            }
    } )
}

/** Metodo que se debe llamar para obtener al socio del backend de asistencia */
export const obtenerDatosSocio = (interlocutorComercial: number, apiRequest: any, user: any) => {
    return new Promise((resolve, reject) => {
        validarContraSalus(interlocutorComercial, apiRequest, user)
            .then((socio) => resolve(socio))
            .catch((error) => reject(error));
    })
}

// TODO: Eliminar efectos secundarios
const getDataFromIdToken = (socio: Socio, user: any) => {
    let responseDataSocio: any = jwt(user.idToken);
    socio.email = responseDataSocio.email;
    socio.telefono = formatearTelefono(responseDataSocio.mobile_number);
    return socio;
}

const validarContraSalus = (interlocutorComercial: number, apiRequest : any, user :any) => {
    return new Promise<Socio>((resolve, reject) => {
        getSocioSalusByIc(interlocutorComercial, apiRequest)
            .then((response) => {
                if (response.status === 200) {
                    const esMayor = calcularEdad(response.data.fechaNacimiento) >= 13;
                    if (esMayor) {
                        // Falta determinar que se hace en caso de que no es mayor el socio
                        // Si es mayor se obtiene del backend de asistencia o en caso de no exisitr, se persiste
                        obtenerOGuardarSocioPrincipal(response.data, apiRequest, user)
                            .then(socioObtenido => resolve(socioObtenido))
                            .catch((error) => {
                                console.error(error);
                                reject(error);
                            });
                    }
                }
            })
            .catch((error) => {
                console.error(error)
                switch (error.response.status) {
                    case 404:
                        console.info(`No se encontro el socio en Salus: ${error.response.data}`);
                        break;
                    case 500:
                        console.error(`El cliente Salus devolvio un error interno: ${error.response.data}`);
                        break;
                    default:
                        console.error(`Ocurrio un error al invocar el servicio para obtener un socio de Salus: ${error.response.data}`);
                        break;
                }
                reject(error);
            });
    })
}

const instanciarSocioFamiliar = (familiarSalus: GrupoFamiliarSalus) => {
    let familiar = <SocioFamiliar>{};
    familiar.apellidos = familiarSalus.apellidos;
    familiar.nombre = familiarSalus.nombre;
    familiar.fechaNacimiento = familiarSalus.fechaNacimiento;
    familiar.ic = familiarSalus.ic;
    familiar.dni = familiarSalus.documento;
    familiar.nroContrato = familiarSalus.numeroSocio;
    familiar.sexo = familiarSalus.sexo;
    familiar.activo = true;
    familiar.plan = familiarSalus.plancontrato;
    return familiar;
}

const verificarCambios = (socioSalus: SocioSalus, socio: Socio, apiRequest: any) : Socio => {
    socioSalus.contratos.forEach((contratoSalus, index) => {
        let contrato = {} as Contrato;
        contrato.estado = contratoSalus.estado;
        contrato.fechaFinContrato = contratoSalus.fechaFinContrato;
        contrato.fechaFinVigencia = contratoSalus.fechaFinVigencia;
        contrato.fechaInicioContrato = contratoSalus.fechaInicioContrato;
        contrato.fechaInicioVigencia = contratoSalus.fechaInicioVigencia;
        contrato.numeroSocio = contratoSalus.numeroSocio;
        contrato.parentesco = contratoSalus.parentesco;
        let existeContrato = socio.contratos.find((contratoDB) => {
            return contratoDB.numeroSocio == contrato.numeroSocio;
        })
        if (!existeContrato) {
            socio.contratos.push(contrato);
        }
    });
    let contrato = getUltimoContrato(socioSalus);
    localStorage.setItem('contratoActivo', JSON.stringify(contrato));
    socio.nroContrato = contrato.numeroSocio;
    let esSocioNuevo: boolean;
    let parentesco = parentescoUniforme(contrato.parentesco);
    switch (parentesco) {
        case TITULAR:
            getGrupoFamiliarByContrato(contrato.numeroSocio.slice(0, 9), apiRequest)
                .then((response) => {
                    socio.plan = response[0].plancontrato
                    response.forEach((familiarSalus) => {
                        if (familiarSalus.ic !== socio.ic) {
                            esSocioNuevo = true;
                            socio.familiares.forEach((familiar) => {
                                if (familiarSalus.ic === familiar.ic) {
                                    esSocioNuevo = false;
                                    familiar = verificarCambiosEnSocioFamiliar(
                                        familiarSalus,
                                        familiar
                                    );
                                }
                            });
                            if (esSocioNuevo) {
                                agregarSocioFamiliar(familiarSalus, socio);
                            }
                        }  else {
                                //asigno al socio principal los valores nuevos actualizados
                                socio.apellidos = familiarSalus.apellidos;
                                socio.nombre = familiarSalus.nombre;
                                socio.fechaNacimiento = familiarSalus.fechaNacimiento;
                                socio.ic = familiarSalus.ic;
                                socio.dni = familiarSalus.documento;
                                socio.nroContrato = familiarSalus.numeroSocio;
                                socio.sexo = familiarSalus.sexo;
                                socio.plan = familiarSalus.plancontrato;
                        }
                    });
                    habilitarSocioFamiliar(response, socio, apiRequest);
                })
                .catch((error) => console.error(error));
            break;
        case CONYUGE:
        case CONYUGE_ADICIONAL:
        case CONCUBINA_O:
            getGrupoFamiliarByContrato(contrato.numeroSocio.slice(0, 9), apiRequest)
                .then(
                    (response) => {
                        let esMenor: boolean;
                        socio.plan = response[0].plancontrato
                        response.forEach((familiarSalus) => {
                            if (familiarSalus.ic !== socio.ic) {
                                esSocioNuevo = true;
                                esMenor =
                                    calcularEdad(familiarSalus.fechaNacimiento) < 18
                                        ? true
                                        : false;
                                socio.familiares.forEach((familiar) => {
                                    if (familiarSalus.ic === familiar.ic) {
                                        esSocioNuevo = false;
                                        familiar = verificarCambiosEnSocioFamiliar(
                                            familiarSalus,
                                            familiar
                                        );
                                    }
                                });
                                if (esSocioNuevo && esMenor) {
                                    agregarSocioFamiliar(familiarSalus, socio);
                                }
                            }
                        });
                        habilitarSocioFamiliar(response, socio, apiRequest);
                    })
                .catch((error) => console.error(error));
            break;
        default:
            getGrupoFamiliarByContrato(contrato.numeroSocio.slice(0, 9), apiRequest)
                .then((response)=>{
                    socio.plan = response[0].plancontrato
                })
                .catch((error)=>{
                    console.error(error)
                })
            break;
    }
    return socio;
}

const verificarCambiosEnSocioFamiliar = (socioFamliarSalus, familiar) => {
    familiar.apellidos = socioFamliarSalus.apellidos;
    familiar.nombre = socioFamliarSalus.nombre;
    familiar.fechaNacimiento = socioFamliarSalus.fechaNacimiento;
    familiar.ic = socioFamliarSalus.ic;
    familiar.dni = socioFamliarSalus.documento;
    familiar.nroContrato = socioFamliarSalus.numeroSocio;
    familiar.sexo = socioFamliarSalus.sexo;
    familiar.plan = socioFamliarSalus.plancontrato;
    familiar.activo = true;
    return familiar;
}

const agregarSocioFamiliar = (familiarSalus: GrupoFamiliarSalus, socio: Socio) => {
    let familiar = {} as SocioFamiliar;
    familiar.apellidos = familiarSalus.apellidos;
    familiar.nombre = familiarSalus.nombre;
    familiar.fechaNacimiento = familiarSalus.fechaNacimiento;
    familiar.ic = familiarSalus.ic;
    familiar.dni = familiarSalus.documento;
    familiar.nroContrato = familiarSalus.numeroSocio;
    familiar.sexo = familiarSalus.sexo;
    familiar.plan = familiarSalus.plancontrato;
    familiar.activo = true;
    socio.familiares.push(familiar);
}

const habilitarSocioFamiliar = (response, socio, apiRequest) => {
    updateSocioPrincipal(socio, apiRequest)
        .then(() => { 
            let esSocioDadoDeBaja, esSocioReactivado: boolean;
            socio.familiares.forEach((familiar) => {
                esSocioDadoDeBaja = true;
                esSocioReactivado = false;
                //IGI-112283 Se agrega esta validacion para corregir socios que no tienen grupo familiar, pero figuran duplicados.
                if (familiar.ic !== socio.ic) {
                    response.forEach((familiarSalus) => {
                        if (familiar.ic === familiarSalus.ic) {
                            esSocioDadoDeBaja = false;
                            if (!familiar.activo) {
                                esSocioReactivado = true;
                            }
                        }
                    });
                }
                if (esSocioDadoDeBaja || esSocioReactivado) {
                    if (esSocioDadoDeBaja) {
                        familiar.activo = false;
                    } else {
                        familiar.activo = true;
                    }
                    updateSocioFamiliar(familiar, apiRequest)
                        .then(() => {
                            socio.familiares.forEach((element) => {
                                if (element.ic === familiar.ic) {
                                    element.activo = familiar.activo;
                                }
                            })
                        })
                        .catch((error) => console.error(error));
                }
            });
        }
    )
    .catch((error) => console.error(error));
}