import { AxiosResponse } from "axios";
import { CasoUrgencias, InfoCaso } from "../models/caso.model"
import { BACKEND_ENDPOINT, CASO_BY_NRO_CASO_SERVICE_URL, CASO_SERVICE, CASO_SERVICE_URL, SALUS_SERVICE } from "../utils/constants";

/**
 * Invoca el servicio de creacion de casos en el Backend
 */
export const sendCasoSalus = (caso: CasoUrgencias, apiRequest: any) => {
    return new Promise<AxiosResponse>((resolve, reject) => {
        const url = BACKEND_ENDPOINT + SALUS_SERVICE;
        const method = 'POST';
        const data = caso;
        const options = {
            url,
            method,
            data
        }
        apiRequest(options)
            .then((response: AxiosResponse) => resolve(response))
            .catch((error: any) => reject(error));
    });
}

/**
 * Guarda el caso en la DB
 */
export const saveCaso = (caso: InfoCaso, apiRequest: any) => {
    return new Promise<AxiosResponse>((resolve, reject) => {
        const headers = {
            "Content-Type" : "application/json"
        }
        const url = BACKEND_ENDPOINT + CASO_SERVICE_URL;
        const method = 'POST';
        const data = caso;
        const options = {
            url,
            method,
            data,
            headers
        }
        apiRequest(options)
            .then((response: AxiosResponse) => resolve(response))
            .catch((error: any) => reject(error));
    });
}

/**
 * Obtiene el caso en base al numero de caso
 */
export const obtenerCasoByNroCaso = (nroCaso: any, apiRequest:any) => {
    return new Promise<InfoCaso>((resolve, reject) => {
        const url = BACKEND_ENDPOINT + CASO_SERVICE + CASO_BY_NRO_CASO_SERVICE_URL;

        const params = {
            'nroCaso': nroCaso
        }
        const headers = {
            'Content-Type': 'application/json',
        }
        const method = 'GET';
        const options = {
            url,
            method,
            params,
            headers
        }
        apiRequest(options)
            .then((response: AxiosResponse) => resolve(response.data))
            .catch((error: any) => reject(error));
    });
}