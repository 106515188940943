import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Antecedentes from '../pages/Antecedentes/Antecedentes';
import { AsistenciaMedica } from '../pages/AsistenciaMedica/AsistenciaMedica';
import AsistenciaSolicitada from '../pages/AsistenciaSolicitada/AsistenciaSolicitada';
import Cancelar from '../pages/Cancelar/Cancelar';
import VerificarDatosContacto from '../pages/DatosContacto/VerificarDatosContacto';
import PedidoServicio from '../pages/PedidoServicio/PedidoServicio';
import { HOME, SELECCIONAR_SOCIO, FACTORES_RIESGO, VERIFICAR_DATOS_CONTACTO, VERIFICAR_DATOS_UBICACION, SINTOMAS, PREGUNTAS, CANCELAR_SOLICITUD, RESUMEN, PEDIDO_SERVICIO, ASISTENCIA_CREADA, ADS_PRINCIPAL, SERVICIO_EN_CURSO, HISTORIAL_ATENCIONES, DETALLE_CASO, TELEFONOS_PAGE, SEGUIMIENTO_VACIO, FEEDBACK_SERVICIO_EN_CURSO} from '../utils/constants';
import SeleccionarDomicilio from '../pages/SeleccionarDomicilio/SeleccionarDomicilio';
import Sintomas from '../pages/Sintomas/Sintomas';
import Preguntas from '../pages/Preguntas/Preguntas';
import Resumen from '../pages/Resumen/Resumen';
import SeleccionarSocio from '../pages/SeleccionarSocio/SeleccionarSocio';
import ServicioEnCurso from '../pages/ServicioEnCurso/ServicioEnCurso';
import HistorialAtenciones from '../pages/HistorialAtenciones/HistorialAtenciones';
import DetalleCaso from '../pages/DetalleCaso/DetalleCaso';
import TelefonosPage from '../pages/TelefonosPage/TelefonosPage';
import SeguimientoVacio from '../pages/SeguimientoVacio/SeguimientoVacio';
import FeedbackServicioEnCurso from '../pages/FeedbackServicioEnCurso/FeedbackServicioEnCurso';

export const AsistenciaMedicaRouter = () => {
	return (
		<>
			<Switch>
				<Route exact path={HOME} component={ AsistenciaMedica } />
				<Route exact path={SELECCIONAR_SOCIO} component={ SeleccionarSocio } />
				<Route exact path={TELEFONOS_PAGE} component={TelefonosPage} />
                <Route exact path={FACTORES_RIESGO} component={ Antecedentes } />
                <Route exact path={VERIFICAR_DATOS_CONTACTO} component={ VerificarDatosContacto }/>
                <Route exact path={VERIFICAR_DATOS_UBICACION} component={SeleccionarDomicilio}/>
                <Route exact path={SINTOMAS} component={ Sintomas } />
                <Route exact path={PREGUNTAS} component={ Preguntas }/>
				<Route exact path={RESUMEN} component={ Resumen } />
				<Route exact path={PEDIDO_SERVICIO} component={PedidoServicio}/>
				<Route exact path={FEEDBACK_SERVICIO_EN_CURSO} component={ FeedbackServicioEnCurso } />
				<Route exact path={ASISTENCIA_CREADA} component={AsistenciaSolicitada}/>
				<Route exact path={CANCELAR_SOLICITUD} component={ Cancelar } />
				<Route exact path={SERVICIO_EN_CURSO} component={ ServicioEnCurso } />
				<Route exact path={HISTORIAL_ATENCIONES} component={ HistorialAtenciones } />
				<Route exact path={DETALLE_CASO} component={ DetalleCaso } />
				<Route exact path={SEGUIMIENTO_VACIO} component={ SeguimientoVacio } />
				<Route exact path="/" render={() => <Redirect to={ADS_PRINCIPAL} />} />
			</Switch>
		</>
	)
}
