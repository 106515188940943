import { IonCol, IonContent, IonFooter, IonGrid, IonPage, IonRow } from '@ionic/react';
import { Titulo, LineaEstados, CardSolicitarAsistencia, Separador, Modal, Header } from 'app-principal-componentes-visuales';
import React, { useContext, useEffect, useState } from 'react';
import { obtenerCasoByNroCaso } from '../../api/casoService';
import DatosAtencionAccordion from '../../components/Accordions/DatosAtencionAccordion/DatosAtencionAccordion';
import DatosContactoAccordion from '../../components/Accordions/DatosContactoAccordion/DatosContactoAccordion';
import DatosSocioAccordion from '../../components/Accordions/DatosSocioAccordion/DatosSocioAccordion';
import DatosUbicacionAccordion from '../../components/Accordions/DatosUbicacionAccordion/DatosUbicacionAccordion';
import DetalleAtencion from '../../components/Modals/DetalleAtencion/DetalleAtencion';
import DetalleCasoSkeleton from '../../components/Skeletons/DetalleCasoSkeleton/DetalleCasoSkeleton';
import { AsistenciaMedicaContext } from '../../contexts/AsistenciaMedicaContext';
import { DETALLE_CASO, ESTADOS_ASIGNADOS, ESTADOS_CONFIRMADOS, HOME } from '../../utils/constants';
import './DetalleCaso.scss';
import SessionHeader from '../../components/Headers/SessionHeader/SessionHeader';
import Footer from '../../components/Footer/Footer';
import DatosPrestadorAccordion from '../../components/Accordions/DatosPrestadorAccordion/DatosPrestadorAccordion';
import PageWrapper from '../../components/PageWrapper/PageWrapper';

const DetalleCaso = ({ history }) => {

    const { useRequest, asistenciaMedica } = useContext(AsistenciaMedicaContext);
    const { apiRequest } = useRequest();
    const { casoEnCursoSeleccionado, from } = history.location.state;
    const [casoBD, setCasoBD] = useState(null);
    const [pending, setPending] = useState(true);
    const [estadoAtencion, setEstadoAtencion] = useState(1)
    const [showVerDetalleModal, setShowVerDetalleModal] = useState(false);

    const inicializarEstados = [
        {
            id: 1,
            texto: 'Asistencia creada'
        },
        {
            id: 2,
            texto: 'En espera de atención'
        },
        {
            id: 3,
            texto: 'Asistencia finalizada'
        }
    ]

    useEffect(() => {
        const obtenerCaso = async () => {
            // TODO cambiar los 1, 2, 3, por constantes
            if (casoEnCursoSeleccionado.estadoSalus === "" || ESTADOS_ASIGNADOS.includes(casoEnCursoSeleccionado.estadoSalus)) {
                setEstadoAtencion(1)
            } else if (ESTADOS_CONFIRMADOS.includes(casoEnCursoSeleccionado.estadoSalus)) {
                setEstadoAtencion(2)
            } else if (casoEnCursoSeleccionado.estadoSalus === "CE") {
                setEstadoAtencion(3)
            }
            try {
                const caso = await obtenerCasoByNroCaso(casoEnCursoSeleccionado.nroCaso, apiRequest)
                setCasoBD(caso)
                setPending(false)
            }
            catch(error) {
                console.error(error);
                setCasoBD("");
                setPending(false)
            }
        }

        obtenerCaso()
    }, [])

    const handleModalCoseguro = () => {
        setShowVerDetalleModal(true)
    }

    const handleBack = () => {
        if (from === HOME) {
            history.push(HOME);
        } else {
            history.goBack();
        }
    }

    return (
        <>
            {
                pending ?
                    <PageWrapper tituloHeader={'Detalle de asistencia'} handlerHandleBack={handleBack} pageClassName='' path={DETALLE_CASO} socio={asistenciaMedica.usuario} notCancel>
                        <DetalleCasoSkeleton />
                    </PageWrapper>
                    :
                    <>
                    <PageWrapper tituloHeader={'Detalle de asistencia'} handlerHandleBack={handleBack} pageClassName='' path={DETALLE_CASO} socio={asistenciaMedica.usuario} notCancel>
                            <IonRow>
                                <IonCol size='12'>
                                    <LineaEstados
                                        id='asistencia-medica-linea-estados'
                                        textosEstados={inicializarEstados}
                                        valorActual={estadoAtencion}
                                    />
                                </IonCol>
                            </IonRow>
                            <IonRow>
                            <IonCol size='12'>
                                {
                                    casoEnCursoSeleccionado.estado === "F" ?
                                    <></>
                                    // Se comenta momentaneamente por reglas de negocio
                                    // <CardSolicitarAsistencia
                                    //     descripcion='Fuiste atendido correctamente'
                                    //     id="card-solicitar-asisitencia-atendido"
                                    // />
                                    :
                                    casoEnCursoSeleccionado.excepcionCoseguro === '1' ?
                                        casoEnCursoSeleccionado?.codigoSeguridad ?
                                            <CardSolicitarAsistencia
                                                descripcion="Tené en cuenta para tu consulta"
                                                id="asistencia-medica-card-coseguro"
                                                masInfo
                                                onClick={handleModalCoseguro}
                                                codSeg={`Código de seguridad: ${casoEnCursoSeleccionado?.codigoSeguridad}`}
                                                profesional={`Profesional: ${casoEnCursoSeleccionado?.prestador}`}
                                            />
                                        :
                                        casoEnCursoSeleccionado?.prestador &&
                                            <CardSolicitarAsistencia
                                                descripcion="Tené en cuenta para tu consulta"
                                                id="asistencia-medica-card-coseguro"
                                                masInfo={false}
                                                profesional={`Profesional: ${casoEnCursoSeleccionado?.prestador}`} 
                                            />
                                    :
                                        <>
                                            {   
                                                casoEnCursoSeleccionado.coseguro > 0 ?
                                                    casoEnCursoSeleccionado?.codigoSeguridad ?
                                                        <CardSolicitarAsistencia
                                                            coseguro={`Coseguro: $${casoEnCursoSeleccionado.coseguro}`}
                                                            descripcion="Tené en cuenta para tu consulta"
                                                            id="asistencia-medica-card-coseguro"
                                                            masInfo
                                                            onClick={handleModalCoseguro}
                                                            codSeg={`Código de seguridad: ${casoEnCursoSeleccionado?.codigoSeguridad}`}
                                                            profesional={`Profesional: ${casoEnCursoSeleccionado?.prestador}`}
                                                        />
                                                    :
                                                        <CardSolicitarAsistencia
                                                            coseguro={`Coseguro: $${casoEnCursoSeleccionado.coseguro}`}
                                                            descripcion="Tené en cuenta para tu consulta"
                                                            id="asistencia-medica-card-coseguro"
                                                            masInfo
                                                            onClick={handleModalCoseguro}
                                                            profesional={casoEnCursoSeleccionado?.prestador ? `Profesional: ${casoEnCursoSeleccionado?.prestador}` : null}
                                                        />
                                                :

                                                !casoEnCursoSeleccionado.codigoSeguridad && casoEnCursoSeleccionado.prestador &&
                                                    <CardSolicitarAsistencia
                                                        descripcion="Tené en cuenta para tu consulta"
                                                        id="asistencia-medica-card-coseguro"
                                                        masInfo={false}
                                                        onClick={handleModalCoseguro}
                                                        profesional={`Profesional: ${casoEnCursoSeleccionado?.prestador}`}
                                                    />
                                            }
                                        </>
                                }
                            </IonCol>
                            </IonRow>
                            <IonRow className='datos-atencion__row'>
                                <IonCol size='12'>
                                    {
                                        casoEnCursoSeleccionado &&
                                        <DatosAtencionAccordion
                                            casoEnCursoSeleccionado={casoEnCursoSeleccionado}
                                            casoBD={casoBD}
                                        />
                                    }
                                </IonCol>
                            </IonRow>
                            {
                                casoEnCursoSeleccionado &&
                                <>
                                    <Separador />
                                    <IonRow>
                                        <IonCol size="12">
                                            <DatosSocioAccordion
                                                casoEnCursoSeleccionado={casoEnCursoSeleccionado}
                                                casoBD={casoBD}
                                            />
                                        </IonCol>
                                    </IonRow>
                                </>
                        }
                        {
                            casoBD && 
                            <>
                            <Separador />
                            <IonRow>
                                <IonCol size='12'>
                                    <DatosContactoAccordion
                                        casoBD={casoBD}
                                    />
                                </IonCol>
                            </IonRow>
                        </>
                        }
                        {
                            casoBD &&
                            <>
                            <Separador />
                            <IonRow className='datos-ubicacion__row'>
                                <IonCol size='12'>    
                                    <DatosUbicacionAccordion
                                        casoBD={casoBD}
                                    />
                                </IonCol>
                            </IonRow> 
                            </>
                        }
                        {
                            casoEnCursoSeleccionado.estado === "F" &&
                            <>
                            <Separador />
                            <IonRow className='datos-ubicacion__row'>
                                <IonCol size='12'>    
                                    <DatosPrestadorAccordion
                                        casoEnCursoSeleccionado={casoEnCursoSeleccionado}
                                    />
                                </IonCol>
                            </IonRow> 
                            </>
                        }
                    </PageWrapper>
                    <Modal
                        className='modal_web'
                        hideCloseBtn={false}
                        setShowModal={setShowVerDetalleModal}
                        showModal={showVerDetalleModal}
                        id="detalle-atencion-modal"
                        //@ts-ignore
                        component={
                            <DetalleAtencion
                                excepcionCoseguro={casoEnCursoSeleccionado.excepcionCoseguro}
                                coseguro={casoEnCursoSeleccionado.coseguro}
                                visitaMedicaDomicilio={casoEnCursoSeleccionado?.codigoSubTipoServicio === 'CV' ? true : false}
                                codigoSeguridad={casoEnCursoSeleccionado?.codigoSeguridad ? true : false}
                            />}            
                    />
            </>
        }
        </>
    )
}

export default DetalleCaso
