import { IonCol, IonContent, IonFooter, IonGrid, IonPage, IonRow } from '@ionic/react';
import { Checkbox, Button, Titulo, Separador } from 'app-principal-componentes-visuales';
import React, { useContext, useEffect, useState } from 'react'
import { updateSocioFamiliar } from '../../api/socioFamiliarService';
import { updateSocioPrincipal } from '../../api/socioPrincipalService';
import { AsistenciaMedicaContext } from '../../contexts/AsistenciaMedicaContext';
import { ANTECEDENTES, FACTOR_CLAVE_ID, VERIFICAR_DATOS_CONTACTO } from '../../utils/constants';
import { capitalizeString } from '../../utils/utils';
import ErrorPage from '../ErrorPage/ErrorPage';
import './Antecedentes.scss'
import { AsistenciaMedicaActionType } from '../../interfaces/global-state/asistencia.medica.action.type.enum';
import AntecedentesSkeleton from '../../components/Skeletons/AntecedentesSkeleton/AntecedentesSkeleton';
import { getFactores } from '../../api/asistenciaMedicaService';
import { GA_KEYS, mapGA } from '../../helpers/GTM';
import SessionHeader from '../../components/Headers/SessionHeader/SessionHeader';
import Footer from '../../components/Footer/Footer';
import PageWrapper from '../../components/PageWrapper/PageWrapper';

const Antecedentes = ({history}) => {

    const { asistenciaMedica, dispatchAsistenciaMedica, useRequest, storageManager, setupData } = useContext(AsistenciaMedicaContext);
    const socioSeleccionado = asistenciaMedica.socioSeleccionado;
    const [factoresFiltrados, setFactoresFiltrados] = useState([]);
    const [factoresSeleccionados, setFactoresSeleccionados] = useState([])
    const [error, setError] = useState(false)
    const [factorClaveSeleccionado, setFactorClaveSeleccionado] = useState(false);
    const [factorSkeleton, setFactorSkeleton] = useState(true);
    const { apiRequest } = useRequest();
    const [stateBoton, setStateBoton] = useState(true);
    const socioPrincipal = asistenciaMedica.usuario;

    /**
     * Realiza la peticion al servicio de factores.
     * En caso que dicha peticion falle, se muestran se recuperan los factores del storage
     */
    useEffect(() => {
        getFactores(apiRequest).then( factores => {
            storageManager.setDataStorage("factores", JSON.stringify(factores));
            inicializarFactores(factores);
        }).catch( error => {
            console.error("ASIS-MED - Error al inicializar listado de Factores", error);
            storageManager.getDataStorage("factores").then( factoresFromStorage => {
                if ( factoresFromStorage !== null ) {
                    inicializarFactores(JSON.parse(factoresFromStorage));
                }
            });
        });
    }, [])

    /**
     * Encargado de filtrar los factores visibles y agrega el campo checked a los radio buttons del listado de factores con los factores del socioSeleccionado
     */
    const inicializarFactores = (factores) => {
        const factoresVisibles = factores.filter((antecedente) => antecedente.visible === true)
        let index = factoresVisibles.findIndex(antecedente => antecedente.id === FACTOR_CLAVE_ID)
        let aux = factoresVisibles.splice(index, 1)[0];
        factoresVisibles.push(aux);
        let factoresVisiblesAux = factoresVisibles.map((factor) => ({...factor, checked: false}))
        let auxChecked = factoresVisiblesAux
        let antecedentesSeleccionados = [];
        socioSeleccionado.factores.forEach(socioFactor => {
            factoresVisiblesAux.forEach((factor, index) => {
                if(socioFactor.id === factor.id){
                    
                    if(factor.id === FACTOR_CLAVE_ID){
                        setFactorClaveSeleccionado(true)
                    }
                    factor['checked'] = true;
                    auxChecked[index] = factor;
                    setStateBoton(false);
                } 
            })
            factoresVisibles.forEach(factorVisible => {
                if(socioFactor.id === factorVisible.id && factorVisible.visible){
                    antecedentesSeleccionados.push(socioFactor);
                }
            });
        })
        
        setFactoresSeleccionados(antecedentesSeleccionados);
        setFactoresFiltrados(auxChecked);
        setFactorSkeleton(false);
    }

    /**
     * Metodo para validar si el boton debe estar deshabilitado o no
     */
    const validarBoton = (inputs) => {
        setStateBoton(true);
        inputs.forEach((input) => {
            if(input['checked'] === true) {
                setStateBoton(false);
            } 
        })
    }
    /**
     *  metodo que se ejecuta al seleccionar un factor del listado de factores 
     *  si checked es true el factor fue seleccionado, si checked es false el factor fue deseleccionado
     *  factorClave hace referencia al antecedente No posee ningun sintoma que necesita un tratamiento especial, 
     *  en caso de que se seleccione el factor clave, se tienen que deseleccionar los demas factores 
     */
    const handleSelectAntecedente = (e) => {
        let factorSelect = e.target;
        let factorChecked = factoresFiltrados.find((antecedente) => antecedente.descripcion === factorSelect.value)
        if(factorSelect.checked){
            let factoresFiltradosAux = factoresFiltrados;

            if(factorClaveSeleccionado){
                let factorClave = factoresFiltrados.find((antecedente) => antecedente.id === FACTOR_CLAVE_ID)
                factoresFiltradosAux = factoresFiltrados.map((filtradoAux) => {
                    if(filtradoAux.id === FACTOR_CLAVE_ID){
                        return {...factorClave, checked: false}
                    } 
                    return filtradoAux
                });
                setFactorClaveSeleccionado(false);
            }

            factoresFiltradosAux = factoresFiltradosAux.map((filtradoAux) => { 
                if(filtradoAux.descripcion === factorSelect.value){
                    return {...factorChecked, checked: true}
                } 
                return filtradoAux
            });

            if(factorChecked.id === FACTOR_CLAVE_ID ){
                factoresFiltradosAux = factoresFiltrados.map(factor => {
                    if(factorChecked.id === factor.id){
                        return {...factor, checked: true}
                    }
                    return {...factor, checked: false}
                })
                factoresFiltrados.forEach(factor => {
                    factor['checked'] = factorChecked.id === factor.id;
                })
                setFactorClaveSeleccionado(true)
                setFactoresSeleccionados([
                    {...factorChecked, checked: factorSelect.checked}
                ])
            } else {
                const filteredArray = factoresSeleccionados.filter( factor => factor.id !==  FACTOR_CLAVE_ID)
                setFactorClaveSeleccionado(false)
                setFactoresSeleccionados([
                    ...filteredArray,
                    {...factorChecked, checked: factorSelect.checked}
                ])
            }
            
            setFactoresFiltrados(factoresFiltradosAux)
            validarBoton(factoresFiltradosAux);
        } else {

            let factoresFiltradosAux = factoresFiltrados.map((filtradoAux) => { 
                if(filtradoAux.descripcion === factorSelect.value){
                    return {...factorChecked, checked: false}
                } 
                return filtradoAux
            });

            let factoresSeleccionadosAux = factoresSeleccionados.filter((factor) => factor.id !== factorChecked.id)
            setFactoresSeleccionados(factoresSeleccionadosAux)
            setFactoresFiltrados(factoresFiltradosAux)
            validarBoton(factoresFiltradosAux);
        }
    }

    /**
     * metodo que se ejecuta cuando se presiona el boton continuar, recorre los factores seleccionado y los actualiza dependiendo si es un socio familiar o un socio principal
     */
    const handleBotonContinuar = () => {
        let socioSeleccionadoAux;
        let factoresActualizados = [];
        if(factoresSeleccionados.length >= 1){
            if(factorClaveSeleccionado){
                let factorClave = factoresFiltrados.find((antecedente) => antecedente.id === FACTOR_CLAVE_ID)
                factoresActualizados.push({
                    id: factorClave.id,
                    descripcion: factorClave.descripcion,
                    visible: factorClave.visible
                })
            } else {
                factoresSeleccionados.forEach((factorSeleccionado) => {
                    factoresActualizados.push({
                        id: factorSeleccionado.id,
                        descripcion: factorSeleccionado.descripcion,
                        visible: factorSeleccionado.visible
                    })
                })
            }
            socioSeleccionadoAux = socioSeleccionado;
            let factoresActualizadosFiltrados = [];
            //se filtran los duplicados
            factoresActualizadosFiltrados = factoresActualizados.filter((value, index, self) =>
                index === self.findIndex((t) => (
                    t.id === value.id
            )))
            // TODO esto hay que arreglarlo, no funciona correctamente el factor clave
            if (factoresActualizadosFiltrados.length > 1) {
                factoresActualizadosFiltrados = factoresActualizadosFiltrados.filter((factor) => factor.id !== FACTOR_CLAVE_ID);
                factoresActualizadosFiltrados.forEach(factor => {
                    
                })
            }
            socioSeleccionadoAux.factores = factoresActualizadosFiltrados;
        }
        let antecedentes = '';

        socioSeleccionadoAux.factores.forEach( (factor, i) => {
            if(i + 1 < socioSeleccionadoAux.factores.length ){

                antecedentes += factor.id + ','
            }else {
                antecedentes += factor.id + ''
            }
        })
        const configGA = {
            antecedentes
        }

        mapGA(GA_KEYS.ANTECEDENTES_SCREEN_ACTION, setupData, configGA)

        if(socioSeleccionado.contratos){
                updateSocioPrincipal(socioSeleccionadoAux, apiRequest)
                    .then(response => {
                        dispatchAsistenciaMedica({
                            type: AsistenciaMedicaActionType.actualizarAntecedentesSocioSeleccionado,
                            antecedentes: socioSeleccionadoAux.factores
                        })
                    })
                    .catch(error => {
                        console.error(`Hubo un error al persistir el socio principal ${error}`);
                        setError(true)
                    }
                );
        } else {
            updateSocioFamiliar(socioSeleccionadoAux, apiRequest)
                .then(response => {
                    dispatchAsistenciaMedica({
                        type: AsistenciaMedicaActionType.actualizarAntecedentesSocioSeleccionado,
                        antecedentes: socioSeleccionadoAux.factores
                    })
                })
                .catch(error => {
                    console.error(`Hubo un error al persistir el socio principal ${error}`);
                    setError(true)
                }
            );
        };
        history.push(VERIFICAR_DATOS_CONTACTO);
    }

    if(error){
        return <ErrorPage setError={setError}/>
    }

    return (
        <PageWrapper pageClassName='asistencia-antecedentes' socio={socioPrincipal} path={ANTECEDENTES} >
            { factorSkeleton ? 
                            ( <AntecedentesSkeleton /> )
                            :
                            <>  
                                <IonRow>
                                    <IonCol size='12'>
                                        <Titulo
                                            className='antecedentes__title'
                                            titulo={socioSeleccionado.contratos ? 'Por favor, indicanos tu estado de salud' : `Por favor, indicanos el estado de salud de ${capitalizeString(socioSeleccionado.nombre)}`}
                                            id="titulo-asistencia-antecedentes"
                                        />
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol size='12'>
                                        {
                                        factoresFiltrados.map((antecedente, indice) => {
                                            return (
                                                <>
                                                    { indice === factoresFiltrados.length - 1 && antecedente.id === FACTOR_CLAVE_ID && <Separador /> }
                                                    <Checkbox
                                                        btnBlue
                                                        label={antecedente.descripcion}
                                                        onClickLink={function noRefCheck() { }}
                                                        onIonBlur={function noRefCheck() { }}
                                                        onIonFocus={function noRefCheck() { }}
                                                        onClick={handleSelectAntecedente}
                                                        value={antecedente.descripcion}
                                                        checked={antecedente.checked}
                                                        key={antecedente.id}
                                                        id={"checkbox-filtro"+antecedente.id}
                                                    />
                                                </>
                                            )
                                        })
                                        }
                                    </IonCol>
                                </IonRow>
                                <IonRow className='footer_antecedentes ion-no-border'>
                                    <IonCol>
                                        <Button 
                                            id='antecedentes-continuar'
                                            className='footer_button_antecedentes'
                                            primary
                                            label='Continuar'
                                            onClick={handleBotonContinuar}
                                            disable={stateBoton}
                                        />
                                    </IonCol>
                                </IonRow>
                            </>
                        }
        </PageWrapper>
    )
}

export default Antecedentes;