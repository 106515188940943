import React from "react";
import { IonCol, IonContent, IonGrid, IonPage, IonRow } from "@ionic/react";
import { Separador, Skeleton } from "app-principal-componentes-visuales";
import "./HistorialSkeleton.scss";

const HistorialSkeleton = () => {

  return (
        <IonGrid className="content__grid asistencia-historial-skeleton">
          <IonRow>
            <IonCol size="12">
              <Skeleton height={38} />
            </IonCol>
            <IonCol className="row__margin--top" size="12">
              <Skeleton height={50} />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="12">
              <Separador />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="12">
              <Skeleton height={38} />
            </IonCol>
            <IonCol className="row__margin--top" size="12">
              <Skeleton height={80} />
            </IonCol>
            <IonCol className="row__margin--top" size="12">
              <Skeleton height={80} />
            </IonCol>
            <IonCol className="row__margin--top" size="12">
              <Skeleton height={80} />
            </IonCol>
          </IonRow>
        </IonGrid>
  )
}

export default HistorialSkeleton;
