import React, { useState, useContext } from 'react';
import { IonCol, IonContent, IonFooter, IonGrid, IonHeader, IonPage, IonRow } from '@ionic/react';
import { Button, InputText, Titulo } from 'app-principal-componentes-visuales';
import { updateSocioPrincipal } from '../../../api/socioPrincipalService';
import { AsistenciaMedicaContext } from '../../../contexts/AsistenciaMedicaContext';
import { updateSocioFamiliar } from '../../../api/socioFamiliarService';
import './EditarDatos.scss';
import { AsistenciaMedicaActionType } from '../../../interfaces/global-state/asistencia.medica.action.type.enum';

const ModalEditarDatos = ({ selectedAssociate, modifyAssociate, setError, dispatchAsistenciaMedica } : {[ key: string ]: any }) => {

    const [phoneNumber, setPhoneNumber] = useState(selectedAssociate.telefono);
    const [email, setEmail] = useState(selectedAssociate.email);
    const [validEmail, setValidEmail] = useState(null);
    const [validPhone, setValidPhone] = useState(null);
    const [savingChanges, setSavingChanges] = useState(false);
    const { useRequest, setupData } = useContext(AsistenciaMedicaContext);
    const { apiRequest } = useRequest();
    
    const handleEmailInput = (str) => {
        setEmail(str.detail.value);
        setValidEmail( emailIsValid(str.detail.value) )       
    };

    const handlePhoneInput = (str) => {
        setPhoneNumber(str.detail.value);
        setValidPhone( phoneIsValid(str.detail.value) )
    };

    const emailIsValid = (emailValue) => {
        const re = /^[\w-\.]+@([\w-]+\.)+[a-z]{2,3}$/;
        const emailIsValid = re.test(String(emailValue))
        return emailIsValid
    };

    const phoneIsValid = (phoneNumberValue) => {
        const re = /^(?![0])[0-9]{10}$/;
        const phoneIsValid = re.test(String(phoneNumberValue))
        return phoneIsValid
    };

    const buttonValid = () => {
        return emailIsValid(email) && phoneIsValid(phoneNumber)
    }

    const handleSaveChanges = () => {
        setSavingChanges(true);  
        
        let updateAssociate = {...selectedAssociate, telefono: phoneNumber, email: email};
        if(selectedAssociate.contratos){
            updateSocioPrincipal(updateAssociate, apiRequest)
                .then(response => {
                    setSavingChanges(false);
                    modifyAssociate(updateAssociate);
                    dispatchAsistenciaMedica({
                        type: AsistenciaMedicaActionType.actualizarDatosContactoSocioSeleccionado,
                        datosContacto: {
                            telefono: updateAssociate.telefono,
                            email: updateAssociate.email
                        }
                    })
                })
                .catch(error => {
                    console.error(`Hubo un error al persistir el socio principal ${error}`);
                    setError(true)
                }
            );
        } else {
            updateSocioFamiliar(updateAssociate, apiRequest)
                .then(response => {
                    setSavingChanges(false);
                    modifyAssociate(updateAssociate);
                    dispatchAsistenciaMedica({
                        type: AsistenciaMedicaActionType.actualizarDatosContactoSocioSeleccionado,
                        datosContacto: {
                            telefono: updateAssociate.telefono,
                            email: updateAssociate.email
                        }
                    })
                })
                .catch(error => {
                    console.error(`Hubo un error al persistir el socio principal ${error}`);

                    setError(true)
                }
            );
        };
    };
    
    return (
        <IonPage className='asistencia-medica asistencia-editar-datos'>
            <IonHeader className='modal__header ion-no-border'>
                <IonGrid>
                    <IonRow>
                        <IonCol size="12" className="titulo">
                            <Titulo titulo="Editar datos de contacto" id="titulo-asistencia-medica" />
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonHeader>

            <IonContent fullscreen>
                <IonGrid className='modal__grid'>
                    <IonRow>
                        <IonCol size='12' className='input-telefono'>
                            <InputText
                                id='editar-datos-input-telefono'
                                assistiveText={validPhone === false ? '' : 'Completá con código de área sin 0 y número sin 15'}
                                errorLink={validPhone === null ? false : !validPhone}
                                errorText={validPhone === null ? '' : validPhone ? '' : "El télefono debe tener 10 dígitos y no debe comenzar con 0."}
                                label="Teléfono celular"
                                normalLink
                                onIonChange={handlePhoneInput}
                                placeholder={`11 · 1111 · 1111`}
                                type="number"
                                inputmode="number"
                                maxLength={10}
                                value={phoneNumber}
                                />
                        </IonCol>
                    </IonRow>

                    <IonRow>
                        <IonCol size='12'>
                            <InputText
                                id='editar-datos-input-email'
                                errorLink={validEmail === null ? false : !validEmail}
                                errorText={validEmail === null ? '' : validEmail ? '' : "Ingresá un email válido."}
                                label="Email"
                                normalLink
                                onIonChange={handleEmailInput}
                                placeholder="Ingresá tu email"
                                type="text"
                                value={email}
                            />
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>

                    <Button
                        id='guardar-datos-contacto'
                        disable={!buttonValid()}
                        label={savingChanges ? "Guardando cambios" : "Guardar cambios"}
                        onClick={handleSaveChanges}
                        waiting={savingChanges}
                        primary
                        className='boton-guardar-cambios'
                        />
        </IonPage>
    );
};

export default ModalEditarDatos;