import { useContext } from 'react'
import axios from 'axios';
import axiosRetry from 'axios-retry';
// import { cerrarSesionHelper } from '../helpers/cerrarSesion';
import { get, set } from '../helpers/webStorageHelper';
import { cerrarSesionHelper } from '../helpers/cerrarSesion';
import jwt_decode from 'jwt-decode'
import { storageManager } from '../helpers/dataProvider';
import { Environment } from '../environment/Environment';
import { AUTH_SERVICE_URL, INSTROSPECT } from '../utils/constants';

const obtenerDataRefresh = () => {
  const codes: string = localStorage.getItem('validatedCodes')!;
  const user = {...JSON.parse(codes)};

  return user
}

const validarToken = (expirationTime) => {
  const fechaExpiracion = Date.parse(expirationTime);
  return fechaExpiracion > Date.now()
}

const generateRefreshFlowStorage = (storageManager, history, setSessionInfo) => {

  const urlRefeshAuth = Environment.ENV.REFRESH_TOKEN;
  const urlExpiry = `${Environment.ENV.BACKEND_ENDPOINT}${AUTH_SERVICE_URL}${INSTROSPECT}`

  const axiosRetryProperties = {
    retries: 1,
    retryDelay: () => 2000
  };
  // axiosRetry(axios, axiosRetryProperties);
  return new Promise(async(resolve, reject) => {

    const codes = localStorage.getItem('validatedCodes')!;
    const user = {...JSON.parse(codes)};
    const { refreshToken } = user
    const headers = {
      "Content-Type": "application/json"
    }
    
    //validamos si es posible refrescar el refresh token
    let refreshTokenValido = false
    try {
      let responseData = await axios.post(urlExpiry, {token: refreshToken}, {headers: headers})
      refreshTokenValido = responseData.data.active;
    }catch(error) {
      console.error({error})
      //CERRAMOS LA SESION
      const urlAuthIAMLogout = Environment.ENV.LOGOUT_URL
      window.location.replace(urlAuthIAMLogout);
    }
    
    if(refreshTokenValido){
      axios.post(urlRefeshAuth, { refresh_token: refreshToken}, {headers: headers }) 
      .then((res) => {
        const { access_token, expires_in, id_token, refresh_token} = res.data
        const userInfo = jwt_decode(access_token);

        let ExpiresInToken = +expires_in;
        let ExpiraDate = new Date();
        ExpiraDate.setSeconds(ExpiresInToken);
        const sessionExpiresDate = new Date(ExpiraDate)

        const userData = {
          accessToken: access_token,
          expirationTime: ExpiraDate,
          idToken: id_token,
          userInfo: userInfo,
          refreshToken: refresh_token
        }

        set('validatedCodes', JSON.stringify(userData))
        sessionStorage.setItem("expires_in", sessionExpiresDate.toString());
        sessionStorage.setItem("refresh_token", refreshToken);
        sessionStorage.setItem("id_token", id_token);
        sessionStorage.setItem("access_token", access_token);
        sessionStorage.setItem("platform", 'web');
        resolve(userData)
      })
      .catch((resErr) => {
        console.error("Res error dentro de axios generateRefreshFlowStorage", { resErr })
        cerrarSesionHelper(history);
      })
  } else {
      //SESSION EXPIRADA
      sessionStorage.removeItem('expires_in');
      sessionStorage.removeItem('refresh_token');
      sessionStorage.removeItem('id_token');
      sessionStorage.removeItem('access_token');
      sessionStorage.removeItem('codeVerifier')
      sessionStorage.removeItem('codeChallenge')
      localStorage.clear();
      setSessionInfo(undefined)
      history.push('/login')
  }

  })
}

export const useRequest = (history?, setSessionInfo?) => {

  const comprobarRespuestaError = (resErr) => {
    console.log("comprobarResponseError: resErr", {resErr})
  }

  // const sendCrashlytics = (options, resErr) => {
    
  //   console.log("sendCrashlytics", options)

  //   setupData?.logCrashlytics({
  //     message: resErr?.response?.data,
  //     fileName: "useRequest",
  //     functionName: options.url.replace("/"+user?.userInfo?.interlocutorComercial, ""),
  //     lineNumber: 1
  //   },
  //     setupData.app_version,      
  //     setupData.environment,
  //     user?.userInfo?.interlocutorComercial,
  //     String(resErr?.response?.status)
  //   );
  // }

  const selecccionarTipoDeRepuesta = (options) => {

    return new Promise(async (resolve, reject) => {
      switch (options.method) {
        case 'POST':
          axios.post(options.url, options.data, { headers: options.headers, responseType: options.responseType, responseEncoding: options.responseEncoding })
            .then(res => {
              resolve(res)
            })
            .catch((resErr) => {
              console.error("Res error dentro de POST", { ...resErr })
              // resErr?.response?.status != 404 && sendCrashlytics(options, resErr);
              comprobarRespuestaError(resErr);
              reject(resErr)
            })
          break;
        case 'GET':
          axios.get(options.url, { headers: options.headers, params: options.params, responseType: options.responseType, responseEncoding: options.responseEncoding })
            .then(res => {
              resolve(res)
            })
            .catch((resErr) => {
              console.error("Res error dentro de GET", { ...resErr })
              // resErr?.response?.status != 404 && sendCrashlytics(options, resErr);
              comprobarRespuestaError(resErr);
              reject(resErr)
            })
          break;
        case 'DELETE':
          axios.delete(options.url, { headers: options.headers, params: options.params, responseType: options.responseType, responseEncoding: options.responseEncoding })
            .then(res => {
              resolve(res)
            })
            .catch((resErr) => {
              console.error("Res error dentro de DELETE", { ...resErr })
              // resErr?.response?.status != 404 && sendCrashlytics(options, resErr);
              comprobarRespuestaError(resErr);
              reject(resErr)
            })
          break;
        case 'PUT':
          axios.put(options.url, options.data, { headers: options.headers, responseType: options.responseType, responseEncoding: options.responseEncoding })
            .then(res => {
              resolve(res)
            })
            .catch((resErr) => {
              console.error("Res error dentro de PUT", { ...resErr })
              // resErr?.response?.status != 404 && sendCrashlytics(options, resErr);
              comprobarRespuestaError(resErr);
              reject(resErr)
            })
          break;
        case 'PATCH':
          axios.patch(options.url, options.data, { headers: options.headers, responseType: options.responseType, responseEncoding: options.responseEncoding })
            .then(res => {
              resolve(res)
            })
            .catch((resErr) => {
              console.error("Res error dentro de PATCH", { ...resErr })
              // resErr?.response?.status != 404 && sendCrashlytics(options, resErr);
              comprobarRespuestaError(resErr);
              reject(resErr)
            })
          break;
      }
    })
  }

  const apiRequest = async (options, axiosRetryProperties = null) => {
    const user = obtenerDataRefresh()
    if(false){
      console.log("apiRequest bloqueado por cierre de sesion");
      // setupData?.logAppIteration({ name: "USER_UseRequest-ApiRequest-FlagCerrarSesion" });
      return;
    }
    // setupData?.logAppIteration({ name: "USER_UseRequest-ApiRequest" });

    if (axiosRetryProperties) {
      axiosRetry(axios, axiosRetryProperties);
    }

    const accessToken = user?.accessToken;
    const fechaExpiracion = user?.expirationTime;
    options = {
      ...options,
      headers: options?.headers ?? null,
      data: options?.data ?? null,
      params: options?.params ?? null,
      responseType: options?.responseType ?? null,
      responseEncoding: options?.responseEncoding ?? null
    }


    if (validarToken(fechaExpiracion)) {
      let headers = {
        ...options.headers,
        ["Authorization"]: `Bearer ${accessToken}`
      };
      options = {
        ...options,
        headers: headers
      }
      return selecccionarTipoDeRepuesta(options)
    } else {
      return new Promise(async (resolve, reject) => {
        await generateRefreshFlowStorage(storageManager, history, setSessionInfo).then((res) => {
          const nuevoheaders = {
            ...options.headers,
            ["Authorization"]: `Bearer ${(<any>res).accessToken}`
          };
          options = {
            ...options,
            headers: nuevoheaders
          }
          if (validarToken((<any>res).expirationTime)) {
            selecccionarTipoDeRepuesta(options)
              .then(res => resolve(res))
              .catch(resErr => reject(resErr))
          }
        }).catch((err) => {
          console.error("Error al refresh token", err)
          const optionsCrash = {
            url: "No se pudo Refrescar el Token",
          }
          // sendCrashlytics(optionsCrash, err);
          reject(err)
        });
      })
    }
  }

  return {
    apiRequest
  }



}