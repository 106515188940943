import React from 'react'
import { Feedback } from 'app-principal-componentes-visuales'
import { formatDirection } from '../../../utils/ubicacionUtils'
import { capitalizeString } from '../../../utils/utils'
import './EliminarDomicilio.scss'

const EliminarDomicilio = ({ selectedDirection, openDeleteDirection, deleteDirection, modalDelete, isWeb } : {[ key: string ]: any }) => {
    
    const direccion = 'Confirmanos si querés eliminar el domicilio' + capitalizeString(` ${formatDirection(selectedDirection)}, ${selectedDirection.localidad}`);

    const handleCancel = () => {
        openDeleteDirection(selectedDirection)
    }

    const handleAccept = () => {
        deleteDirection(selectedDirection.id, modalDelete)
    }

    return (
        <div className={ isWeb ? 'asistencia-eliminar-domicilio' : 'asistencia-eliminar-domicilio asistencia-eliminar-domicilio-mobile'}>
            <Feedback
                className='feedback__button'
                bgWhite
                id="feedback-eliminar-domicilio"
                labelButtonCancel="No, volver"
                labelButtonOk="S&iacute;, eliminar"
                phoneEliminarTelefonoColor
                onClickCancel={handleCancel}
                onClickOk={handleAccept}
                parrafo={direccion}
                titulo="¿Quer&eacute;s eliminar este domicilio?"
                />
        </div>
    )
}

export default EliminarDomicilio