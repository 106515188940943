import { AsistenciaMedicaGlobalState } from "../interfaces/global-state/asistencia.medica.state.interface";

export const getAsistenciaMedicaInitialState = (): AsistenciaMedicaGlobalState | undefined => {
    return {
        usuario: undefined,
        socioSeleccionado: undefined,
        asistenciasSolicitadas: [],
        datosParaTriage: undefined,
        direccionSeleccionada: undefined,
        asistenciasFinalizadas: [],
        restriccionesPlan: [],
        controlVerdes: undefined
    };
}