import { AxiosResponse } from "axios";
import { BACKEND_ENDPOINT, DESHABILITACION } from "../utils/constants";

export const getDeshabilitacionModuloFromBackend = (apiRequest: any) => {
    return new Promise<any>((resolve, reject) => {
        const url = BACKEND_ENDPOINT + DESHABILITACION;
        const method = 'GET';
        const options = {
            url,
            method
        }
        apiRequest(options)
            .then((response: AxiosResponse) => resolve(response.data))
            .catch((error : any) => reject(error));
    });
}