// Helper intermediario entre storage y metodos.

import { inicializarStorage, clear, get, set, remove } from "./webStorageHelper";

const iniciarStorage = () => {
  return new Promise((resolve, reject) => {
    inicializarStorage().then(
      (res) => {
        resolve(res);
      },
      (err) => {
        reject(err);
      }
    );
  });
};

const getDataStorage = async (datoAObtener: any) => {
  return new Promise((resolve, reject) => {
    get(datoAObtener).then(
      (res) => {
        resolve(res);
      },
      (err) => {
        reject(err);
      }
    );
  });
};

const setDataStorage = async (key: string, datoAGuardar: any) => {
  return set(key, datoAGuardar);
};

const removeDataKeyFromStorage = async (key: string) => {
  return await remove(key);
};

const storageManager = {
  getDataStorage: getDataStorage,
  setDataStorage: setDataStorage,
  removeDataKeyFromStorage: removeDataKeyFromStorage,
  iniciarStorage: iniciarStorage
};

//NO SE INCLUYE EN EL STORAGE MANAGER.
//Es un método sensible que debe usar solo ADS para cerrar sesion
const clearAllStorage = async() => {
  return await clear();
};

export { iniciarStorage, clearAllStorage, storageManager };
