import { IonCol, IonGrid, IonRow } from '@ionic/react';
import { Subtitulo, Texto1Linea } from 'app-principal-componentes-visuales';
import React, { useState } from 'react';
import './DatosContactoAccordion.scss';

const DatosContactoAccordion = ({casoBD}) => {
    const [datosContactoAccordion, setDatosContactoAccordion] = useState(true);

    return (
        <>
            <Subtitulo
                accordion
                accordionOpen={datosContactoAccordion}
                onClickAccordion={() => setDatosContactoAccordion(!datosContactoAccordion)}
                titulo="Datos de contacto"
                txt16
                id="subtitulo-datos"
            />
                
            {
                datosContactoAccordion && 
                <>
                    <IonGrid>
                        <IonRow className='datos-contacto-accordion__row'>
                            <IonCol size='12'>
                                <Texto1Linea
                                    left
                                    onClick={function noRefCheck(){}}
                                    texto="Teléfono celular"
                                />
                                <Texto1Linea
                                    colorBlack
                                    left
                                    texto={casoBD.telefono}
                                    txt16
                                />
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol size='12'>
                                <Texto1Linea
                                    left
                                    onClick={function noRefCheck(){}}
                                    texto="Email"
                                />
                                <Texto1Linea
                                    colorBlack
                                    left
                                    texto={casoBD.email}
                                    txt16
                                />
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </>
            }
        </>
    )
}

export default DatosContactoAccordion