import { AxiosResponse } from "axios";
import { HistorialFinalizado } from "../interfaces/historialFinalizado.interface";
import { BACKEND_ENDPOINT, CASOS_HISTORIAL_SALUS } from "../utils/constants";

export const getHistorialFinalizados = (ic, apiRequest: any) => {
    return new Promise< HistorialFinalizado [] >((resolve, reject) => {
        const url = BACKEND_ENDPOINT + CASOS_HISTORIAL_SALUS + ic;
        const method = 'GET';
        const options = {
            method,
            url
        }
        
        apiRequest(options)
        .then((response : AxiosResponse ) => resolve(response.data.listaServicios))
        .catch((error : any) => reject(error));
    });
}