import { IonCol, IonGrid, IonRow } from '@ionic/react';
import { Subtitulo, Texto1Linea } from 'app-principal-componentes-visuales';
import React, { useState } from 'react';
import { capitalizeString } from '../../../utils/utils';
import './DatosUbicacionAccordion.scss';

const DatosUbicacionAccordion = ({casoBD}) => {
    const [datosUbicacionAccordion, setDatosUbicacionAccordion] = useState(true);

    const formatInformacionAdicional = (domicilio, direcciones) => {
        const domicilioSplit = domicilio.split(',');
        const regex = /\d+/g;
        const nro = domicilioSplit[0].match(regex);
        const calle = domicilioSplit[0].replace(/[0-9]/g, '');

        const direccion = direcciones.find((direccion) => {
            if(domicilioSplit.length === 4){
                return (direccion.calle === calle.trim()  && direccion.altura === nro[0]  
                && direccion.barrio === domicilioSplit[1].trim() && direccion.localidad === domicilioSplit[2].trim() && 
                    direccion.provincia === domicilioSplit[3].trim())
            } else {
                return (direccion.calle === calle.trim()  && direccion.altura === nro[0]  
                && direccion.localidad === domicilioSplit[1].trim() && 
                    direccion.provincia === domicilioSplit[2].trim())
            }
        })
        return direccion ? direccion.observaciones : '-';
    }

    return (
        <>
            <Subtitulo
                accordion
                accordionOpen={datosUbicacionAccordion}
                onClickAccordion={() => setDatosUbicacionAccordion(!datosUbicacionAccordion)}
                titulo="Datos de ubicación"
                txt16
                id="subtitulo-datos"
            />
            
            {
                datosUbicacionAccordion && 
                <>
                    <IonGrid>
                        <IonRow className='datos-ubicacion-accordion__row'>
                            <IonCol size='12'>
                                <Texto1Linea
                                    left
                                    onClick={function noRefCheck(){}}
                                    texto="Domicilio"
                                />
                                <Texto1Linea
                                    colorBlack
                                    left
                                    texto={capitalizeString(casoBD.domicilio)}
                                    txt16
                                />
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol size='12'>
                                <Texto1Linea
                                    left
                                    onClick={function noRefCheck(){}}
                                    texto="Información adicional"
                                />
                                <Texto1Linea
                                    colorBlack
                                    left
                                    texto={formatInformacionAdicional(casoBD.domicilio, casoBD.principal.direcciones)}
                                    txt16
                                />
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </>
            }
        </>
    )
}

export default DatosUbicacionAccordion