// Esta funcion esta aqui meramente para coincidir con los tipos previamente definidos para la version movil por el modulo principal. 
// Por esa misma razon se mantuvo el "async" en algunas las funciones
// No se utiliza en ningun lugar de asistencia medica, ya sea web o movil
export const inicializarStorage = () => {
  return null;
};

export const set = async (key: string, val: any) =>  {
  try {
    const val1 = Promise.resolve(null);
    localStorage.setItem(key, val);
    return true;
  } catch (error) {
    console.error("Error al guardar en el Web Storage:", error);
    return false;
  }
}

export const get = async(key: string) => {

  const val = Promise.resolve(null);
  const json = localStorage.getItem(key);
  return json;
}

export const remove = async (key: string) =>  {
  const val = Promise.resolve(null);
  try {
    localStorage.removeItem(key);
    return true;
  } catch (error) {
    console.error("Error al eliminar del Web Storage:", error);
    return false;
  }
}

export const clear = async() => {
  try {
    const val = Promise.resolve(null);
    localStorage.clear();
    return true;
  } catch (error) {
    console.error("Error al limpiar el Web Storage:", error);
    return false;
  }
}

