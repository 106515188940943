import { Texto1Linea, Titulo, Subtitulo } from 'app-principal-componentes-visuales';
import React, { useEffect, useState } from 'react';
import { IonContent, IonGrid, IonPage, IonRow, IonCol, IonHeader } from '@ionic/react';

const DetalleAtencion = ({ excepcionCoseguro, coseguro, visitaMedicaDomicilio, codigoSeguridad }) => {
    const [excepcionCoseguroState, setExcepcionCoseguroState] = useState(excepcionCoseguro);
    const [coseguroState, setCoseguroState] = useState(coseguro);

    useEffect(() => {
        setExcepcionCoseguroState(excepcionCoseguro);
        setCoseguroState(coseguro);
    }, [])
    

    return (
        <IonPage className='asistencia-detalle-atencion asistencia-medica'>
            <IonHeader className='ion-no-border modal__header'>
                <IonGrid>
                    <IonRow>
                        <IonCol size="12" className="titulo">
                            <Titulo titulo={visitaMedicaDomicilio ? "Visita médica a domicilio" : "Información importante"} id="titulo-asistencia-atencion" />
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonHeader>

            <IonContent>
                <IonGrid className='modal__grid'>
                    {
                        visitaMedicaDomicilio &&
                        <>
                            <Subtitulo titulo="¿En qué consiste la visita médica a domicilio?" id="subtitulo-visita" />
                            <Texto1Linea txt14 texto={"Nuestros profesionales pueden diagnosticar, recetar medicamentos y realizar constancias de atención y de alta."} />
                            <br></br>
                            <Texto1Linea left={true} txt14 texto={"Te enviaremos un código de seguridad. Cuando el profesional se presente, pedile el código y constatá que coincida con el que te enviamos."} />
                            <br></br>
                            {
                                coseguro.coseguroValorFinal > 0 &&
                                <>
                                    <Texto1Linea left={true} txt14 texto={`Dado tu plan, deberás abonar al médico un coseguro de $ ${coseguro.coseguroValorFinal} por esta prestación.`} />
                                    <br></br>
                                </>
                            }


                            <Texto1Linea left={true} txt14 texto={"El servicio puede presentar demoras adicionales entre las 22 y las 5 horas."} />
                            <br></br>
                        </>
                    }

                    {
                        (coseguroState?.coseguroValorFinal > 0 || coseguroState > 0) ?
                            <>
                                <Subtitulo titulo="¿Qué es el coseguro?" id="subtitulo-coseguro" />
                                <Texto1Linea left={true} txt14 texto={"Es una diferencia que se abona por la visita médica domiciliaria."} />
                                <br></br>
                                <Texto1Linea left={true} txt14 texto={"Es importante que dispongas del dinero en efectivo al momento de la consulta."} />
                                <br></br>
                            </>
                        :
                        (excepcionCoseguroState !== undefined || excepcionCoseguroState > 0) &&
                            <></>
                    }

                    {
                        codigoSeguridad && 
                        <>
                        <Subtitulo titulo="Código de seguridad" id="subtitulo-codigo" />
                        <Texto1Linea txt14 texto={"Recordá tener a la mano este código para presentárselo al médico en caso de que te lo solicite"} />
                        <br></br>
                        </>
                    }
                </IonGrid>
            </IonContent>
        </IonPage>
    )
}

export default DetalleAtencion;