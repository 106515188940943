import { DatoInvalido } from "../interfaces/merlin/datoInvalido.interface";
import { Duda } from "../interfaces/merlin/duda.interface";
import { DireccionNormalizadaMerlin } from "../interfaces/merlin/direccionNormalizada.interface";

export class ResponseMerlin {
    status?: string;
    direccionNormalizada?: DireccionNormalizadaMerlin;
    datoInvalido?: DatoInvalido = {} as DatoInvalido;
    opciones?: Duda[];
}
