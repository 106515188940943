import { IonCol, IonGrid, IonPage, IonRow } from '@ionic/react'
import { Feedback, Modal } from 'app-principal-componentes-visuales'
import React, { useContext, useState } from 'react'
import { ASISTENCIA_CREADA, HOME, TITULO_PREGUNTA } from '../../utils/constants';
import { capitalizeString } from '../../utils/utils';
import Contactanos from '../../components/Modals/Contactanos/Contactanos'
import { AsistenciaMedicaContext } from '../../contexts/AsistenciaMedicaContext';

const FeedbackServicioEnCurso = ({history}) => {

  const [showContactanosModal, setShowContactanosModal] = useState(false);

  const { setupData } = useContext(AsistenciaMedicaContext);

  let { socio } = history.location.state;

  const handleVolverInicio = () => {
    history.push(HOME, { seguimiento : false, from: ASISTENCIA_CREADA });
  }

  const openModalContactanos = () => {
    console.log('showContactanosModal', showContactanosModal)
      setShowContactanosModal(!showContactanosModal);
  };

  return (
    <IonPage>
    <div className="asistencia-error_page">
        <IonGrid className='content__grid asistencia_content-container asistencia-error_page'>
            <IonRow>
                <IonCol size='12'>
                <Feedback
                    asistencia
                    handsPhoneError
                    labelButtonCancel='Volver al inicio'
                    labelButtonOk='Contactanos'
                    onClickCancel={handleVolverInicio}
                    onClickOk={openModalContactanos}
                    titulo='Existe una solicitud en curso'
                    parrafo={`Ya existe una solicitud para ${capitalizeString(socio.nombre)}. En caso de que necesites realizar una consulta, podés contactarnos`}
                    id="feedback-asistencia-solicitada-contactanos"
                />
                </IonCol>
            </IonRow>
        </IonGrid>
        <Modal
            className='modal_web'
            hideCloseBtn={false}
            //@ts-ignore
            component={<Contactanos setupData={setupData} screen={'servicio_vigente'} sintomas texto={null} titulo={TITULO_PREGUNTA} />}
            setShowModal={setShowContactanosModal}
            showModal={showContactanosModal}
            id='contactanos'
        />
    </div>
    </IonPage>
  )
}

export default FeedbackServicioEnCurso