import { AsistenciaMedicaGlobalState } from "../interfaces/global-state/asistencia.medica.state.interface";
import { AsistenciaMedicaActionType } from "../interfaces/global-state/asistencia.medica.action.type.enum";
import { AsistenciaMedicaAction } from "../interfaces/global-state/asistencia.medica.action.interface";
import { SocioFamiliar } from "../interfaces/socioFamiliar.interface";

export const asistenciaMedicaReducer = (state: AsistenciaMedicaGlobalState, action: AsistenciaMedicaAction): AsistenciaMedicaGlobalState => {

    let esUsuario;
    if(state?.usuario && state?.socioSeleccionado){
        esUsuario = state.usuario.ic === state.socioSeleccionado.ic;
    }

    switch (action.type) {

        case AsistenciaMedicaActionType.actualizarUsuario:

            return {
                ...state,
                usuario: action.socio
            }

        case AsistenciaMedicaActionType.aceptarTerminos:

            return {
                ...state,
                usuario: {
                    ...state.usuario,
                    terms: true
                }
            }

        case AsistenciaMedicaActionType.actualizarSocioSeleccionado:

            return {
                ...state,
                socioSeleccionado: action.socio
            }

        case AsistenciaMedicaActionType.actualizarAntecedentesSocioSeleccionado:
            if ( esUsuario ) {

                return {
                    ...state,
                    socioSeleccionado: {
                        ...state.socioSeleccionado,
                        factores: action.antecedentes
                    },
                    usuario: {
                        ...state.usuario,
                        factores: action.antecedentes
                    }
                }

            } else {

                let indexFamiliarEditado = state.usuario.familiares.findIndex( familiar => familiar.ic === state.socioSeleccionado.ic );

                let familiarConAntecedentesEditados: SocioFamiliar = { ...state.usuario.familiares[indexFamiliarEditado] };
                familiarConAntecedentesEditados.factores = action.antecedentes;

                let nuevosFamiliares: SocioFamiliar[] = [ ...state.usuario.familiares ];
                nuevosFamiliares.splice( indexFamiliarEditado , 1, familiarConAntecedentesEditados );

                return {
                    ...state,
                    socioSeleccionado: {
                        ...state.socioSeleccionado,
                        factores: action.antecedentes
                    },
                    usuario: {
                        ...state.usuario,
                        familiares: nuevosFamiliares
                    }
                }
            }

        case AsistenciaMedicaActionType.actualizarDatosContactoSocioSeleccionado:
            if ( esUsuario ) {

                return {
                    ...state,
                    socioSeleccionado: {
                        ...state.socioSeleccionado,
                        email: action.datosContacto.email,
                        telefono: action.datosContacto.telefono
                    },
                    usuario: {
                        ...state.usuario,
                        email: action.datosContacto.email,
                        telefono: action.datosContacto.telefono
                    }
                }

            } else {

                let indexFamiliarEditado = state.usuario.familiares.findIndex( familiar => familiar.ic === state.socioSeleccionado.ic );

                let familiarConDatosContactoEditados: SocioFamiliar = { ...state.usuario.familiares[indexFamiliarEditado] };
                familiarConDatosContactoEditados.email = action.datosContacto.email;
                familiarConDatosContactoEditados.telefono = action.datosContacto.telefono;

                let nuevosFamiliares: SocioFamiliar[] = [ ...state.usuario.familiares ];
                nuevosFamiliares.splice( indexFamiliarEditado , 1, familiarConDatosContactoEditados );

                return {
                    ...state,
                    socioSeleccionado: {
                        ...state.socioSeleccionado,
                        email: action.datosContacto.email,
                        telefono: action.datosContacto.telefono
                    },
                    usuario: {
                        ...state.usuario,
                        familiares: nuevosFamiliares
                    }
                }
            }

        case AsistenciaMedicaActionType.actualizarDireccion:
            if(esUsuario) {
                return {
                    ...state,
                    socioSeleccionado: {
                        ...state.socioSeleccionado,
                        direcciones: action.direcciones
                    },
                    usuario: {
                        ...state.usuario,
                        direcciones: action.direcciones
                    }
                }
            } else {
                return {
                    ...state,
                    usuario: {
                        ...state.usuario,
                        direcciones: action.direcciones
                    }
                }
            }
    
        case AsistenciaMedicaActionType.actualizarDireccionSeleccionada:
            return {
                ...state,
                direccionSeleccionada: action.direccionSeleccionada,
            }
        
        case AsistenciaMedicaActionType.actualizarSintomas:
            return {
                ...state,
                datosParaTriage: {
                    ...state.datosParaTriage,
                    sintomasSeleccionados: action.sintomas
                }
            }

        case AsistenciaMedicaActionType.actualizarPreguntas:
            return {
                ...state,
                datosParaTriage: {
                    ...state.datosParaTriage,
                    preguntasRespondidas: action.preguntas
                }
            }

        case AsistenciaMedicaActionType.actualizarObservaciones:
            return {
                ...state,
                datosParaTriage: {
                    ...state.datosParaTriage,
                    observaciones: action.observaciones
                }
            }

        case AsistenciaMedicaActionType.restablecerTriage:
            return {
                ...state,
                datosParaTriage: undefined
            }

        case AsistenciaMedicaActionType.actualizarCasosEnCurso:
            return {
                ...state,
                asistenciasSolicitadas: action.casosEnCurso
            }


        case AsistenciaMedicaActionType.actualizarAtencionesFinalizadas:
            return {
                ...state,
                asistenciasFinalizadas: action.casosFinalizados
            }

        case AsistenciaMedicaActionType.actualizarRestriccionesPlan:
            return {
                ...state,
                restriccionesPlan: action.restricciones
            }

        case AsistenciaMedicaActionType.actualizarControlVerdes:
            return {
                ...state,
                controlVerdes: action.controlVerdes
            }

        default: 

            throw new Error("No existe la operación especificada");

    }
}
