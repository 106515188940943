import { IonCol, IonContent, IonGrid, IonRow } from '@ionic/react';
import { Button, Subtitulo, Titulo } from 'app-principal-componentes-visuales';
import React, { useState } from 'react'
import "./LocalidadError.scss"

const LocalidadError = ({setLocalidadError, setEditarFormulario}) => {

    const [corregir, setCorregir] = useState(true)

    const handleSelectClick = () => {
        if(corregir) {
            setCorregir(false);
            setLocalidadError(false)
            setEditarFormulario(true)
        }
    }

    const closeModal = () => {
        setLocalidadError(false)
    }

    return (
        <>
            <IonContent fullscreen className='asistencia__normalizacion'>
                <IonGrid className='asistencia__normalizacion__header'>
                    <IonRow>
                        <IonCol size="12" className="titulo" >
                            <Titulo titulo={"Encontramos un problema con la dirección"} fontSm  id="asistencia-normalizacion"/>
                        </IonCol>
                        <IonCol size="12" className="titulo" >
                            <Subtitulo className='norm_subtitulo' titulo={"La localidad Ciudad Autónoma de Buenos Aires no corresponde a la provincia Buenos Aires. Por favor, corregí el domicilio y seleccioná Capital Federal como provincia."} id="subtitulo-localidad" />
                        </IonCol>
                    </IonRow>   
                </IonGrid>

                <div className='asistencia__normalizacion__footer'>
                    <IonGrid>
                        <IonRow className='norm_button_container' >
                            <IonCol size='6'>
                                <Button
                                    onClick={closeModal}
                                    className='norm_button'
                                    label={"Volver"}
                                />
                            </IonCol>
                            <IonCol size='6'>
                                <Button
                                    primary
                                    className='norm_button'
                                    onClick={handleSelectClick}
                                    label={"Corregir"}
                                    disable={!corregir}
                                />
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </div>
            </IonContent> 
        </>
    )
};

export default LocalidadError;
