import { Whatsapp } from "../interfaces/whatsapp.interface";
import { formatearTelefono } from "./utils";

/**
 * Encargado de armar el mensaje cuando falla disponibilidad en zona que se envia al backend para el saliente de whatsapp
 * @param asistenciaMedica 
 * @param preguntasRespondidas 
 * @returns 
 */
export const armadoMensajeCarritoAbandonado = (asistenciaMedica, preguntasRespondidas): Whatsapp => {
    const { socioSeleccionado, direccionSeleccionada, datosParaTriage } = asistenciaMedica;

    let nombre = `${socioSeleccionado.nombre} ${socioSeleccionado.apellidos}`
    let depto = direccionSeleccionada.departamento ? direccionSeleccionada.departamento : ' - ';
    let piso = direccionSeleccionada.piso ? direccionSeleccionada.piso : ' - ';
    let barrio = direccionSeleccionada.barrio ? direccionSeleccionada.barrio + ", " : '';
    let entrecalles = "";
    
    if (direccionSeleccionada.entreCalleUno && direccionSeleccionada.entreCalleDos) {
        entrecalles = `Entrecalles: ${direccionSeleccionada.entreCalleUno} y ${direccionSeleccionada.entreCalleDos}.`;
    }
    const antecedentes = socioSeleccionado.factores.map(factor => factor.descripcion).join(", ");
    let domicilio = `${direccionSeleccionada.calle}, ${direccionSeleccionada.altura}, Piso: ${piso}, Depto: ${depto}, ${barrio} ${direccionSeleccionada.localidad}, ${direccionSeleccionada.provincia}. ${entrecalles}`;
    
    let datosContacto = `${socioSeleccionado.email}, N\u00famero de celular: ${formatearTelefono(socioSeleccionado.telefono)}`;
    let sintomasSeleccionados = datosParaTriage.sintomasSeleccionados.map(sintoma => sintoma.descripcion).join(", ");
    let sintomas = `${sintomasSeleccionados}`
    let preguntasSeleccionadas = preguntasRespondidas.map(pregunta => pregunta.descripcion + ' ' + pregunta.respuesta).join(", ");
    let preguntas = `${preguntasSeleccionadas}`;
    let ic = socioSeleccionado.ic;
    let esMenor = false;

    return {
        ic, 
        nombre,
        credencial: socioSeleccionado.nroContrato,
        antecedentes,
        domicilio,
        datosContacto,
        sintomas,
        preguntas,
        esMenor
    }
}

/**
 * Encargado de armar el mensaje que se envia via whatsapp
 * @param asistenciaMedica 
 * @param preguntasRespondidas 
 * @returns 
 */
export const whatsappCarritoAbandonado = (asistenciaMedica, preguntasRespondidas) => {
    const mensajeAsistenciaMedica = armadoMensajeCarritoAbandonado(asistenciaMedica, preguntasRespondidas);
    
    let datosContactoAux = mensajeAsistenciaMedica.datosContacto.split(", ");
    let datosContacto = datosContactoAux[0];
    let datosCelular = datosContactoAux[1];
    
    const mensajeCarritoAbandonado = `Hola, ingresé a la App de OSDE para solicitar una asistencia médica de Urgencias para ${mensajeAsistenciaMedica.nombre}, Credencial Nro. ${mensajeAsistenciaMedica.credencial}.%0A%0A` +
        `Antecedentes: ${mensajeAsistenciaMedica.antecedentes}.%0A`+ 
        `Domicilio: ${mensajeAsistenciaMedica.domicilio}.%0A` +
        `Datos de Contacto: ${datosContacto}.%0A` +
        `${datosCelular}.%0A` +
        `S\u00edntomas: ${mensajeAsistenciaMedica.sintomas}.%0A` +
        `Preguntas: ${mensajeAsistenciaMedica.preguntas}`;

    return mensajeCarritoAbandonado;
}

/**
 * Encargado de armar el mensaje que tiene salida por whatsapp a ysocial
 * @param socioSeleccionado 
 * @returns 
 */
export const armarMensajeEsMenor = (socioSeleccionado) => {
    let nombre = `${socioSeleccionado.nombre} ${socioSeleccionado.apellidos}`
    const mensaje = `Hola, ingresé a la App de OSDE para solicitar una asistencia médica de Urgencias para un menor de 6 meses y no pude continuar. Nombre: ${nombre}, Credencial ${socioSeleccionado.nroContrato}`;

    return mensaje;
}

/**
 * Encargado de armar el mensaje cuando es menor y se envia al backend para el saliente de whatsapp
 * @param socioSeleccionado 
 * @returns 
 */
export const mensajeEsMenor = (socioSeleccionado, socioPrincipal): Whatsapp => {

    let nombre = `${socioSeleccionado.nombre} ${socioSeleccionado.apellidos}`
    const antecedentes = '';
    let domicilio = '';
    
    let datosContacto = `${socioPrincipal.email}, N\u00famero de celular: ${formatearTelefono(socioPrincipal.telefono)}`;
    let sintomas = '';
    let preguntas = '';
    let ic = socioSeleccionado.ic;
    let esMenor = true;

    return {
        ic, 
        nombre,
        credencial: socioSeleccionado.nroContrato,
        antecedentes,
        domicilio,
        datosContacto,
        sintomas,
        preguntas,
        esMenor
    }
}