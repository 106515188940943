import React, { useContext, useEffect, useState } from 'react';
import { IonCol, IonGrid, IonRow } from '@ionic/react';
import { Button, CardSolicitarAsistencia, Modal, Separador, Subtitulo, TextArea, TextLink, Texto1Linea, Titulo } from 'app-principal-componentes-visuales';
import ModalEditarDatos from '../../components/Modals/EditarDatos/EditarDatos';
import CambiarSocio from '../../components/Modals/CambiarSocio/CambiarSocio';
import { NACIONAL, PANTALLA_TELEFONO, PEDIDO_SERVICIO, SELECCIONAR_SOCIO, serviciosEMAceptados, serviciosICAceptados, serviciosVMAceptados, SERVICIO_EM, SERVICIO_IC, SERVICIO_VM, TITULO_INFORMACION_IMPORTANTE, RESUMEN, TELEFONOS_PAGE, CABA, CAPITAL, HOME} from '../../utils/constants';
import { calcularEdad, capitalizeString, formatearNumeroInputCelular } from '../../utils/utils';
import { formatDirection } from '../../utils/ubicacionUtils';
import { getCoseguro, getDisponibilidad, getReglas } from '../../api/asistenciaMedicaService';
import { AsistenciaMedicaContext } from '../../contexts/AsistenciaMedicaContext';
import { ReglaAlternativa } from '../../interfaces/regla/reglaAlternativa.interface';
import { Regla } from '../../interfaces/regla/regla.interface';
import { Direccion } from '../../interfaces/direccion.interface';
import { Alternativa } from '../../interfaces/regla/alternativa.interface';
import { Servicio } from '../../interfaces/servicio.interface';
import { Disponibilidad } from '../../interfaces/disponibilidad.interface';
import { ClasificacionModel } from '../../interfaces/clasificacion.interface';
import './Resumen.scss';
import Contactanos from '../../components/Modals/Contactanos/Contactanos';
import ErrorPage from '../ErrorPage/ErrorPage';
import { AsistenciaMedicaActionType } from '../../interfaces/global-state/asistencia.medica.action.type.enum';
import { sendWhatsAppMessage } from '../../api/mensajeriaService';
import Direcciones from '../Direcciones/Direcciones';
import PageWrapper from '../../components/PageWrapper/PageWrapper';
import { carritoAbandonado } from '../../helpers/whatsappSalienteHelper';
import { whatsappCarritoAbandonado } from '../../utils/whatsappUtils';

const Resumen = ({ history }) => {
    
    let historyState = history.location.state;
    historyState = JSON.parse(historyState);
    const { disponibilidad, clasificacionesHistory, coseguro, direccionPredefinida } = historyState;
    const { useRequest, asistenciaMedica, dispatchAsistenciaMedica, storageManager, isWeb } = useContext(AsistenciaMedicaContext);
    const { apiRequest } = useRequest();
    const [contactoAccordion, setContactoAccordion] = useState(true);
    const [direccionAccordion, setDireccionAccordion] = useState(true);
    const [atencionAccordion, setAtencionAccordion] = useState(true);
    const [showSocioModal, setShowSocioModal] = useState(false);
    const [showEditarContactoModal, setShowEditarContactoModal] = useState(false)
    const [waitingButton, setWaitingButton] = useState(false)
    const [socioSeleccionado, setSocioSeleccionado ] = useState(asistenciaMedica.socioSeleccionado);
    const [direccionSocio, setDireccionSocio] = useState(asistenciaMedica.direccionSeleccionada);
    const [showSintoma, setShowSintoma] = useState('');
    const [error, setError] = useState(false)
    const [showContactanosModal, setShowContactanosModal] = useState(false)
    const [direccionesSocio, setDireccionesSocio] = useState<Direccion[]>(asistenciaMedica?.usuario?.direcciones);
    const [seleccionarDomicilio, setSeleccionarDomicilio] = useState(false);
    const [errorDisponibilidad, setErrorDisponibilidad] = useState(false);
    const [mensaje, setMensaje] = useState('');
    const [carritoAbandonadoDisable, setCarritoAbandonadoDisable] = useState(false);
    const sintomas = asistenciaMedica.datosParaTriage.sintomasSeleccionados;
    const preguntas = asistenciaMedica.datosParaTriage.preguntasRespondidas;
    const observacion = asistenciaMedica.datosParaTriage.observaciones;

    let tarjetaTelefono = false;
    let clasificaciones: ClasificacionModel[] = [];
    let ubicacionSocio = NACIONAL;
    let admiteDisponibilidadEnZona = true;
    let disponibilidadFinal = [];
    let coseguroFinal: any;
    let direccionAnterior = direccionPredefinida;

    useEffect(() => {
        let sintomaAux = '';
        sintomas.forEach(sintoma => {
            sintomaAux = sintomaAux + sintoma.descripcion + ', ';
        });

        sintomaAux = sintomaAux.substring(0, sintomaAux.length - 2 );
        setShowSintoma(sintomaAux);

        storageManager.getDataStorage("deshabilitacion").then(deshabilitarModulos => {
            let deshabilitarParse = JSON.parse(deshabilitarModulos);
            setCarritoAbandonadoDisable(deshabilitarParse?.deshabilitarDisponibilidad);
        })
    }, []);

    const handleCambiarSocio = () => {
        setShowSocioModal(!showSocioModal);
    }

    const handleUbicacionSocio = () => {
        setSeleccionarDomicilio(true);
    }
    
    const handleContacto = () => {
        setShowEditarContactoModal(!showEditarContactoModal);
    }
    
    /**
     * Metodo que navega hasta la pagina de seleccionar socio cuando se presiona el aceptar en el modal
     */
    const acceptCambiarSocio = () => {
        setShowSocioModal(!showSocioModal);
        history.push(SELECCIONAR_SOCIO);
    }

    const refuseCambiarSocio = () => {
        setShowSocioModal(!showSocioModal);
    }

    /**
     * Retorna los datos del contacto modificados y cierra el modal correspondiente
     */
    const modifyAssociate = (updateAssociate) => {
        setSocioSeleccionado({...socioSeleccionado, email: updateAssociate.email, telefono: updateAssociate.telefono});
        handleContacto();
    }

    /**
     * Retorna la direccion seleccionada del modal de datos de ubicacion y la setea en el state de direccionSocio
     */
    const updateDirection = (direction) => {
        let findDirection = direction.find((direccion) => direccion.seleccionado === true);
        dispatchAsistenciaMedica({
            type: AsistenciaMedicaActionType.actualizarDireccionSeleccionada,
            direccionSeleccionada: findDirection
        })
        dispatchAsistenciaMedica({
            type : AsistenciaMedicaActionType.actualizarDireccion,
            direcciones: direction
        })
        setDireccionSocio(findDirection);
        setDireccionesSocio(direction);
    }

    /**
     * metodo que valida si el socio edito la direccion
     */
    const cambioDireccion = ()=>{
        let cambioDireccion = false;

        if(direccionAnterior.provincia === direccionSocio.provincia) {
            if (direccionAnterior.localidad === direccionSocio.localidad) {
                if(direccionAnterior.barrio !== direccionSocio.barrio) {
                    cambioDireccion = true;
                }
            } else {
                cambioDireccion = true;
            }
        }   else {
            cambioDireccion = true
        } 

        return cambioDireccion;
    }

    /**
     * Metodo que realiza los llamados a los request correspondientes de reglas, disponibilidad y coseguro
     */
    const handleBotonContinuar = () => {
        setWaitingButton(!waitingButton);
        let preguntasRequest:any[] = preguntas

        //valido si hubo cambio de direccion
        if (cambioDireccion()) {
            getReglas(preguntasRequest, apiRequest)
            .then((reglas:Regla[]) => {
                if (!reglas || reglas.length <= 0) {
                    setShowContactanosModal(true);
                    setErrorDisponibilidad(false);
                    handleWhatsappMessage();
                    setWaitingButton(false);
                    } else {
                        reglas.forEach((regla: Regla) => {
                            if (regla.visible) {
                                    validacionDeClasificacion(regla.reglaAlternativa).then(pantalla => {
                                    if(pantalla === PANTALLA_TELEFONO) {
                                        setShowContactanosModal(true);
                                        setWaitingButton(false);
                                    } else {
                                        let serviciosDisponibles = { disponibilidad: disponibilidadFinal, clasificaciones: clasificaciones, coseguro: coseguroFinal };
                                        let serviciosDisponiblesArray = serviciosDisponibles.clasificaciones.map((servicio)=>{
                                            return servicio.descripcion;
                                        });
                                        let serviciosPosiblesSinRestriccion = asistenciaMedica?.restriccionesPlan.map((servicio)=>{
                                            return servicio.descripcion;
                                        });
                                        //validacion entre restricciones y servicios disponibles 
                                        let ofrecerServicios = false;
                                        serviciosDisponiblesArray.forEach((servicio, index)=>{
                                            if(serviciosPosiblesSinRestriccion.includes(servicio)) {
                                                ofrecerServicios = true;
                                            }
                                        })
                                        if (ofrecerServicios) {
                                            history.push(PEDIDO_SERVICIO, serviciosDisponibles);
                                            setWaitingButton(false);                
                                        } else {
                                            //no existen servicios que se pueden ofrecer
                                            history.push(TELEFONOS_PAGE, {screen: 'resumen' } )
                                            setWaitingButton(false);
                                        }
                                    }  
                                });
                            } else {
                                setShowContactanosModal(true);
                                setErrorDisponibilidad(false);
                                handleWhatsappMessage();
                                setWaitingButton(false);
                            }
                    });
                }})
            .catch(error => {
                setWaitingButton(false);
                console.error(`Ocurrio un error al realizar la peticion a reglas: ${error}`);
                setError(true)

            })
        } else {
            let serviciosDisponiblesHistory = { disponibilidad: disponibilidad, clasificaciones: clasificacionesHistory, coseguro: coseguro };            
            history.push(PEDIDO_SERVICIO, serviciosDisponiblesHistory);
        }

    }

    /**
     * Metodo que realiza los llamados en caso de ser necesario enviar mensaje de whatsapp
     */
    const handleWhatsappMessage = () => {
        let casosEnCurso = [];

        storageManager.getDataStorage("casosEnCurso").then(casos => {
            casosEnCurso = JSON.parse(casos);        
            let flag = true;

            if(casosEnCurso.length > 0) {
                casosEnCurso.forEach(caso => {
                    if (
                        caso.numeroSocio === asistenciaMedica?.socioSeleccionado?.nroContrato &&
                        (
                        (caso.codigoTipoServicio === SERVICIO_VM && serviciosVMAceptados.includes(caso.codigoSubTipoServicio)) ||
                        (caso.codigoTipoServicio === SERVICIO_IC && serviciosICAceptados.includes(caso.codigoSubTipoServicio)) ||
                        (caso.codigoTipoServicio === SERVICIO_EM && serviciosEMAceptados.includes(caso.codigoSubTipoServicio))
                        )
                        ) {
                        flag = false;
                    }
                });
            };
            
            if(flag) {
                sendWhatsAppMessage(socioSeleccionado, apiRequest)
                    .then(resp => {
                        console.info("Mensaje saliente persistido en la db exitosamente");
                    }).catch(error => {
                        console.error("Error al persistir mensaje saliente en la db", error);
                    });
            }
        });
    };

    /**
     * Metodo encargado de ordenar las reglas alternativas por su prioridad y realizar la consulta a disponibilidad en zona  
     */
    const validacionDeClasificacion = (reglaAlternativa: ReglaAlternativa[]) => {
        return new Promise(async (resolve, reject) => {
            reglaAlternativa.sort((a, b) => (a.prioridad < b.prioridad) ? -1 : 1);
            let pantalla = await consultoDisponibilidadEnZona(reglaAlternativa);
            
            resolve(pantalla);
        });
    }

    /**
     * Metodo que realiza filtra las reglas alternativas por prioridad y luego realiza el analisis de cuales realizar dicho request a disponibilidad en zona
     */
    const consultoDisponibilidadEnZona = async (reglaAlternativa) => {
        let direccion: Direccion = direccionSocio;
        let provincia = direccion.provincia === CABA ? CAPITAL : direccion.provincia;
        let localidad = direccion.localidad;
        let barrio = direccion.barrio;
        let esMenor = calcularEdad(socioSeleccionado.fechaNacimiento) < 18;
        let prioridadesFiltradas;
        let pantalla;
        for (let i = 0; i < reglaAlternativa.length; i++) {
            prioridadesFiltradas = reglaAlternativa.filter(ra => ra.prioridad == reglaAlternativa[i].prioridad);
            pantalla = await analisisDisponibilidadPorPrioridad(prioridadesFiltradas, provincia, localidad, barrio, esMenor);
            if(tarjetaTelefono || clasificaciones.length > 0){
                break;
            } else {
                reglaAlternativa = reglaAlternativa.filter(ra => ra.prioridad != reglaAlternativa[i].prioridad);
                i=-1;
            }
        }
    
        return pantalla;
    }

    /**
     * Metodo que realiza la peticion a disponibilidad en zona para las reglas que contengan segun su clasificacion una disponibilidad activa
     */
    const analisisDisponibilidadPorPrioridad = async (reglaAlternativa, provincia, localidad, barrio, esMenor) => {
        let pantalla = PANTALLA_TELEFONO;
        for (let i = 0; i < reglaAlternativa.length; i++) {
            let alternativa: Alternativa = reglaAlternativa[i].alternativa;
            let clasificacion: ClasificacionModel = alternativa.clasificacion;
            let servicio: Servicio = alternativa.servicio;
            let disponibilidad: Disponibilidad = clasificacion.disponibilidad;
            if (alternativa.salida != 'servicio') {
                tarjetaTelefono = true;
                break;
            } else if (disponibilidad.activa) {
                if (ubicacionSocio === NACIONAL && (disponibilidad.ubicacion === NACIONAL || disponibilidad.ubicacion === 'Ambas')) {
                    if(clasificacion.consultaDisponibilidad && admiteDisponibilidadEnZona) {
                        try {
                            const disponibilidad = await getDisponibilidad(provincia, localidad, barrio, servicio.descripcion, clasificacion.descripcion, esMenor, apiRequest);
                                let disponibilidades = [];
                                disponibilidades.push(disponibilidad);
                                disponibilidadFinal.push(disponibilidad)
                            if(disponibilidad.disponibilidad){
                                pantalla = await cargarCoseguro(provincia, localidad, servicio.descripcion, clasificacion, pantalla);
                            } else {
                                salidaCarritoAbandonado();
                            }
                        } catch(error) {
                            salidaCarritoAbandonado();
                            console.error("Ocurrio un problema al realizar la peticion a disponibilidad en zona:", error);
                        };
                        
                    } else {
                        pantalla = await cargarCoseguro(provincia, localidad, servicio.descripcion, clasificacion, pantalla);
                    }
                }
            }
                // Se deja comentado ya que por el momento no se utilizara la opcion internacional del socio. Se mantendra solamente la nacional.
                // else if (this.ubicacionSocio === 'Internacional' && (disponibilidad.ubicacion === 'Internacional' || disponibilidad.ubicacion === 'Ambas')) {
                //   this.clasificaciones.push(clasificacion);
                //   pantalla = PANTALLA_SERVICIO;
                //   break;
                // }
            }
        return pantalla;
    }

    const cargarCoseguro = async (provincia, localidad, servicio, clasificacion, pantalla) => {

        try {
            const coseguroResponse = await getCoseguro(socioSeleccionado.nroContrato, servicio, clasificacion.descripcion, provincia, localidad, apiRequest)
            clasificacion.coseguro = coseguroResponse.valorFinal;
            clasificacion.errorCoseguro = '';
            coseguroFinal = {
                coseguroValorFinal: coseguroResponse.valorFinal,
                errorCoseguro: ''
            }
        } catch(error) {
            clasificacion.errorCoseguro = error;
            coseguroFinal = {
                coseguroValorFinal: 0,
                errorCoseguro: clasificacion.errorCoseguro
            }
            setWaitingButton(false);
            console.error('Ocurrio un error con el request de coseguro:', error);
        } finally {
                clasificaciones.push(clasificacion);
        }   
        return pantalla = PEDIDO_SERVICIO;
    }

    const salidaCarritoAbandonado = () => {
        if(carritoAbandonadoDisable) {
            setErrorDisponibilidad(false);
            setShowContactanosModal(true);
            setWaitingButton(false);
        } else {
            let mensajeCarritoAbandonado  = whatsappCarritoAbandonado(asistenciaMedica, preguntas);
            setErrorDisponibilidad(true);
            setMensaje(mensajeCarritoAbandonado);
            setShowContactanosModal(true);
            setWaitingButton(false);
            carritoAbandonado(asistenciaMedica, preguntas, apiRequest, storageManager, false);
        }
    }
    
    if(error){
        return <ErrorPage setError={setError}/>
    }

    if(seleccionarDomicilio) {
        return <Direcciones 
                    direccionesSocioPrincipal={direccionesSocio} 
                    updateDirection={updateDirection} 
                    setSeleccionarDomicilio={setSeleccionarDomicilio}
                    handleContinueClick={() => setSeleccionarDomicilio(false)}
                    localidadError={false}
                />
    } else {
        return (
            <>
                <PageWrapper 
					socio={asistenciaMedica.usuario} 
					pageClassName="asistencia-resumen"
					path={RESUMEN}>
                        <IonRow>
                            <IonCol size='12'>
                                <Titulo
                                    titulo='Antes de finalizar, asegurate de que los datos sean correctos'
                                    id="titulo-asistencia-resumen"
                                />
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol size='12'>
                                <CardSolicitarAsistencia
                                    cambiarSocio
                                    className='solicitar-asistencia-card'
                                    socio={capitalizeString(`${socioSeleccionado.nombre} ${socioSeleccionado.apellidos}`)}
                                    numSocio={`N° de Socio: ${socioSeleccionado.nroContrato}`}
                                    nacimiento={`Fecha de nacimiento: ${socioSeleccionado.fechaNacimiento.split('-').reverse().join('/')}`}
                                    documento={`Documento: ${socioSeleccionado.dni}`}
                                    id={socioSeleccionado.id}
                                    onClick={handleCambiarSocio}
                                />
                            </IonCol>
                        </IonRow>
                        <IonRow className='contacto__row--margin-top'>
                            <IonCol size='12'>
                                <Subtitulo
                                    accordion
                                    accordionOpen={contactoAccordion}
                                    onClickAccordion={() => setContactoAccordion(!contactoAccordion)}
                                    titulo='Datos de contacto'
                                    txt16
                                    id='subtitulo-resumen-contacto'
                                />
                                {contactoAccordion && 
                                    <>
                                        <IonRow>
                                            <IonCol size='12' className='contenido-inputs'>
                                                <Texto1Linea
                                                    left
                                                    texto='Tel&eacute;fono celular'
                                                />
                                                <Texto1Linea
                                                    colorBlack
                                                    left
                                                    texto={!socioSeleccionado.telefono ? ' - ' : formatearNumeroInputCelular(socioSeleccionado.telefono)}
                                                    txt16
                                                />
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol size='12' className='contenido-inputs' >
                                                <Texto1Linea
                                                    left
                                                    texto='Email'
                                                />
                                                <Texto1Linea
                                                    colorBlack
                                                    left
                                                    texto={!socioSeleccionado.email ? ' - ' : socioSeleccionado.email}
                                                    txt16
                                                />
                                                <TextLink
                                                    id='asistencia__resumen-contacto'
                                                    onClick={handleContacto}
                                                    right
                                                    texto='Editar datos'
                                                />
                                            </IonCol>
                                        </IonRow>
                                    </>
                                }
                            </IonCol>
                        </IonRow>

                        <Separador />

                        <IonRow>
                            <IonCol size='12'>
                                <Subtitulo
                                    accordion
                                    accordionOpen={direccionAccordion}
                                    onClickAccordion={() => setDireccionAccordion(!direccionAccordion)}
                                    titulo='Datos de ubicaci&oacute;n'
                                    txt16
                                    id='subtitulo-resumen-ubicacion'
                                />
                                {direccionAccordion && 
                                    <>
                                        <IonRow className=''>
                                            <IonCol size='12' className='contenido-inputs'>
                                                <Texto1Linea
                                                    left
                                                    texto='Domicilio'
                                                />
                                                <Texto1Linea
                                                    colorBlack
                                                    left
                                                    texto={capitalizeString(formatDirection(direccionSocio))}
                                                    txt16
                                                />
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol size='12' className='contenido-inputs'>
                                                <TextArea
                                                    disabled
                                                    label="Información adicional"
                                                    placeholder="Información adicional"
                                                    value={direccionSocio.observaciones ? direccionSocio.observaciones : '-'}
                                                    id="text-area-informacion-adicional"
                                                />
                                                <TextLink
                                                    id='asistencia__resumen-direccion'
                                                    onClick={handleUbicacionSocio}
                                                    right
                                                    texto='Editar datos'
                                                />
                                            </IonCol>
                                        </IonRow>
                                    </>
                                }
                            </IonCol>
                        </IonRow>

                        <Separador />

                        <IonRow>
                            <IonCol size='12' >
                                <Subtitulo
                                    accordion
                                    accordionOpen={atencionAccordion}
                                    onClickAccordion={() => setAtencionAccordion(!atencionAccordion)}
                                    titulo='Datos de la atenci&oacute;n'
                                    txt16
                                    id='subtitulo-resumen-atencion'
                                />
                                {atencionAccordion && 
                                    <>
                                        <IonRow>
                                            <IonCol size='12' className='contenido-inputs'>
                                                <Texto1Linea
                                                    left
                                                    texto='Síntomas'
                                                />
                                                <Texto1Linea
                                                    colorBlack
                                                    left
                                                    texto={showSintoma}
                                                    txt16
                                                />
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol size='12' className='contenido-inputs'>
                                                <Texto1Linea
                                                    left
                                                    texto='Observaciones adicional'
                                                />
                                                <Texto1Linea
                                                    colorBlack
                                                    left
                                                    texto={observacion ? observacion : '-'}
                                                    txt16
                                                />
                                            </IonCol>
                                        </IonRow>
                                    </>
                                }
                            </IonCol>
                        </IonRow>
                        <IonGrid className="sticky-button-container">
                            <Button
                                id='resumen-continuar'
                                    primary
                                    label='Continuar'
                                    waiting={waitingButton}
                                    onClick={handleBotonContinuar}
                                />
                        </IonGrid>
                </PageWrapper>
                <Modal
                    className='modal_web'
                    hideCloseBtn={false}
                    //@ts-ignore
                    component={<CambiarSocio acceptCambiarSocio = {acceptCambiarSocio} refuseCambiarSocio = {refuseCambiarSocio} />}
                    setShowModal={setShowSocioModal} 
                    showModal={showSocioModal}
                    id="cambiar-socio-modal"  
                    screen60
                />

                <Modal
                    className='modal_web'
                    hideCloseBtn={false}
                    //@ts-ignore
                    component={<ModalEditarDatos selectedAssociate={socioSeleccionado} modifyAssociate = {modifyAssociate} dispatchAsistenciaMedica = {dispatchAsistenciaMedica} />}
                    setShowModal={setShowEditarContactoModal}
                    showModal={showEditarContactoModal}
                    id="datos-contacto-modal"
                />
                {
                    errorDisponibilidad ?
                        <Modal
                            className='modal_web'
                            hideCloseBtn={false}
                            actionClose={ ()=> {
                                history.push(HOME)
                            }}
                            //@ts-ignore
                            component={<Contactanos menorSeisMeses={false} sintomas texto={mensaje} titulo={TITULO_INFORMACION_IMPORTANTE}/> }
                            setShowModal={setShowContactanosModal}
                            showModal={showContactanosModal}
                            id="contactanos"
                        />
                    :
                        <Modal
                            className='modal_web'
                            hideCloseBtn={false}
                            actionClose={ ()=> {
                                history.push(HOME)
                            }}
                            //@ts-ignore
                            component={<Contactanos menorSeisMeses = {true} titulo={TITULO_INFORMACION_IMPORTANTE}/>}
                            setShowModal={setShowContactanosModal}
                            showModal={showContactanosModal}
                            id="contactanos"
                        />
                }
            </>
        )
    }
}

export default Resumen