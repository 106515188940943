import { Geolocation } from '@capacitor/geolocation';

/**
 * Método que se encarga de revisar si el usuario tiene permisos para retornar su geolocalización
 * @param storageManager 
 */
const checkPermisosGeo = (storageManager) => {
    Geolocation.checkPermissions().then((resp)=>{
        if( resp.coarseLocation !== 'prompt' && resp.location !== 'prompt' && resp.coarseLocation !== 'prompt-with-rationale' && resp.location !== 'prompt-with-rationale' && resp.coarseLocation !== "denied" && resp.location !== 'denied') {
            inicioLocation(storageManager);
        } else {
            console.error("El socio no dio permisos de geolocalización");
            errorGeolocalizacion(storageManager);
        }
    }).catch((error)=>{
        console.error("Ocurrió un problema relacionado con los permisos de geolocalización", 'logsitoerror', {error});
        errorGeolocalizacion(storageManager);
    })
}

/**
 * Método que retorna la geolocalización
 * @param storageManager 
 */
const inicioLocation = (storageManager) => {
    let optionsGeoLoc = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
    };
    Geolocation.getCurrentPosition(optionsGeoLoc).then((geo) => {
        storageManager.setDataStorage("geolocalizacion", JSON.stringify(geo));
    }).catch((error) => {
        console.error("Ocurrió un problema al recuperar la geolocalización", error);
    });
};

const inicioLocationWeb = async(storageManager) => {
    let optionsGeoLoc = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
    };
    const geo = await Geolocation.getCurrentPosition(optionsGeoLoc);
    const geoAux = {
        coords: {
            accuracy: geo.coords.accuracy,
            latitude: geo.coords.latitude,
            longitude: geo.coords.longitude
        }
    }
    const stringGeo = JSON.stringify(geoAux);
    storageManager.setDataStorage("geolocalizacion", stringGeo);

    return geoAux
};

const errorGeolocalizacion = (storageManager) => {
    storageManager.setDataStorage("geolocalizacion", null);
}

export {checkPermisosGeo, inicioLocation, inicioLocationWeb}