import { IonCol, IonContent, IonFooter, IonGrid, IonPage, IonRow, IonToolbar } from "@ionic/react";
import { Button, CardData, Modal, Subtitulo, TextLink, Titulo } from "app-principal-componentes-visuales";
import React, { useState, useEffect, useContext } from "react";
import { updateSocioPrincipal } from "../../api/socioPrincipalService";
import AsistenciaHeader from "../../components/Headers/AsistenciaHeader/AsistenciaHeader";
import FormularioDomicilio from "../../components/Modals/FormularioDomicilio/FormularioDomicilio";
import Normalizacion from "../../components/Modals/Normalizacion/Normalizacion";
import { AsistenciaMedicaContext } from "../../contexts/AsistenciaMedicaContext";
import { normalizarDireccion } from "../../helpers/direccionHelper";
import { Direccion } from "../../interfaces/direccion.interface";
import { AsistenciaMedicaActionType } from "../../interfaces/global-state/asistencia.medica.action.type.enum";
import { Socio } from "../../models/socioPrincipal.model";
import { CABA, CAPITAL, CORREGIDO, DUDOSO, NO_ENCONTRADO, SINTOMAS, VERIFICAR_DATOS_UBICACION } from "../../utils/constants";
import { completeDirection, eliminarLocalidadStorage, formatDirectionNormalizada } from "../../utils/ubicacionUtils";
import { capitalizeString, capitalizeWord } from "../../utils/utils";
import ErrorPage from "../ErrorPage/ErrorPage";
import "./SeleccionarDomicilio.scss";
import LocalidadError from "../../components/Modals/LocalidadError/LocalidadError";
import Direcciones from "../Direcciones/Direcciones";
import SessionHeader from "../../components/Headers/SessionHeader/SessionHeader";
import Footer from "../../components/Footer/Footer";
import { checkPermisosGeo, inicioLocationWeb } from "../../helpers/geolocalizacion";
import { checkearGeolocalizacion, instanciarGoogleServices } from "../../helpers/googleApi";
import ProximidadUbicacion from "../../components/Modals/ProximidadUbicacion/ProximidadUbicacion";
import PageWrapper from "../../components/PageWrapper/PageWrapper";

const SeleccionarDomicilio = ({ history }) => {

	const { useRequest, asistenciaMedica, dispatchAsistenciaMedica, storageManager, isWeb } = useContext(AsistenciaMedicaContext);
	const socioPrincipal = asistenciaMedica.usuario;
	const isPrincipal = socioPrincipal.contratos !== undefined;
	const [formularioModal, setFormularioModal] = useState(false);
	const [editPlace, setEditPlace] = useState(false);
	const [error, setError] = useState(false)
	const [direccionesSocio, setDireccionesSocio] = useState(socioPrincipal.direcciones);
	const [showNormalizacionModal, setShowNormalizacionModal] = useState(false);
	const [selectedDirection, setSelectedDirection] = useState(null);
	const [stateMerlin, setStateMerlin] = useState(undefined);
	const [direccionInputState, setDireccionInputState] = useState(null)
	const [edicionDireccion, setEdicionDireccion] = useState(false);
	const [merlinWaiting, setMerlinWaiting] = useState(false);
	const [localidadError, setLocalidadError] = useState(false);
	const [seleccionarDomicilio, setSeleccionarDomicilio] = useState(false);
	const [proximidadModal, setProximidadModal] = useState(false);
	const [menorDireccionProxima, setMenorDireccionProxima] = useState(null);

	const { apiRequest } = useRequest();

	useEffect(() => {
		if (isWeb) {
			inicioLocationWeb(storageManager);
		} else {
			checkPermisosGeo(storageManager);
		}
		foundSelected(socioPrincipal.direcciones);
	}, [asistenciaMedica]);

	const updateDirection = (direcciones) => {
		let socio: Socio = socioPrincipal;
		let direccionesAux = direcciones.map((direc) => {
			if (direc.provincia === CABA) {
				return ({ ...direc, provincia: CAPITAL })
			}
			return direc;
		})
		socio.direcciones = direccionesAux;
		foundSelected(socio.direcciones);
		updateSocioPrincipal(socioPrincipal, apiRequest)
			.then(response => {
				dispatchAsistenciaMedica({
					type: AsistenciaMedicaActionType.actualizarDireccion,
					direcciones: response.data.direcciones
				})
				for (let i = 0; i < direcciones.length; i++) {
					direcciones[i] = { ...direcciones[i], id: response.data.direcciones[i].id }
					direcciones[i].socioId = socioPrincipal.id;
				}
				setDireccionesSocio(direcciones);
			})
			.catch(error => {
				console.error(`Hubo un error al persistir el socio principal ${error}`);
				setError(true)
			}
			)
	}

	const modifyDirection = (direccionModificada) => {
		setEdicionDireccion(true);
		setMerlinWaiting(true);
		setDireccionInputState(direccionModificada);
		normalizarDireccion(direccionModificada, apiRequest)
			.then((responseMerlin) => {
				setStateMerlin(responseMerlin);
				setMerlinWaiting(false);
				setShowNormalizacionModal(true);
			})
	}

	const addDirection = (newDireccion) => {
		setEdicionDireccion(false);
		setMerlinWaiting(true);
		setDireccionInputState(newDireccion);
		normalizarDireccion(newDireccion, apiRequest)
			.then((responseMerlin) => {
				setStateMerlin(responseMerlin);
				setMerlinWaiting(false);
				setShowNormalizacionModal(true);
			})
			.catch((error) => console.error(error));
	}

	const foundSelected = (direcciones) => {
		const foundAddress = direcciones.find((address) => address.seleccionado === true);
		foundAddress ? setSelectedDirection(foundAddress) : setSelectedDirection(null);
	}

	const editLocation = () => {
		setEditPlace(!editPlace);
	}

	const handleNormalizacion = (data) => {
		if (stateMerlin.status == DUDOSO) {
			normalizarDireccion({
				barrio: direccionInputState.barrio,
				calle: data.calle,
				localidad: direccionInputState.localidad,
				altura: direccionInputState.altura,
				provincia: direccionInputState.provincia
			}, apiRequest)
				.then((responseMerlin) => {
					let direccionFormateada: Direccion = formatDirectionNormalizada(responseMerlin.direccionNormalizada);
					direccionFormateada = completeDirection(direccionFormateada, direccionInputState);
					let direccionesAux: Direccion[] = direccionesSocio;
					direccionesAux.forEach(direccion => {
						direccion.seleccionado = false
					});
					direccionesAux.push(direccionFormateada);
					updateDirection(direccionesAux);
					setShowNormalizacionModal(false);
					setFormularioModal(false);
				})
		}
		if (stateMerlin.status === NO_ENCONTRADO) {
			setShowNormalizacionModal(false);
		}
		if (stateMerlin.status === CORREGIDO) {
			let direccionFormateada = formatDirectionNormalizada(stateMerlin.direccionNormalizada);
			direccionFormateada = completeDirection(direccionFormateada, direccionInputState);
			let direccionesAux: Direccion[] = direccionesSocio;
			direccionesAux.forEach(direccion => {
				direccion.seleccionado = false
			});
			direccionesAux.push(direccionFormateada);
			setSelectedDirection(direccionInputState);
			updateDirection(direccionesAux);
			setShowNormalizacionModal(false);
			setFormularioModal(false);
		}
	}

	const handleNormalizacionEditar = (data) => {
		if (stateMerlin.status == DUDOSO) {
			normalizarDireccion({
				barrio: direccionInputState.barrio,
				calle: data.calle,
				localidad: direccionInputState.localidad,
				altura: direccionInputState.altura,
				provincia: direccionInputState.provincia
			}, apiRequest)
				.then((responseMerlin) => {
					let direccionFormateada: Direccion = formatDirectionNormalizada(responseMerlin.direccionNormalizada);
					direccionFormateada = completeDirection(direccionFormateada, direccionInputState);
					direccionFormateada.id = direccionInputState.id;
					let direction = direccionesSocio.map(direccion => {
						return direccion.id === direccionFormateada.id ? direccionFormateada : direccion;
					})
					setDireccionesSocio(direction);
					updateDirection(direction);
					setShowNormalizacionModal(false);
					setFormularioModal(false);
				})
		}
		if (stateMerlin.status === NO_ENCONTRADO) {
			setShowNormalizacionModal(false);
		}
		if (stateMerlin.status === CORREGIDO) {
			let direccionFormateada = formatDirectionNormalizada(stateMerlin.direccionNormalizada);
			direccionFormateada = completeDirection(direccionFormateada, direccionInputState);
			direccionFormateada.id = direccionInputState.id;
			let direction = direccionesSocio.map(direccion => {
				return direccion.id === direccionFormateada.id ? direccionFormateada : direccion;
			})
			setDireccionesSocio(direction);
			updateDirection(direction);
			setShowNormalizacionModal(false);
			setFormularioModal(false);
		}
	}

	const handleCloseModal = () => {
		if (stateMerlin.status == DUDOSO) {
			setShowNormalizacionModal(false);
		} else {
			let direccionesAux: Direccion[] = direccionesSocio;
			direccionesAux.forEach(direccion => {
				direccion.seleccionado = false
			});
			direccionesAux.push(direccionInputState);
			setShowNormalizacionModal(false);
			setSelectedDirection(direccionInputState);
			updateDirection(direccionesAux);
			setFormularioModal(false);
		}
	}

	const handleCloseModalEdit = () => {
		if (stateMerlin.status == DUDOSO) {
			setShowNormalizacionModal(false);
		} else {
			let direction = direccionesSocio.map(direccion => {
				return direccion.id === direccionInputState.id ? direccionInputState : direccion;
			})
			setDireccionesSocio(direction);
			updateDirection(direction);
			setShowNormalizacionModal(false);
			setFormularioModal(!formularioModal)
		}
	}

	const handleClickValidarProximidad = () => {
		checkearGeolocalizacion(direccionesSocio, selectedDirection, storageManager).then(resultado => {
			if (resultado) {
				setMenorDireccionProxima(resultado);
				setProximidadModal(true);
			} else {
				handleClick();
			}
		})
			.catch((error) => {
				handleClick();
			})
	}

	/**
	 * actualiza la direccion seleccionada por proximidad
	 */
	const handleClickCambiarDireccionProximidad = () => {
		let direccionAux: Direccion[] = direccionesSocio;
		direccionAux.forEach(direccion => {
			direccion.seleccionado = (direccion.id === menorDireccionProxima.id)
		});
		const foundAddress = direccionAux.find((address) => address.seleccionado === true);
		dispatchAsistenciaMedica({
			type: AsistenciaMedicaActionType.actualizarDireccion,
			direcciones: direccionAux
		})
		updateDirection(direccionAux);
		handleClick(foundAddress);
	}

	const handleClick = (foundAddress = null) => {
		let dirSeleccionada = foundAddress ? foundAddress : selectedDirection;
		if (selectedDirection.provincia === 'BUENOS AIRES' && (selectedDirection.localidad === 'CIUDAD AUTONOMA BUENOS AIRES' || selectedDirection.localidad === 'CAPITAL FEDERAL')) {
			if (!localidadError) {
				eliminarLocalidadStorage(storageManager);
				setLocalidadError(true);
			}
		} else {
			dispatchAsistenciaMedica({
				type: AsistenciaMedicaActionType.actualizarSintomas,
				sintomas: []
			})

			dispatchAsistenciaMedica({
				type: AsistenciaMedicaActionType.actualizarDireccionSeleccionada,
				direccionSeleccionada: dirSeleccionada
			})
			history.push(SINTOMAS);
		}
	}

	const handleSeleccionarDireccion = () => {
		setSeleccionarDomicilio(true);
	}

	if (error) {
		return <ErrorPage setError={setError} />
	}

	if(seleccionarDomicilio) {
		return (
			<>
				<Direcciones
					handleContinueClick={handleClickValidarProximidad}
					direccionesSocioPrincipal={socioPrincipal.direcciones} 
					updateDirection={updateDirection} 
					setSeleccionarDomicilio={setSeleccionarDomicilio}
					localidadError={localidadError}
				/>

				<Modal
					screen60
					className="modal_web"
					component={
						<ProximidadUbicacion
							onClickContinuar={handleClick}
							onClickCambiarDireccion={handleClickCambiarDireccionProximidad}
							direccionMasProxima={menorDireccionProxima}
						/>
					}
					setShowModal={setProximidadModal}
					showModal={proximidadModal}
					id="modal-proximidad-ubicacion"
				/>
			</>
		)
	} else {
		if (!selectedDirection) {
			return (
				<>
					<PageWrapper 
					socio={asistenciaMedica.usuario} 
					pageClassName="asistencia-seleccionar-domicilio"
					path={VERIFICAR_DATOS_UBICACION}
					className="verificar-datos-grid">
						<IonGrid>
							<IonRow>
								<Titulo className="sd__title" titulo={"Ingresá un domicilio"} id="titulo-asistencia-seleccionar-domicilio" />
							</IonRow>
							<IonRow>
								<Subtitulo txt16 titulo={"Debe corresponder al lugar donde se encontrará para recibir la asistencia médica."} id="subtitulo-seleccionar-domicilio" />
							</IonRow>
							<IonRow>
								<Subtitulo txt16 className="domicilios_card" txtCenter titulo={"No tenés domicilios registrados"} id="subtitulo-domicilio" />
							</IonRow>
							<IonRow className="form__link">
								<TextLink id="asistencia__seleccionar-domicilio" texto="Agregar un nuevo domicilio" onClick={() => setFormularioModal(true)} />
							</IonRow>
						</IonGrid>
						<Button
							id="seleccionar-domicilio-continuar"
							className={isWeb ? 'seleccionar-domicilio__button' : 'seleccionar-domicilio-mobile__button'}
							key="d_continue_button"
							disable
							primary
							label={"Continuar"}
						/>
					</PageWrapper>
					<Modal
						className="modal_web-large"
						hideCloseBtn={editPlace}
						showModal={formularioModal}
						setShowModal={setFormularioModal}
						id="formulario-domicilio-modal"
						//@ts-ignore
						component={
							<FormularioDomicilio
								openFormularioModal={() => setFormularioModal(!formularioModal)}
								addDirection={addDirection}
								selectedDirection={selectedDirection}
								modifyDirection={modifyDirection}
								editLocation={editLocation}
								merlinWaiting={merlinWaiting}
								isWeb={isWeb}
							/>
						}
					/>
					<Modal
						screen60
						hideCloseBtn
						className='modal_web'
						//@ts-ignore
						component={
							<Normalizacion
								onClick={edicionDireccion ? handleNormalizacionEditar : handleNormalizacion}
								closeModal={edicionDireccion ? handleCloseModalEdit : handleCloseModal}
								data={stateMerlin}
								isWeb={isWeb}
							/>
						}
						setShowModal={setShowNormalizacionModal}
						showModal={showNormalizacionModal}
						id="modal-normalizacion"
					/>
				</>
			);
		}

		return (
			<>
				<PageWrapper
					socio={asistenciaMedica.usuario}
					pageClassName="asistencia-seleccionar-domicilio"
					path={VERIFICAR_DATOS_UBICACION}
					className="verificar-datos-grid">
					<IonGrid>
						<IonRow>
							<Titulo className="sd__title" titulo={"Seleccioná un domicilio"} id="titulo-asistencia-seleccionar-domicilio-2" />
						</IonRow>
						<IonRow>
							<Subtitulo txt16 titulo={"Debe corresponder al lugar donde se encontrará para recibir la asistencia médica."} id="subtitulo-corresponder" />
						</IonRow>
						<IonRow>
							<IonCol size='12'>
								<CardData
									asistencia
									direccion={selectedDirection.barrio ?
										capitalizeString(`${selectedDirection.calle} ${selectedDirection.altura}, ${selectedDirection.barrio}`) :
										capitalizeString(`${selectedDirection.calle} ${selectedDirection.altura}`)}
									id="asistencia-direccion-editar"
									linkEdit="Editar"
									onClick={() => setFormularioModal(true)}
									texto={<>{capitalizeString(selectedDirection.localidad)}<br />{selectedDirection.observaciones !== undefined ? capitalizeString(selectedDirection.observaciones) : selectedDirection.observaciones}</>}
								/>
							</IonCol>
						</IonRow>
						<IonRow className="form__link">
							<TextLink id="asistencia__form-link" texto="Seleccionar otro domicilio" onClick={() => handleSeleccionarDireccion()} />
						</IonRow>
					</IonGrid>
					<Button
						id="seleccionar-domicilio-continuar"
						className='seleccionar-domicilio__button'
						key="continue_button"
						primary
						onClick={handleClickValidarProximidad}
						label={"Continuar"}
						disable={localidadError}
					/>
				</PageWrapper>
				<Modal
					className="modal_web-large"
					hideCloseBtn={editPlace}
					showModal={formularioModal}
					setShowModal={setFormularioModal}
					id="formulario-domicilio-modal"
					//@ts-ignore
					component={
						<FormularioDomicilio
							openFormularioModal={() => setFormularioModal(!formularioModal)}
							addDirection={addDirection}
							selectedDirection={selectedDirection}
							modifyDirection={modifyDirection}
							editLocation={editLocation}
							showModal={false}
							merlinWaiting={merlinWaiting}
							isWeb={isWeb}
						/>
					}
				/>
				<Modal
					screen60
					hideCloseBtn
					//@ts-ignore
					className='modal_web'
					component={
						<Normalizacion
							onClick={edicionDireccion ? handleNormalizacionEditar : handleNormalizacion}
							closeModal={edicionDireccion ? handleCloseModalEdit : handleCloseModal}
							data={stateMerlin}
							isWeb={isWeb}
						/>
					}
					setShowModal={setShowNormalizacionModal}
					showModal={showNormalizacionModal}
					id="modal-normalizacion"
				/>
				<Modal
					screen60
					hideCloseBtn
					//@ts-ignore
					component={
						<LocalidadError
							setLocalidadError={setLocalidadError}
							setEditarFormulario={setFormularioModal}
						/>
					}
					setShowModal={setLocalidadError}
					showModal={localidadError}
					id="modal-localidad-error"
				/>
				<Modal
					screen60
					className="modal_web"
					component={
						<ProximidadUbicacion
							onClickContinuar={handleClick}
							onClickCambiarDireccion={handleClickCambiarDireccionProximidad}
							direccionMasProxima={menorDireccionProxima}
						/>
					}
					setShowModal={setProximidadModal}
					showModal={proximidadModal}
					id="modal-proximidad-ubicacion"
				/>
			</>
		);
	}


};

export default SeleccionarDomicilio;