import React, { useEffect, useState, useContext } from 'react'
import { IonContent, IonGrid, IonPage, IonRow, IonCol, IonFooter, IonToolbar } from '@ionic/react';
import { Button, Titulo, RadioButtonGroup, Modal, TextLink } from 'app-principal-componentes-visuales';
import DetalleAtencion from '../../components/Modals/DetalleAtencion/DetalleAtencion';
import { CasoUrgencias, CuestionarioCaso, InfoCaso } from '../../models/caso.model';
import { ASISTENCIA_CREADA, CABA, CANCELAR_SOLICITUD, CAPITAL, CODIGO_CANAL_ENTRADA, DELAY_REQUEST_CASOS_EN_CURSO, FIRMA_SALUS, HOME, PREFIJO_MOTIVO, RESPUESTA_SALUS_AFIRMATIVA, RESPUESTA_SALUS_NEGATIVA, PEDIDO_SERVICIO, FEEDBACK_SERVICIO_EN_CURSO } from '../../utils/constants';
import { formatearTelefono, listarSintomas } from '../../utils/utils';
import { Direccion } from '../../interfaces/direccion.interface';
import { saveCaso, sendCasoSalus } from '../../api/casoService';
import { AsistenciaMedicaContext } from '../../contexts/AsistenciaMedicaContext';
import './PedidoServicio.scss';
import { ClasificacionModel } from '../../interfaces/clasificacion.interface';
import ErrorPage from '../ErrorPage/ErrorPage';
import { useLocation } from 'react-router';
import SessionHeader from '../../components/Headers/SessionHeader/SessionHeader';
import Footer from '../../components/Footer/Footer';
import PageWrapper from '../../components/PageWrapper/PageWrapper';

const PedidoServicio = ({ history }) => {
    
    const { disponibilidad, clasificaciones, coseguro } = history.location.state;
    const { useRequest, asistenciaMedica, storageManager, isWeb } = useContext(AsistenciaMedicaContext);
    const { apiRequest } = useRequest();
    const [pending, setPending] = useState(false);
    const [showVerDetalleModal, setShowVerDetalleModal] = useState(false);
    const [servicios, setServicios] = useState([])
    const [casoRequest, setCasoRequest] = useState(null);
    const [visitaMedicaDomicilio, setVisitaMedicaDomicilio] = useState(false);
    const [error, setError] = useState(false)
    const socioSeleccionado = asistenciaMedica.socioSeleccionado;
    const direccionSeleccionada = asistenciaMedica.direccionSeleccionada;
    const sintomas = asistenciaMedica.datosParaTriage.sintomasSeleccionados;
    const preguntas = asistenciaMedica.datosParaTriage.preguntasRespondidas;
    const observacion = asistenciaMedica.datosParaTriage.observaciones;

    let sintomasSeleccionado;
    let clasificacionSeleccionada;
    let direccion: Direccion;
    let infoCaso = new InfoCaso();

    /**
     * se arma array con los servicios a mostrar, que le llegan de la pantalla anterior en clasificaciones, para el componente radioButtonGroup.
     */
    useEffect(() => {
        clasificacionSeleccionada = clasificaciones[0];
        sintomasSeleccionado = sintomas.concat(listarSintomas(sintomas));
        cargarDatosCasoSalus();
        const filterClasificaciones = clasificaciones.slice(0,1);
        const serviciosAux = filterClasificaciones.map((clasificacion: ClasificacionModel) => {
            return {
                descripcionSolicitud: clasificacion.clasificacionDetalle.descripcionSolicitud,
                detalleListado: clasificacion.clasificacionDetalle.detalleListado,
                tituloSolicitud: clasificacion.clasificacionDetalle.tituloSolicitud,
                servicio: clasificacion.servicio,
                descripcion: clasificacion.descripcion
            }
        })

        const serviciosRadioButton = serviciosAux.map((servicio: any, index) => {
            let comentario;
            if(servicio.descripcion == 'CV'){
                comentario = 'El servicio puede presentar demoras adicionales entre las 22 y las 05 hs.'
            } else if (servicio.descripcion == 'HCA' || servicio.descripcion == 'HCD'){
                comentario = 'Uno de nuestros prestadores se estará comunicando dentro de las 24 hs para otorgarte un turno.'
            } else if (servicio.descripcion == 'CMO' || servicio.descripcion == 'GEND' || servicio.descripcion == 'DEND'){
                comentario = 'Nuestros profesionales pueden diagnosticar, recetar medicamentos y realizar constancias de atención y de alta.'
            }
            
            let iconWater = servicio.descripcion === 'HCA' || servicio.descripcion === 'HCD';
            let iconComputer = servicio.descripcion === 'CMO' || servicio.descripcion === 'GEND' || servicio.descripcion === 'COVD' || servicio.descripcion === 'DEND';
            let iconHouse = servicio.descripcion === 'CV';
            // Se comenta momentaneamente hasta corregir la descripcion del servicio para GEND y COVD
            // let iconClipBoard = servicio.descripcion === 'GEND' || servicio.descripcion === 'COVD';
            let iconMedico = servicio.descripcion === '';

            if (coseguro.coseguroValorFinal > 0) {
                return {
                    iconWater: iconWater,
                    iconComputer: iconComputer,
                    iconHouse: iconHouse,
                    iconMedico: iconMedico,
                    onclick: verDetalleHandler,
                    onClickVerDetalle: () => verDetalleHandler(index),
                    tipoTramite: servicio.tituloSolicitud,
                    value: index.toString(),
                    coseguro: `Coseguro $${coseguro.coseguroValorFinal}`,
                    verDetalle: true,
                    comentario: comentario
                }
            } else {
                return {
                    iconWater: iconWater,
                    iconComputer: iconComputer,
                    iconHouse: iconHouse,
                    iconMedico: iconMedico,
                    onclick: verDetalleHandler,
                    onClickVerDetalle: () => verDetalleHandler(index),
                    tipoTramite: servicio.tituloSolicitud,
                    value: index.toString(),
                    verDetalle: servicio.descripcion === 'CV',
                    comentario: comentario
                }
            }
        })

        setServicios(serviciosRadioButton);
    }, [])

    /**
     * metodo que se encarga de crear un caso urgencias para solicitar el caso
     */
    const cargarDatosCasoSalus = () => {

        let caso = new CasoUrgencias;
        caso.codigoCanalEntrada = CODIGO_CANAL_ENTRADA;
        caso.firma = FIRMA_SALUS;
        //Datos contacto
        caso.contacto.celular = formatearTelefono(socioSeleccionado.telefono);
        caso.contacto.telefonollamante = formatearTelefono(socioSeleccionado.telefono);
        caso.contacto.numeroic = socioSeleccionado.ic;
        caso.contacto.numerosocio = socioSeleccionado.nroContrato;
        caso.contacto.apellidos = socioSeleccionado.apellidos.toUpperCase();
        caso.contacto.nombre = socioSeleccionado.nombre.toUpperCase();
        caso.contacto.documento = socioSeleccionado.dni;
        caso.contacto.email = socioSeleccionado.email;
        let prefijomotivo = PREFIJO_MOTIVO;
        let motivo = prefijomotivo.concat(sintomasSeleccionado[sintomasSeleccionado.length - 1]);
        caso.contacto.motivo = motivo;
        //Datos Ubicacion
        direccion = direccionSeleccionada;
        caso.contacto.calle = direccion.calle.toUpperCase();
        caso.contacto.numero = direccion.altura;
        if (direccion.entreCalleUno) {
            caso.contacto.entrecallesinicio = direccion.entreCalleUno.toUpperCase();
        }
        if (direccion.entreCalleDos) {
            caso.contacto.entrecallesfin = direccion.entreCalleDos.toUpperCase();
        }
        caso.contacto.provincia = direccion.provincia.toUpperCase() === CABA ? CAPITAL : direccion.provincia.toUpperCase();
        caso.contacto.localidad = direccion.localidad.toUpperCase();
        caso.contacto.barrio = direccion.barrio.toUpperCase();
        caso.contacto.piso = direccion.piso;
        caso.contacto.departamento = direccion.departamento;
        caso.contacto.latitud = direccion.latitud;
        caso.contacto.longitud = direccion.longitud;
        caso.contacto.observaciones = direccion.observaciones;
        caso.contacto.posicionexacta = direccion.posicionExacta;

        caso.contacto.codigoSubtipoServicio = clasificacionSeleccionada.descripcion;
        caso.contacto.codigoTipoServicio = clasificacionSeleccionada.servicio.descripcion;

        if (observacion) {
            caso.sintomas.comentario = observacion
        }

        let respuestaSalus = [];
        preguntas.forEach((pregunta, index) => {
            let cuestionario = new CuestionarioCaso();

            if (pregunta.respuesta === 'si') {
                respuestaSalus[index] = RESPUESTA_SALUS_AFIRMATIVA;
            } else {
                respuestaSalus[index] = RESPUESTA_SALUS_NEGATIVA;
            }
            cuestionario.pregunta = pregunta.descripcion;
            cuestionario.respuesta = respuestaSalus[index];
            caso.sintomas.cuestionario.push(cuestionario);
        });

        setCasoRequest(caso);
    }

    /**
     * metodo que se ejectua al presionar continuar, realizando la llamada a servicio sendCasoSalus, manejo de errores y persistir en la base de datos
     */
    const botonContinuarHandler = async () => {
        setPending(true);
        
        try {
            let aux = casoRequest;
            aux.contacto.provincia = aux.contacto.provincia === CABA ? CAPITAL : aux.contacto.provincia;
            const sendCasoResponse = await sendCasoSalus(aux, apiRequest);
            
            if (sendCasoResponse.status === 200) {
                persistirEnBD(sendCasoResponse);
                const payload = {
                    socio: socioSeleccionado,
                    asistenciaValida: true,
                    sendCasoResponse: sendCasoResponse.data
                }
                
                let fechaActual = new Date();
                fechaActual.setMinutes(fechaActual.getMinutes() - DELAY_REQUEST_CASOS_EN_CURSO);
                storageManager.setDataStorage("fechaRequestCasosEnCurso", fechaActual.toString());
                history.push(ASISTENCIA_CREADA, payload);
            }

        } catch (error) {
            let statusError = error.response.status;

            if (statusError === 412) {
                const payload = {
                    socio: socioSeleccionado
                }
                history.push(FEEDBACK_SERVICIO_EN_CURSO, payload);
            } else {
                setError(true)
            }

        } finally {
            setPending(false)
        }
    }

    /**
     * metodo que se encarga de crear un objeto infoCaso y guardarlo en la base de datos
     */
    const persistirEnBD = async (sendCasoResponse) => {

        let socioPrincipal = asistenciaMedica?.usuario;

        infoCaso.principal = socioPrincipal;
        infoCaso.sintomas = listarSintomas(sintomas);
        infoCaso.observacionSintoma = observacion;

        let direccion = direccionSeleccionada;
        let direccionString = `${direccion.calle} ${direccion.altura}${direccion.barrio ? ", " + direccion.barrio + ", " : ", "} ${direccion.localidad}, ${direccion.provincia}`;
        infoCaso.domicilio = direccionString;

        if (compararDosSocios(socioSeleccionado, socioPrincipal)) {
            infoCaso.email = socioPrincipal.email;
            infoCaso.telefono = socioPrincipal.telefono;
        } else {
            infoCaso.familiar = socioSeleccionado;
            infoCaso.email = socioSeleccionado.email;
            infoCaso.telefono = socioSeleccionado.telefono;
        }
        if (sendCasoResponse && sendCasoResponse.data && sendCasoResponse.data.servicio) {
            infoCaso.nroCaso = sendCasoResponse.data.servicio.codigo;
            infoCaso.codigoContacto = sendCasoResponse.data.servicio.codigocontacto;
            infoCaso.codigoTipoServicio = sendCasoResponse.data.servicio.codigotiposervicio;
            infoCaso.codigoSubTipoServicio = sendCasoResponse.data.servicio.codigosubtiposervicio;
            infoCaso.estado = sendCasoResponse.data.servicio.estado;
            let fechaCreacionToString = sendCasoResponse.data.servicio.horacreacion.toString().slice(0, 19);
            let fechaCreacion = fechaCreacionToString.concat('.000+00:00').replace(/ /g, "T");
            infoCaso.fechaCreacion = new Date(fechaCreacion);
        }

        let contratoActivo = await JSON.parse(localStorage.getItem('contratoActivo'));

        if (contratoActivo) {
            infoCaso.contrato = socioSeleccionado.nroContrato;
            infoCaso.fechaInicioContrato = contratoActivo.fechaInicioContrato;
            infoCaso.fechaFinContrato = contratoActivo.fechaFinContrato;
            infoCaso.fechaInicioVigencia = contratoActivo.fechaInicioVigencia;
            infoCaso.fechaFinVigencia = contratoActivo.fechaFinVigencia;
            infoCaso.estadoContrato = contratoActivo.estado;
        }

        try {
            const persistirEnBDResponse = await saveCaso(infoCaso, apiRequest);
            if (persistirEnBDResponse.status === 200) {
                console.info(`Caso guardado correctamente en la db. `);
            }
        } catch (error) {
            setError(true)
        }
    }

    /**
     * Compara los datos basico del socio para comprobar que no se trate del mismo.
     */
    const compararDosSocios = (socioUno, socioDos) => {
        return socioUno.nombre === socioDos.nombre &&
            socioUno.apellidos === socioDos.apellidos &&
            socioUno.id === socioDos.id;
    }

    /**
     * metodo para abrir el modal de ver detalle atencion
     */
    const verDetalleHandler = (index) => {
        const tipoServicio = clasificaciones.filter((servicio) => servicio.descripcion === 'CV')
        if (tipoServicio.length > 0) {
            setVisitaMedicaDomicilio(true);
        }
        setShowVerDetalleModal(true);
    }

    /**
     * metodo que se ejectua al presionar cancelar
     */
    const cancelarHandler = () => {
        history.push(CANCELAR_SOLICITUD,  { from: history.location.pathname})
    }

    if (error) {
        return <ErrorPage setError={setError} />
    }

    return (
        <>
            <PageWrapper
                className='verificar-datos-grid'
                pageClassName={ isWeb ? 'asistencia__pedidoServicio' : 'asistencia__pedidoServicio asistencia__pedidoServicio-mobile'}
                socio={asistenciaMedica.usuario} 
                path={PEDIDO_SERVICIO}
            >
                <div>
                    <IonRow className='pedido-servicio--row'>
                        <IonCol size='12'>
                            <Titulo
                                className='pedidoServicio__title'
                                titulo={'De acuerdo con los síntomas informados, podemos ofrecerte:'}
                                id="asistencia-pedido-servicio"
                            />
                        </IonCol>
                    </IonRow>

                    <RadioButtonGroup
                        asistencia
                        id="asistencia-radio-button"
                        onClick={() => { }}
                        radioList={servicios}
                        titulo=""
                        valueSelected="0"
                    />
                </div>
                <IonToolbar className='ion-no-border'>
                    <TextLink
                        id="pedido-servicio-cancelar"
                        onClick={cancelarHandler}
                        texto="Cancelar"
                        txt14
                        className='cancelar__button'
                    />
                    <Button
                        id='pedido-servicio-solicitar-asistencia'
                        className='footer__button'
                        primary
                        label='Solicitar la asistencia'
                        onClick={botonContinuarHandler}
                        waiting={pending}
                    />
                </IonToolbar>
            </PageWrapper>
            <Modal
                className='modal_web'
                hideCloseBtn={false}
                //@ts-ignore
                component={
                    <DetalleAtencion
                        excepcionCoseguro={0}
                        coseguro={coseguro}
                        visitaMedicaDomicilio={visitaMedicaDomicilio}
                        codigoSeguridad={false}
                    />}
                setShowModal={setShowVerDetalleModal}
                showModal={showVerDetalleModal}
                id="detalle-atencion-modal"
            />
        </>
    )
}

export default PedidoServicio;
