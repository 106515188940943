import { AxiosResponse } from "axios";
import { BACKEND_ENDPOINT, CONTROL_VERDES } from "../utils/constants";

export const getControlVerdes = (apiRequest: any) => {
    return new Promise<AxiosResponse>((resolve, reject) => {
        const url = BACKEND_ENDPOINT + CONTROL_VERDES;
        const method = 'GET';
        const options = {
            method,
            url
        }
        apiRequest(options)
        .then((response : AxiosResponse) => resolve(response.data))
        .catch((error : any) => reject(error));
    });
}