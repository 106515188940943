import { Button, CardMensajeAccion, MensajeConSpinner } from 'app-principal-componentes-visuales';
import React, { useContext, useEffect, useState } from 'react';
import { FINISHED_SIGLA, SELECCIONAR_SOCIO } from '../../../utils/constants';
import { Socio } from '../../../models/socioPrincipal.model';
import './SolicitarAsistencia.scss';
import { AsistenciaMedicaContext } from '../../../contexts/AsistenciaMedicaContext';
import { useHistory  } from 'react-router';
interface SolicitarAsistenciaProps {
	socioPrincipal: Socio,
    requestServicioEnCurso: boolean,
    openModal: () => void,
    handleServiciosEnCurso: () => void
}

const SolicitarAsistencia = ({ socioPrincipal, requestServicioEnCurso, openModal, handleServiciosEnCurso }: SolicitarAsistenciaProps) => {

    const history = useHistory()
    const { asistenciaMedica } = useContext(AsistenciaMedicaContext);
    const { asistenciasFinalizadas } = asistenciaMedica
    const serviciosEnCurso = asistenciaMedica.asistenciasSolicitadas !== undefined ? asistenciaMedica.asistenciasSolicitadas : [];
    const [serviciosLength, setServiciosLength] = useState(serviciosEnCurso)
    
    useEffect(() => {
        let length = serviciosEnCurso.filter(servicio => servicio.estado !== FINISHED_SIGLA);
        setServiciosLength(length);
    }, [serviciosEnCurso, serviciosEnCurso.length])
    
    /**
     * comienza los pasos para solicitar una asistencia
     */
    const solicitarAsistencia = () => {
        history.push(SELECCIONAR_SOCIO)
    }

    // /**
    //  * En caso de no haber aceptado terminos y condiciones abre su respectivo modal, sino comienza los pasos para solicitar una asistencia
    //  * se comenta para web 
    //  */
    // const solicitarAsistencia = () => {
    //     if(!socioPrincipal.terms) {
    //         openModal()
    //     } else {
    //         history.push(SELECCIONAR_SOCIO)
    //     }
    // }

    return (
            <> 
                { requestServicioEnCurso ? 
                    <>
                        <MensajeConSpinner spinner titulo='Estamos verificando si existen asistencias en curso...'
                            descripcion='Esta acción puede tardar unos minutos.' 
                            id='solicitar-asistencia-request-servicio-en-curso'    
                        />
                        
                        <Button
                            asist
                            btnLink
                            id="asistencia-medica-boton-nueva-asistencia"
                            label="Solicitar otra asistencia"
                            plus
                            onClick={solicitarAsistencia}
                            className='btn-solicitar-otra-asistencia'
                        />
                    </>
                : (
                    <>
                        { serviciosEnCurso?.length === 0 ?
                            <div className={ asistenciasFinalizadas.length > 0 ? 'asistencia-medica-card-solicitar-asistencia' : ''}> 
                                <CardMensajeAccion
                                    btnTexto="Iniciar solicitud"
                                    id="solicitar-asistencia"
                                    onClick={solicitarAsistencia}
                                    texto="Solicitá tu asistencia médica con nuestros profesionales directamente desde la aplicación."
                                    titulo="¿Necesitás una asistencia médica de urgencias?"
                                />
                            </div>
                        :
                            <>
                                <CardMensajeAccion
                                    btnTexto="Ver detalle"
                                    cantidad={ serviciosLength.length }
                                    enCurso
                                    id="asistencia-medica-servicios-en-curso"
                                    onClick={handleServiciosEnCurso}
                                    titulo="¡Tenés una asistencia en curso!"
                                />

                                <Button
                                    asist
                                    btnLink
                                    id="asistencia-medica-boton-nueva-asistencia"
                                    label="Solicitar otra asistencia"
                                    plus
                                    onClick={solicitarAsistencia}
                                    className='btn-solicitar-otra-asistencia'
                                />
                            </>
                        }
                    </>    
                )}
            </> 
        )
}
export default SolicitarAsistencia