import { IonCol, IonContent, IonGrid, IonPage, IonRow } from '@ionic/react'
import { Button, Subtitulo, Titulo } from 'app-principal-componentes-visuales'
import React, { useContext, useEffect } from 'react'
import { LABEL_RIESGO, LABEL_WHATSAPP, TELEFONO_EMERGENCIAS, TELEFONO_URGENCIAS_DOS, TELEFONO_URGENCIAS_UNO, TELEFONO_WHATSAPP } from '../../utils/constants'
import { formatearNumeroBoton } from '../../utils/utils'
import AsistenciaHeader from '../../components/Headers/AsistenciaHeader/AsistenciaHeader'
import { GA_KEYS, mapGA } from '../../helpers/GTM'
import { AsistenciaMedicaContext } from '../../contexts/AsistenciaMedicaContext'
import './TelefonosPage.scss'

const TelefonosPage = ( {history} ) => {
    let state = history.location.state;
    const { setupData } = useContext(AsistenciaMedicaContext);

    useEffect(() => {
        if(state?.screen){
            const configGA = {
                salida_motivo: state.screen
            }
            mapGA(GA_KEYS.TELEFONOS, setupData, configGA)
        }
    }, [])
    

  return (
    <IonPage>
        
		<AsistenciaHeader titulo={"Contactanos"} showBackButton showCancelPage={false}/>
        <IonContent fullscreen>
        <IonGrid className='telefonos__page content__grid asistencia_content-container'>
                    <IonRow>
                        <IonCol size='12'>
                            <Titulo titulo="Para brindarte una mejor asistencia, será necesario que te contactes directamente con nosotros." id="titulo-asistencia-telefono-page"/>
                        </IonCol>
                    </IonRow>
                {/* {!menorSeisMeses  && 
                    <IonRow>
                        <IonCol size='12'>
                            <Button
                                href={`whatsapp://send?phone=+54${TELEFONO_WHATSAPP}`}
                                label={LABEL_WHATSAPP}
                                whatsapp
                            />
                        </IonCol>
                    </IonRow>
                } */}
                {/* {servicioEnCurso && 
                    <IonRow>
                        <IonCol size='12'>
                            <Button
                                id='whatsapp-contactanos-button'
                                href={ `whatsapp://send?phone=+54${TELEFONO_WHATSAPP}` }
                                label={LABEL_WHATSAPP}
                                whatsapp
                            />
                        </IonCol>
                    </IonRow>
                } */}
                <IonRow className={ 'contactanos-row' }>
                    <IonCol size='12'>
                        <Button
                            id='urgencias-uno-button'
                            href={`tel:${TELEFONO_URGENCIAS_UNO}`}
                            label={formatearNumeroBoton(TELEFONO_URGENCIAS_UNO!)}
                            phone
                        />
                    </IonCol>
                </IonRow>
                <IonRow className={ 'contactanos-row' }>
                    <IonCol size='12'>
                        <Button
                            id='urgencias-2-button'
                            href={`tel:${TELEFONO_URGENCIAS_DOS}`}
                            label={formatearNumeroBoton(TELEFONO_URGENCIAS_DOS!)}
                            phone
                        />
                    </IonCol>
                </IonRow>
                    <IonRow className={ 'contactanos-row' }>
                        <IonCol size='12'>
                            <Subtitulo
                                titulo={LABEL_RIESGO}
                                txtRed
                                id="subtitulo-riesgo"
                            />
                            <Button
                                colorRed
                                id='Riesgo-button'
                                href={`tel:${TELEFONO_EMERGENCIAS}`}
                                label={formatearNumeroBoton(TELEFONO_EMERGENCIAS!)}
                                primary
                            />
                        </IonCol>
                    </IonRow>
            </IonGrid>
        </IonContent>
    </IonPage>
  )
}

export default TelefonosPage