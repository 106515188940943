import { AxiosResponse } from "axios";
import { Socio } from "../models/socioPrincipal.model";
import { BACKEND_ENDPOINT, SOCIO_PRINCIPAL } from "../utils/constants";

export const getSocioPrincipalByIc = (interlocutorComercial: number, apiRequest: any) => {
    return new Promise<AxiosResponse>((resolve, reject) => {
        const url = BACKEND_ENDPOINT + `${SOCIO_PRINCIPAL}/${interlocutorComercial}`;
        const method = "GET";
        const options = {
            url,
            method 
        }
        apiRequest(options)
            .then((response : AxiosResponse) => resolve(response))
            .catch((error : any) => reject(error));
    });
}

export const saveSocioPrincipal = (socio: Socio, apiRequest: any) => {
    return new Promise<AxiosResponse>((resolve, reject) => {
        const url = BACKEND_ENDPOINT + SOCIO_PRINCIPAL;
        const method = 'POST';
        const data = socio;
        const options = {
            url,
            method,
            data
        }
        apiRequest(options)
            .then((response: AxiosResponse) => resolve(response))
            .catch((error : any) => reject(error));
    });
}

export const updateSocioPrincipal = (socio: Socio, apiRequest: any) => {
    return new Promise<AxiosResponse>((resolve, reject) => {
        const url = BACKEND_ENDPOINT + SOCIO_PRINCIPAL;
        const method = 'PUT';
        const data = socio;
        const options = {
            url,
            method,
            data
        }
        apiRequest(options)
            .then((response: AxiosResponse) => resolve(response))
            .catch((error : any) => reject(error));
    });
}
