import { getFactores, getSintomas } from "../api/asistenciaMedicaService";
import { getCanalFromBackend } from "../api/canalService";
import { getProvincias } from "../api/direccionService";
import { getServicioFromBackend } from "../api/servicio";
import { HttpRequestOptions } from "../interfaces/ads/http.request.options.interface";
import { StorageManager } from "../interfaces/ads/storage.manager.interface";
import { Canal } from "../interfaces/canal.interface";
import { ClasificacionModel } from "../interfaces/clasificacion.interface";
import { Factor } from "../interfaces/factor.interface";
import { Provincia } from "../interfaces/provincia.interface";
import { Servicio } from "../interfaces/servicio.interface";
import { Sintoma } from "../interfaces/sintoma.interface";

// TODO: Probar flujo con Context no inicializado.

/** Metodo que obtiene las provincias del storage.
 *  En caso de que no encuentre las provincias dentro del mismo
 *  llama al servicio para obtenerlas y posteriormente guardarlas.
 */
export const obtenerProvinciasStorage = (
    apiRequest: ( HttpRequestOptions ) => Promise<any>, 
    storageManager: StorageManager
    ): Promise<Provincia[]> => {

    if ( !storageManager ) {
        return Promise.reject("No se proporcionó ningun valor para storageManager");
    }

    return new Promise<Provincia[]>((resolve,reject) => {
        storageManager.getDataStorage("provincias").then( provinciasFromStorage => {
            if ( provinciasFromStorage !== null ) {
                resolve(JSON.parse(provinciasFromStorage));
            } else {
                getProvincias(apiRequest)
                .then( provincias => {
                    storageManager.setDataStorage("provincias", JSON.stringify(provincias))
                    .then( value => {
                        resolve(provincias);
                    });
                })
            }
        }).catch( error => { reject(error) });
    })
}

/** Metodo que obtiene los sintomas del storage.
 *  En caso de que no encuentre los sintomas dentro del mismo
 *  llama al servicio para obtenerlas y posteriormente guardarlos.
 */
export const obtenerSintomasStorage = (
    apiRequest: ( HttpRequestOptions ) => Promise<any>,
    storageManager: StorageManager
    ): Promise<Sintoma[]> => {

    if ( !storageManager ) {
        return Promise.reject("No se proporcionó ningun valor para storageManager");
    }

    return new Promise<Sintoma[]>((resolve,reject) => {
        storageManager.getDataStorage("sintomas").then( sintomasFromStorage => {
            if ( sintomasFromStorage !== null ) {
                resolve(JSON.parse(sintomasFromStorage));
            } else {
                getSintomas(apiRequest)
                .then( sintomas => {
                    storageManager.setDataStorage("sintomas", JSON.stringify(sintomas))
                    .then( value => {
                        resolve(sintomas);
                    });
                })
            }
        }).catch( error => { reject(error) });
    })
}

/** Metodo que obtiene los factores del storage.
 *  En caso de que no encuentre los factores dentro del mismo
 *  llama al servicio para obtenerlas y posteriormente guardarlos.
 */
export const obtenerFactoresStorage = (
    apiRequest: ( HttpRequestOptions ) => Promise<any>,
    storageManager: StorageManager
    ): Promise<Factor[]> => {
        
    if ( !storageManager ) {
        return Promise.reject("No se proporcionó ningun valor para storageManager");
    }

    return new Promise<Factor[]>((resolve,reject) => {
        storageManager.getDataStorage("factores").then( factoresFromStorage => {
            if ( factoresFromStorage !== null ) {
                resolve(JSON.parse(factoresFromStorage));
            } else {
                getFactores(apiRequest)
                .then( factores => {
                    storageManager.setDataStorage("factores", JSON.stringify(factores))
                    .then( value => {
                        resolve(factores);
                    });
                })
            }
        }).catch( error => { reject(error) });
    })
}

/** Metodo que obtiene los servicios del storage.
 *  En caso de que no encuentre los servicios dentro del mismo
 *  llama al servicio para obtenerlas y posteriormente guardarlos.
 */
export const obtenerServicios = (
            apiRequest: ( HttpRequestOptions ) => Promise<any>,
            storageManager: StorageManager) => {

    if ( !storageManager ) {
        return Promise.reject("No se proporcionó ningun valor para storageManager");
    }

    return new Promise<ClasificacionModel[]>((resolve,reject) => {
        let clasificaciones: ClasificacionModel[] = [];
        let serviciosStorage = [];
        
        storageManager.getDataStorage("clasificaciones").then( clasificacionesFromStorage => {
            if ( clasificacionesFromStorage !== null ) {
                resolve(JSON.parse(clasificacionesFromStorage));
            } else {
                getServicioFromBackend(apiRequest)
                .then((servicios: Servicio[]) => {
                    servicios.forEach((servicio) => {
                        servicio.clasificacion.forEach((clasificacion) => {
                            clasificaciones.push(clasificacion);
                        });
                        let servicioStorage = {
                            descripcion: servicio.descripcion,
                            visibleEnCurso: servicio.visibleEnCurso,
                        };
                        serviciosStorage.push(servicioStorage);
                    });
                    storageManager.setDataStorage("servicios", JSON.stringify(serviciosStorage))
                    .then(() => { 
                        storageManager.setDataStorage("clasificaciones", JSON.stringify(clasificaciones))
                        .then(() => {
                            resolve(clasificaciones);
                        });
                    })
                })
            }
        }).catch( error => { reject(error) });
    }
)}

/** Metodo que obtiene los factores del storage.
 *  En caso de que no encuentre los factores dentro del mismo
 *  llama al servicio para obtenerlas y posteriormente guardarlos.
 */
export const obtenerCanalesStorage = (
    apiRequest: ( HttpRequestOptions ) => Promise<any>,
    storageManager: StorageManager
    ): Promise<Canal[]> => {
        
    if ( !storageManager ) {
        return Promise.reject("No se proporcionó ningun valor para storageManager");
    }

    return new Promise<Canal[]>((resolve,reject) => {
        storageManager.getDataStorage("canales").then( canalesFromStorage => {
            if ( canalesFromStorage !== null ) {
                resolve(JSON.parse(canalesFromStorage));
            } else {
                getCanalFromBackend(apiRequest)
                .then( canales => {
                    storageManager.setDataStorage("canales", JSON.stringify(canales))
                    .then( value => {
                        resolve(canales);
                    });
                })
            }
        }).catch( error => { reject(error) });
    })
}

/**
 * Si existieran casos en curso cargados en el local storage
 * esta funcion los elimina.
 */
export function eliminarCasosEnCurso(storageManager) {
    storageManager.removeDataKeyFromStorage("casosEnCurso");
}
 
export function eliminarCasosYFechaDeCasosEnCurso(storageManager) {
    storageManager.removeDataKeyFromStorage("casosEnCurso");
    storageManager.removeDataKeyFromStorage("fechaRequestCasosEnCurso");
}