import { IonCol, IonContent, IonFooter, IonGrid, IonHeader, IonPage, IonRow, IonToolbar } from '@ionic/react';
import { Button, TextArea, Titulo } from 'app-principal-componentes-visuales';
import React, { useState } from 'react';
import { textIsValid } from '../../../utils/utils';
import './ObservacionesAdicionales.scss';

const ObservacionesAdicionales = ({ setShowObservacionModal, observacionText, setObservacionText, changeTextHandler }) => {

    const [observacionTextAux, setObservacionTextAux] = useState(observacionText);

    const textChangeHandler = (e) => {
        const isValid = textIsValid(e.detail.value)

        if(isValid){
            changeTextHandler(e.detail.value)
            setObservacionTextAux(e.detail.value)
        }
    }

    const botonAgregarHandler = () => {
        setObservacionText(observacionTextAux)
        setShowObservacionModal(false)
    }

    return (
        <IonPage className='asistencia-medica asistencia-observaciones-adicionales'>
            <IonHeader className='ion-no-border modal__header'>
                <IonGrid>
                    <IonRow>
                        <IonCol size="12" className="titulo">
                            <Titulo titulo="Observaciones adicionales" id="titulo-asistencia-observaciones" />
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonHeader>

            <IonContent>
                <IonGrid className='modal__grid'>
                    <IonRow>
                        <IonCol>
                            <TextArea
                                label="Observaciones adicionales"
                                maxLength={101}
                                onIonChange={textChangeHandler}
                                placeholder="Ingresá otros síntomas u observaciones"
                                value={observacionTextAux}
                                id="text-area-observaciones-adicionales"
                            />
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
            <IonFooter className='footer__button ion-no-border'>
                <IonToolbar>
                    <Button
                        className=''
                        primary
                        label='Agregar'
                        onClick={botonAgregarHandler}
                        disable={observacionTextAux.length < 3 || observacionTextAux.length > 100 ? true : false}
                    />
                </IonToolbar>
            </IonFooter>
        </IonPage>

    )
}

export default ObservacionesAdicionales;