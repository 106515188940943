export enum GA_KEYS {
	SINTOMAS_SCREEN_ACTION = "sintomas_screen_action",
	ANTECEDENTES_SCREEN_ACTION = "antecedentes_screen_action",
	PREGUNTAS_SCREEN_ACTION = "preguntas_screen_action",
	NUEVO_CASO_ASISTENCIA = "nuevo_caso_asistencia",
	TELEFONOS = "telefonos",
	ERROR = "error",
}

export const dataGA = {}

export const mapGA = (key: GA_KEYS, setupData: any, configGA?: any) => {
	configGA['producto'] = 'asistencia-medica'
	switch (key) {
		case GA_KEYS.SINTOMAS_SCREEN_ACTION:
			configGA["event"] = "sintomas";
			dataGA['sintomas_ids'] = configGA.sintomas
			sendEventActionGA(setupData, configGA);
			break;
		case GA_KEYS.ANTECEDENTES_SCREEN_ACTION:
			configGA["event"] = "antecedentes";
			dataGA['antecedentes_ids'] = configGA.antecedentes
			sendEventActionGA(setupData, configGA);
			break;
		case GA_KEYS.PREGUNTAS_SCREEN_ACTION:
			configGA["event"] = "triage";
			dataGA['triage_respuestas'] = configGA.triage_respuestas
			sendEventActionGA(setupData, configGA);
			break;
		case GA_KEYS.NUEVO_CASO_ASISTENCIA:
			configGA["event"] = "nuevo_caso_asistencia";
			sendEventActionGA(setupData, {...configGA, ...dataGA});
			break;
		case GA_KEYS.TELEFONOS:
			configGA["event"] = "salida_urgencias";
			sendEventActionGA(setupData, configGA)
			break
		case GA_KEYS.ERROR:
			configGA["event"] = "screen_view";
			sendEventActionGA(setupData, configGA)
			break
		default:
			assertNever(key);
			break;
	}
};

const assertNever = (assertNever) => {
	console.log("NOTI > mapGA >assertNever");
};

const sendEventFeedbackGA = (setupData: any, configGTM: any) => {
	configGTM["event"] = setupData.parametros.GA.event.FEEDBACK;
	sendGA(setupData, configGTM);
};

const sendEventViewGA = (setupData: any, configGTM: any) => {
	configGTM["event"] = setupData.parametros.GA.event.SCREEN;
	sendGA(setupData, configGTM);
};

const sendEventActionGA = (setupData: any, configGTM: any) => {
	sendGA(setupData, configGTM);
};

const sendGA = (setupData: any, configGA: any) => {
	try {

		const dataLayer = {
			...setupData.tagManagerArgs.dataLayer,
			content: 'asistenciamedica',
			...configGA,
		}

		setupData.dispatchGTM(dataLayer);
		
	} catch (error) {
		console.error("ERROR EN ASISTENCIA - SENDGA", error);
	}
};

