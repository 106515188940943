import { ApiError } from "../interfaces/api.error.interface";
import { AsistenciaMedicaActionType } from "../interfaces/global-state/asistencia.medica.action.type.enum";
import { Socio } from "../models/socioPrincipal.model";
import { formatearError } from "../utils/apiUtils";
import { obtenerDatosSocio } from "./socioPrincipal";
import { obtenerProvinciasStorage, obtenerFactoresStorage, obtenerSintomasStorage, obtenerCanalesStorage, obtenerServicios } from "./storageHelper";

/**
 * Metodo que reintenta todas las solicitudes iniciales necesarias para el funcionamiento de asistencia medica
 */
export const reintentarSolicitudesInicio = (user, apiRequest, dispatchAsistenciaMedica, storageManager) => {
    return new Promise<Socio>(async (resolve, reject) => {
        let obtenerSocio = {};
        try {
            obtenerSocio = await obtenerDatosSocio( Number(user.userInfo.interlocutorComercial), apiRequest, user )
            sessionStorage.removeItem("ASIS_MED_INIT_ERROR");
            dispatchAsistenciaMedica({
                type: AsistenciaMedicaActionType.actualizarUsuario, 
                socio: obtenerSocio
            });
        } catch(error: any) {
            let errorAlInicializar: ApiError = formatearError(error);
            sessionStorage.setItem("ASIS_MED_INIT_ERROR", JSON.stringify(errorAlInicializar));
            reject(errorAlInicializar)
        }

        try {
            const servicios = await Promise.all([
                obtenerProvinciasStorage( apiRequest, storageManager ),
                obtenerFactoresStorage( apiRequest, storageManager ),
                obtenerSintomasStorage( apiRequest, storageManager ),
                obtenerCanalesStorage( apiRequest, storageManager),
                obtenerServicios( apiRequest, storageManager)
            ])
            resolve(obtenerSocio);
        } catch(error: any) {
            console.error( "ASIS-MED - Ocurrio un error al inicializar los listados globales de Provincias, Factores y Sintomas.", error );
            let errorAlInicializar = formatearError(error);
            sessionStorage.setItem("ASIS_MED_INIT_ERROR", JSON.stringify(errorAlInicializar));
            reject(errorAlInicializar)
        }
    })
}
