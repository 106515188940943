import { IonCol, IonContent, IonFooter, IonGrid, IonPage, IonRow, IonToolbar } from '@ionic/react';
import { Button, Modal, Subtitulo, TextLink } from 'app-principal-componentes-visuales';
import React, { useContext, useEffect, useState } from 'react';
import { updateSocioPrincipal } from '../../api/socioPrincipalService';
import SolicitarAsistencia from '../../components/Cards/SolicitarAsistencia/SolicitarAsistencia';
import TerminosYCondiciones from '../../components/Modals/TerminosYCondiciones/TerminosYCondiciones';
import AsistenciaMedicaSkeleton from '../../components/Skeletons/AsistenciaMedicaSkeleton/AsistenciaMedicaSkeleton';
import { AsistenciaMedicaContext } from '../../contexts/AsistenciaMedicaContext';
import { Socio } from '../../models/socioPrincipal.model';
import Contactanos from '../../components/Modals/Contactanos/Contactanos';
import ErrorPage from '../ErrorPage/ErrorPage';
import { useHistory } from 'react-router';
import { AsistenciaMedicaActionType } from '../../interfaces/global-state/asistencia.medica.action.type.enum';
import { ApiError } from '../../interfaces/api.error.interface';
import { filtrarCasosEnCurso, filtrarCasosEnHistorial } from '../../helpers/serviciosEnCursoHelper';
import { CABA, CAPITAL, DELAY_REQUEST_CASOS_EN_CURSO, DETALLE_CASO, HISTORIAL_ATENCIONES, HOME, SEGUIMIENTO_VACIO, TITULO_ASISTENCIA_MEDICA } from '../../utils/constants';
import { getCasosEnCurso } from '../../api/salusService';
import HistorialCasos from '../../components/Cards/HistorialCasos/HistorialCasos';
import ErrorReintentar from '../ErrorReintentar/ErrorReintentar';
import { reintentarSolicitudesInicio } from '../../helpers/reintentarHelper';
import DeshabilitarAsistencia from '../DeshabilitarAsistencia/DeshabilitarAsistencia';
import SessionHeader from '../../components/Headers/SessionHeader/SessionHeader';
import Footer from '../../components/Footer/Footer';
import { getDeshabilitacionModuloFromBackend } from '../../api/deshabilitacionModuloService';
import { serviciosHabilitados } from '../../helpers/serviciosHelper';
import { controlDeVerdes } from '../../helpers/controlVerdesHelper';
import { cargarHistorialFinalizado } from '../../helpers/historialFinalizadoHelper';
import './AsistenciaMedica.scss';

export const AsistenciaMedica = ({ history } : {[ key: string ]: any}) => {

    let errorAlInicializar: ApiError = JSON.parse(sessionStorage.getItem("ASIS_MED_INIT_ERROR"));
    const [ showTerminosModal, setShowTerminosModal ] = useState(false);
    const [ showDireccionesModal, setDireccionesModal ] = useState(false);
    const [ showDatosContactoModal, setShowDatosContactoModal ] = useState(false);
    const [ showContactanosModal, setShowContactanosModal ] = useState(false);
    const { useRequest, asistenciaMedica, dispatchAsistenciaMedica, storageManager, user, dataControlDeVersiones, isWeb } = useContext(AsistenciaMedicaContext);
    const { apiRequest } = useRequest();
    const historyNavigate = useHistory();
    const { state }: any = historyNavigate.location;
    const stateFromAds = (state?.from === "/telefonos" || state?.from === "/homePpal" || state?.from === "/micuenta");
    const [ socioPrincipal, setSocioPrincipal ] = useState<any>(asistenciaMedica?.usuario);
    const [ asistenciaSkeleton, setAsistenciaSkeleton ] = useState(stateFromAds);
    const [ error, setError ] = useState(false);
    const [ reintentarError, setReintentarError ] = useState(false);
    const [ reintentarRequest, setReintentarRequest ] = useState(false);
    const [ requestServicio, setRequestServicio ] = useState(false);
    const [detalleCasoModal, setDetalleCasoModal] = useState(false);
    const [skeletonSeguimiento, setSkeletonSeguimiento] = useState(false);
    const asistenciaEnabled = dataControlDeVersiones?.asistencia !== undefined ? dataControlDeVersiones.asistencia.enabled : true;
    const [deshabilitarAsistencia, setDeshabilitarAsistencia] = useState(asistenciaEnabled);
    const [deshabilitarModulos, setDeshabilitarModulos] = useState({});
    // El state llamarServicios hace referencia a los llamados de servicio en inicio, luego de verificar si se encuentra deshabilitado el modulo de ADS
    const [llamarServicios, setLlamarServicios] = useState(false);

    /**
     * Encargado del apagado del modulo de asistencia medica, 
     * tomando en cuenta las deshabilitaciones del abm y del modulo principal de ads
     */
    useEffect(() => {
        if (stateFromAds) {
            getDeshabilitacionModuloFromBackend(apiRequest).then(deshabilitacion => {
                storageManager.setDataStorage("deshabilitacion", JSON.stringify(deshabilitacion));
                if(deshabilitacion?.deshabilitarAds) {
                    setDeshabilitarModulos({...deshabilitacion});
                    setDeshabilitarAsistencia(false);
                    setAsistenciaSkeleton(false);
                    setLlamarServicios(true);
                } else {
                    controlDeVersiones();
                }
            }).catch(error => {
                console.error("ASIS-MED - Ocurrio un error al realizar el request de deshabilitacion del módulo: ", error);
                controlDeVersiones();
            });
        }
	}, [stateFromAds])
    
    /**
     * Encargado del apagado del modulo de asistencia medica web
     */
    useEffect(() => {
        if (isWeb) {
            getDeshabilitacionModuloFromBackend(apiRequest).then(deshabilitacion => {
                storageManager.setDataStorage("deshabilitacion", JSON.stringify(deshabilitacion));
                if(deshabilitacion?.deshabilitarWeb) {
                    setDeshabilitarModulos({...deshabilitacion});
                    setDeshabilitarAsistencia(false);
                    setAsistenciaSkeleton(false);
                    setLlamarServicios(true);
                } 
            }).catch(error => {
                console.error("ASIS-MED - Ocurrio un error al realizar el request de deshabilitacion del módulo: ", error);
            });
        }
    }, [])
    
    useEffect(() => {
        if(deshabilitarAsistencia && !asistenciaSkeleton) {
            let errorAlInicializar: ApiError = JSON.parse(sessionStorage.getItem("ASIS_MED_INIT_ERROR"));
            if ( errorAlInicializar ) {
                console.error("ASIS-MED - Ocurrio un error al inicializar el módulo: ", errorAlInicializar);
                setReintentarError(true);
                setAsistenciaSkeleton(false);
            } else if ( socioPrincipal ) {
                setSocioPrincipal(asistenciaMedica?.usuario);
                setAsistenciaSkeleton(false);
            };
        }
    }, [asistenciaMedica, llamarServicios]);

    /**
     * Encargado de las peticiones de servicios en curso y el historial de atenciones
     */
    useEffect(() => {
        if(deshabilitarAsistencia && !asistenciaSkeleton) {
            const user = asistenciaMedica?.usuario
            if (state && user || isWeb) {
                if (stateFromAds) {
                    storageManager.removeDataKeyFromStorage('fechaRequestCasosEnCurso').then( () => {
                        solicitarServiciosEnCurso()
                    })
                } else if (state?.seguimiento) {
                    setSkeletonSeguimiento(true);
                    solicitarServiciosEnCurso()
                    .then((casos) => {
                        if(casos.length > 0) {
                            casos.sort((a, b) => new Date(b.fechaCreacion).getTime() - new Date(a.fechaCreacion).getTime())
                            let casoSeleccionado = { casoEnCursoSeleccionado: casos[0], from: HOME };
                            history.push(DETALLE_CASO, casoSeleccionado);
                        } else {
                            //se redirecciona a la page de advertencia de recuperacion de datos
                            history.push(SEGUIMIENTO_VACIO);
                        }
                    })
                    .finally(() => setSkeletonSeguimiento(false))
                } else {
                    solicitarServiciosEnCurso();
                }
            }
        }
    }, [llamarServicios])

    /**
     * Encargado de realizar el reintento de peticiones cuando no se posee los datos del socio principal
     */
    useEffect(() => {
        if(deshabilitarAsistencia && !asistenciaSkeleton) {
            if(reintentarRequest) {
                setAsistenciaSkeleton(true);
                reintentarSolicitudesInicio(user, apiRequest, dispatchAsistenciaMedica, storageManager).then(socio => {
                    setSocioPrincipal(socio);
                    setAsistenciaSkeleton(false);
                }).catch(() => {
                    setAsistenciaSkeleton(false);
                    setReintentarError(true);
                });
                setReintentarRequest(false);
            }
    }}, [reintentarRequest])
    

    const handleClose = () => {
        historyNavigate.push('/');
    }

    const openModal = () => {
        setShowTerminosModal(!showTerminosModal);
    };

    const openModalContactanos = () => {
        setShowContactanosModal(!showContactanosModal);
    };

    /**
     * Encargado de realizar el update en la base de datos con el nuevo estado del socio al aceptar terminos y condiciones
     */
    const acceptTerminosYCondiciones = () => {
        setShowTerminosModal(!showTerminosModal);
        let socio:Socio = socioPrincipal;
        socio.terms = true;
        setSocioPrincipal(socio);
        updateSocioPrincipal(socioPrincipal, apiRequest)
            .then(response => {
                dispatchAsistenciaMedica({
                    type: AsistenciaMedicaActionType.aceptarTerminos
                })
            })
            .catch(error => {
                console.error(`ASIS-MED - Hubo un error al persistir el socio principal ${error}`);
                
                setError(true)
            }
        );
    };

    /** */
    const openModalContactInfo = () => {
        setShowDatosContactoModal(true);
    };

    const openModalUbicacion = () => {
        setDireccionesModal(!showDireccionesModal);
    };

    const updateDirection = (direcciones) => {
        let socio:Socio = socioPrincipal;
        let direccionesAux = direcciones.map((direc) => {
            if (direc.provincia === CABA) {
				return ({...direc, provincia : CAPITAL})
            }
			return direc;
        })
        socio.direcciones = direccionesAux;
        setSocioPrincipal(socio);
        updateSocioPrincipal(socioPrincipal, apiRequest)
            .then(response => {
                dispatchAsistenciaMedica({
                    type: AsistenciaMedicaActionType.actualizarUsuario,
                    socio: socio
                })
            })
            .catch(error => {
                console.error(`ASIS-MED - Hubo un error al persistir el socio principal ${error}`);
                setError(true)
            }
        )
    }
    
    const handleServiciosEnCurso = () => {
        let serviciosEnCurso = { serviciosEnCurso: asistenciaMedica?.asistenciasSolicitadas };
        history.push(HISTORIAL_ATENCIONES, serviciosEnCurso);
    }

    const solicitarServiciosEnCurso = () => {
        return new Promise<any>(async (resolve,reject) => {
            let fechaRequestStorage;
            let casosFiltrados;
            let serviciosActivos;
            setRequestServicio(true);

            try {
                serviciosActivos = await serviciosHabilitados(apiRequest);
            }  catch(error: any) {
                console.error(`Hubo un error al recuperar los servicios: ${error}`);
            }

            storageManager.getDataStorage("fechaRequestCasosEnCurso").then(async fecha => {
                fechaRequestStorage = fecha;
    
                let fechaRequestCasosEnCurso = new Date(fechaRequestStorage);
                let fechaActual = new Date();
                let casosPorClasificacion;
                
                fechaRequestCasosEnCurso.setMinutes(fechaRequestCasosEnCurso.getMinutes() + DELAY_REQUEST_CASOS_EN_CURSO);
                if (fechaRequestStorage === undefined || fechaRequestCasosEnCurso.getTime() <= fechaActual.getTime()) {
                    try {
                        const casos = await getCasosEnCurso(socioPrincipal.ic, apiRequest);
                        historialAtencionesFinalizadas();
                        let fechaAct = new Date();
                        storageManager.setDataStorage("fechaRequestCasosEnCurso", fechaAct.toString());
                        if(casos?.status === 200) {
                            storageManager.setDataStorage("casosEnCurso", JSON.stringify(casos?.data));
                            const casosHistorial = await filtrarCasosEnHistorial(casos.data, storageManager);
                            casosFiltrados = await filtrarCasosEnCurso(casosHistorial);

                            dispatchAsistenciaMedica({
                                type: AsistenciaMedicaActionType.actualizarCasosEnCurso, 
                                casosEnCurso: casosFiltrados
                            });
                            
                            const socioConClasificaciones = {};
                            casosFiltrados.forEach(caso => {
                                const nombre = caso.principal.nombre;
                                if (!socioConClasificaciones[nombre]) {
                                    socioConClasificaciones[nombre] = {
                                        nombre,
                                        clasificaciones: [],
                                    };
                                }

                                socioConClasificaciones[nombre].clasificaciones.push(caso);
                            });
                            
                            casosPorClasificacion = Object.values(socioConClasificaciones);

                            resolve(casosHistorial);
                        } else {
                            serviciosEnCursoStorage();
                        }
                    } catch(error: any) {
                        console.error(`ASIS-MED - Hubo un problema asociado al llamado de servicios en curso ${error}`);
                        reject(error);
                    } 
                    
                    controlDeVerdes(apiRequest, dispatchAsistenciaMedica, casosPorClasificacion, serviciosActivos);
                    
                    setRequestServicio(false);
                    
                } else {
                    serviciosEnCursoStorage();
                }
            });
        })
    }

    /**
    * encargado de obtener las atenciones finalizadas, y guardarlas en el contexto
    */
    const historialAtencionesFinalizadas = async () => {
        try {
            const casosHistorialFinalizados = await cargarHistorialFinalizado(socioPrincipal.ic, apiRequest, socioPrincipal, storageManager);
            casosHistorialFinalizados.sort((a, b) => new Date(b.fechaCreacion).getTime() - new Date(a.fechaCreacion).getTime())
            dispatchAsistenciaMedica({
                type: AsistenciaMedicaActionType.actualizarAtencionesFinalizadas, 
                casosFinalizados: casosHistorialFinalizados
            });
            setRequestServicio(false);
        } catch (error: any) {
            setRequestServicio(false);
        }
    }

    /**
     * Encargado de recuperar los casos en curso del storage si no se cumplio el tiempo del delay para realizar su request
     */
    const serviciosEnCursoStorage = () => {
        let casosEnCurso;
        storageManager.getDataStorage("casosEnCurso").then(casos => {
            casosEnCurso = casos;
            if (casosEnCurso) {
                filtrarCasosEnHistorial(JSON.parse(casosEnCurso), storageManager).then(async casosFiltrados => {
                    const casos = await filtrarCasosEnCurso(casosFiltrados);
                    dispatchAsistenciaMedica({
                        type: AsistenciaMedicaActionType.actualizarCasosEnCurso, 
                        casosEnCurso: casos
                    });
                    setRequestServicio(false);
                });
            }
        });
    }

    /**
     * Metodo que verifica la deshabilitacion del modulo a traves del contexto que envia el modulo de ads
     */
    const controlDeVersiones = () => {
        let canalesContacto = {
            canalWhatsapp: true,
            canalTelefono: true,
            redirigirWeb: true
        }
        setDeshabilitarModulos({...canalesContacto});
        if(dataControlDeVersiones && dataControlDeVersiones.asistencia) {
            setDeshabilitarAsistencia(dataControlDeVersiones.asistencia.enabled);
            validarSocioPrincipal();
        } else { 
            //entra al else cuando dataControlDeVersiones es undefined que ocurre cuando falla el servicio o no se pudo completar la peticion, la app se comporta como si estuviera habilitado
            setDeshabilitarAsistencia(true);
            validarSocioPrincipal();
        }
        setLlamarServicios(true);
    }

    const validarSocioPrincipal = () => {
        if(!asistenciaMedica?.usuario) {
            setReintentarError(true);
        }
        setAsistenciaSkeleton(false);
    }

    if(!deshabilitarAsistencia) {
        return <DeshabilitarAsistencia usuario={asistenciaMedica?.usuario} deshabilitarModulos = {deshabilitarModulos} isWeb = { isWeb } />
    } else if(error){
        return <ErrorPage setError={setError}/>
    } else if (reintentarError) {
        return <ErrorReintentar setReintentarError={setReintentarError} setReintentarRequest={setReintentarRequest} />
    }

    return (
        <IonPage className="asistencia-medica">
            {asistenciaSkeleton || skeletonSeguimiento ? (
                <>
                    <IonContent>
                        <SessionHeader socioPrincipal={asistenciaMedica?.usuario} path={HOME} />
                        <IonGrid style={{ height: "100%" }} className="content__grid asistencia_content-container asis-home-container">
                            <AsistenciaMedicaSkeleton />
                        </IonGrid>
                    </IonContent>
                </>
            ) : (
                <IonContent>
                    <SessionHeader socioPrincipal={asistenciaMedica?.usuario} path={HOME} />
                    <IonGrid className="content__grid asistencia_content-container asis-home-container">
                        <div>
                            <IonRow>
                                <IonCol size="12">
                                    <SolicitarAsistencia
                                        socioPrincipal={socioPrincipal}
                                        requestServicioEnCurso={requestServicio}
                                        openModal={openModal}
                                        handleServiciosEnCurso={handleServiciosEnCurso}
                                    />
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size="12">
                                    <HistorialCasos handleServiciosEnCurso={handleServiciosEnCurso} />
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol className="grid__col" size="12">
                                    <Subtitulo titulo="¿Necesitás contactarte con Urgencias?" />
                                    <Button className="button-link" asist btnLink label="Contactanos" contact onClick={openModalContactanos} />
                                </IonCol>
                            </IonRow>
                        </div>
                    </IonGrid>
                    {!asistenciaSkeleton && (
                        <IonFooter className="ion-no-border">
                            <IonToolbar>
                                <TextLink
                                    id="asistencia-medica-text-link-terminos-condiciones"
                                    onClick={openModal}
                                    texto="Ver T&eacute;rminos y condiciones"
                                    className="footer-terminos-condiciones"
                                />
                            </IonToolbar>
                            <Footer />
                        </IonFooter>
                    )}
                </IonContent>
            )}
            <Modal
                className="modal_web-large"
                hideCloseBtn={false}
                //@ts-ignore
                component={<TerminosYCondiciones onClickAccept={acceptTerminosYCondiciones} terminosAceptados={socioPrincipal?.terms} />}
                setShowModal={setShowTerminosModal}
                showModal={showTerminosModal}
                id="terminos-y-condiciones-modal"
            />
            <Modal
                className="modal_web"
                hideCloseBtn={false}
                //@ts-ignore
                component={<Contactanos menorSeisMeses={false} titulo={TITULO_ASISTENCIA_MEDICA} />}
                setShowModal={setShowContactanosModal}
                showModal={showContactanosModal}
                id="contactanos"
            />
        </IonPage>
    );
}
