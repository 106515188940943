import { AxiosResponse } from "axios";
import { getCasosEnCurso } from "../api/salusService";
import { CasoEnCurso } from "../interfaces/salus/servicioencurso.interface";
import { InfoCaso } from "../models/caso.model";
import { CERRADO, EMERGENCIA, ESTADOS_CONFIRMADOS, ESTUDIO, FINISHED_SIGLA, SERVICIOS_COMUNES, TRATAMIENTO_EN_DOMICLIO } from "../utils/constants";
import {  cargarCasosComunes, cargarInfoCasoSegunEstado, convertirInfoCaso } from "../utils/utils";
import { eliminarCasosEnCurso, eliminarCasosYFechaDeCasosEnCurso } from "./storageHelper";

export const obtenerCasosEnCurso = (socio, apiRequest, storageManager) => {
  return new Promise<any[]>((resolve, reject) => {
    eliminarCasosYFechaDeCasosEnCurso(storageManager);

    getCasosEnCurso(socio.ic, apiRequest).then(async (response: AxiosResponse) => {
      let fechaAct = new Date();
      storageManager.setDataStorage("fechaRequestCasosEnCurso", fechaAct.toString());

      if(response?.status === 200) {
        storageManager.setDataStorage("casosEnCurso", JSON.stringify(response.data));
        const casosEnCurso = await filtrarCasos(response.data, storageManager);
        resolve(casosEnCurso);
      } else {
        resolve([]);
      }
    }).catch((error) => {
      if(error?.status === 404) {
        console.error(`No se encontro el socio en Salus: ${error?.data}`);
        eliminarCasosEnCurso(storageManager);
      } else if(error?.status === 500) {
        console.error(`El cliente Salus devolvio un error interno: ${error.data}`);
        eliminarCasosEnCurso(storageManager);
      } else {
        console.error(`Ocurrio un error al invocar el servicio para obtener los casos en curso de un socio de Salus: ${JSON.stringify(error)}`);
        eliminarCasosEnCurso(storageManager);
      }
      reject([]);
    });
  })
}

export const filtrarCasos = (responseCasosEnCurso, storageManager) => {
  return new Promise<any[]>((resolve) => {
    convertirInfoCaso(responseCasosEnCurso).then(casos => {
      let casosAux = casos;

      storageManager.getDataStorage("clasificaciones").then(clasificaciones => {
        storageManager.getDataStorage("servicios").then(async servicios => {
          let serviciosParse = JSON.parse(servicios);
          let clasificacionesParse = JSON.parse(clasificaciones);
          const casosEnCurso = await filtrarTipoServicio(casosAux, serviciosParse, clasificacionesParse);
          resolve(casosEnCurso);
        });
      });
    });
  });
}

const filtrarTipoServicio = (casosAux, serviciosParse, clasificacionesParse) => {
  return new Promise<any[]>((resolve) => {
    let casosEnCurso = [];
    casosAux.forEach((caso) => {
      let clasificacionHabilitada;
      let servicioHabilitado = serviciosParse.find((servicio) => {
        if (
          servicio.descripcion === caso.codigoTipoServicio &&
          servicio.visibleEnCurso
        ) {
          return true;
        }
      });
      if (servicioHabilitado) {
        clasificacionHabilitada = clasificacionesParse.find((clasificacion) => {
          if (
            clasificacion.descripcion === caso.codigoSubTipoServicio &&
            clasificacion.visibleEnCurso
          ) {
            return true;
          }
        });
      }
      if (servicioHabilitado && clasificacionHabilitada) {
        caso.estadoSalus = caso.estado;
        if (
          SERVICIOS_COMUNES.includes(caso.codigoTipoServicio) &&
          caso.estado != CERRADO
        ) {
          casosEnCurso.push(caso);
        } else if (
          caso.codigoTipoServicio === EMERGENCIA &&
          ESTADOS_CONFIRMADOS.includes(caso.estado)
        ) {
          casosEnCurso.push(caso);
        }
      }
    });
    resolve(casosEnCurso);
  });
}

export const filtrarCasosEnHistorial = (casosEnCursoSalus: any, storageManager) => {
  return new Promise<any[]>(async (resolve) => {
    let casos = await casosEnCursoSalus.map(async casoSalus => { 
      let casoSalusAux;
      if(casoSalus?.codigoUltimoEstatus === undefined) {
        casoSalusAux = {...casoSalus, codigoUltimoEstatus: ''};
      } else {
        casoSalusAux = {...casoSalus};
      }
      if (casoSalusAux.codigoUltimoEstatus !== CERRADO) {
        const casoEnCursoInfoCargado = await cargarInfoCasoSegunEstado(casoSalusAux, storageManager);
          if (casoEnCursoInfoCargado) {
            return casoEnCursoInfoCargado
          }
      } else {
        let casoFinalizado = await cargarCasosComunes(casoSalusAux, storageManager);
        if (casoFinalizado.descripcion) {
          casoFinalizado.estado = FINISHED_SIGLA;
          if(casoFinalizado.codigoTipoServicio === ESTUDIO || casoFinalizado.codigoTipoServicio === TRATAMIENTO_EN_DOMICLIO) {
            casoFinalizado = {...casoFinalizado, descripcion: 'Esta asistencia puede no estar finalizada.'}
          }
          return casoFinalizado
        }
      };
    });
    let casosEnCurso = await Promise.all(casos);
    casosEnCurso = casosEnCurso?.filter(caso => ((caso !== undefined) && (Object.keys(caso).length !== 0)));
    resolve(casosEnCurso);
  });
}

/**
 * Retorna los casos en curso que tienen estado finalizado
 */
export const filtrarCasosFinalizados = (casosEnCurso: InfoCaso[]) => {
  return new Promise<InfoCaso[]>((resolve) => {
    let casosFinalizados = casosEnCurso.filter(caso => {
      return (caso.estado === 'F')
    })
    resolve(casosFinalizados);
  })
}

/**
 * Retorna los casos en curso que no esten finalizados
 */
export const filtrarCasosEnCurso = (casosEnCurso: InfoCaso[]) => {
  return new Promise<InfoCaso[]>((resolve) => {
    let casos = casosEnCurso.filter(caso => {
      return (caso.estado !== 'F')
    })
    resolve(casos);
  })
}