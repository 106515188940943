import { getControlVerdes } from "../api/controlVerdesService";
import { AsistenciaMedicaActionType } from "../interfaces/global-state/asistencia.medica.action.type.enum";

/**
 * Encargado de realizar el request a control de verdes y validar por cada servicio en curso si corresponde aplicar el control de verdes
 * @param apiRequest 
 * @param dispatchAsistenciaMedica 
 * @param casosPorClasificacion 
 * @param serviciosActivos 
 */
export const controlDeVerdes = async (apiRequest, dispatchAsistenciaMedica, casosPorClasificacion, serviciosActivos) => {
    try {
        const controles:any = await getControlVerdes(apiRequest);
        const resultadoConServiciosFaltantes = casosPorClasificacion.map(socio => {
            const serviciosFaltantes = serviciosActivos.filter(servicioActivo => {
                return !socio.clasificaciones.some(clasificacion => clasificacion.codigoSubTipoServicio === servicioActivo.descripcion);
            });
        
            return {
                ...socio,
                serviciosFaltantes
            };
        });
        
        const resultadoConServiciosFiltrados = resultadoConServiciosFaltantes.map(socio => ({
            nombre:socio.nombre,
            controlVerdes: filtrarControlVerdes(socio, controles)
        }));

        dispatchAsistenciaMedica({
            type: AsistenciaMedicaActionType.actualizarControlVerdes, 
            controlVerdes: resultadoConServiciosFiltrados
        });
    } catch(error: any) {
        console.error(`ASIS-MED - Hubo un problema asociado al llamado del servicio de control de verdes: ${error}`);
    }
}

/**
 * Encargado de validar si el socio puede solicitar un nuevo servicio
 * @param socio 
 * @param controles 
 */
const filtrarControlVerdes = (socio, controles) => {
    return socio.serviciosFaltantes.filter(servicioFaltante => {
        for (const restriccion of controles.controlVerdes) {
            if (restriccion.servicio === servicioFaltante.servicio.descripcion) {
                if (socio.clasificaciones.some(clasificacion => clasificacion.codigoSubTipoServicio === restriccion.clasificacion)) {
                    return false;
                }
            }
        }
        return true;
    });
}