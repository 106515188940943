const app_version: string = process.env.ASISTENCIA_WEB_VERSION || process.env.asistencia_web_version || '2.3.0';
const environment: string = process.env.REACT_APP_ENVIRONMENT || process.env.react_app_environment || 'TEST';


const sendTracks = (config: any) => {
    const configDataLayer = config
    switch (configDataLayer["origin"]) {
        case "router":
            const logEvent = {
                ...configDataLayer,
                event: "track_pageview",
            }
            //@ts-ignore
            window.dataLayer['push'](logEvent)
            break;
    
        default:
            sendCustomTrackGA(config)
            break;
    } 
}

const tagManagerArgs = {
    dataLayer: {
        app_version,
        environment
        //y todo lo que sea requerido
    },
};

const sendCustomTrackGA = (config:any) => {
    //@ts-ignore
    window.dataLayer['push'](config)
}


const setParamTrackGA = (setupData: any, key:string, value:any) => {
    setupData.tagManagerArgs.dataLayer[key] = value ?? ""
}


export { setParamTrackGA, sendTracks, tagManagerArgs }