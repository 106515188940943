import React from "react"
import { IonCol, IonGrid, IonRow } from "@ionic/react"
import { Skeleton } from "app-principal-componentes-visuales"
import "./DetalleCasoSkeleton.scss"

const DetalleCasoSkeleton = () => {

    return (
            <IonGrid className="dc__skeleton__container content__grid asistencia_content-container">
                <IonCol size="12" className="detalle__caso__skeleton">
                    <IonRow>
                        <IonCol className="title__col" size="12">
                            <Skeleton height={30} className="radio-item" />
                        </IonCol>
                        <IonCol size="12">
                            <IonRow className="detallecaso__skeleton__row">
                                <IonCol size="4">
                                    <IonRow>
                                        <IonCol size="11">
                                            <Skeleton height={5} />
                                        </IonCol>
                                    </IonRow>
                                </IonCol>
                                <IonCol size="4">
                                    <IonRow className="dc__center">
                                        <IonCol size="11">
                                            <Skeleton height={5} />
                                        </IonCol>
                                    </IonRow>
                                </IonCol>
                                <IonCol size="4">
                                    <IonRow className="dc__right">
                                        <IonCol size="11">
                                            <Skeleton height={5} />
                                        </IonCol>
                                    </IonRow>
                                </IonCol>
                            </IonRow>
                        </IonCol>
                        <IonCol size="8">
                            <Skeleton className="radio-item" height={30} />
                        </IonCol>
                        <IonCol size="12">
                            <Skeleton className="radio-item" height={180} />
                            <Skeleton className="radio-item" height={180} />
                            <Skeleton className="radio-item" height={180} />
                        </IonCol>
                    </IonRow>
                </IonCol>
            </IonGrid>
)

}

export default DetalleCasoSkeleton