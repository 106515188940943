import { IonCol, IonContent, IonFooter, IonGrid, IonHeader, IonPage, IonRow, IonToolbar } from '@ionic/react';
import { Button, RadioButtonGroup, Separador, Subtitulo, Titulo } from 'app-principal-componentes-visuales';
import React, { useEffect, useState } from 'react'
import { capitalizeString } from '../../../utils/utils';
import "./Normalizacion.scss"
interface IDatoInvalido {
    nombre: string;
    valor: string;
}

interface IDudaOption {
    barrio: string;
    calle: string;
    codigoPostal: number;
    localidad: string;
    partido: string;
    provincia: string;
    alturaDesde: number;
    alturaHasta: number;
}
interface IOpcion {
    datoInvalido: IDatoInvalido;
    opciones?: {
        duda: IDudaOption[]
    } 
    status: string;
}

interface INormalizacionProps {
    data: IOpcion | any,
    isWeb: boolean,
    onClick: (params?: IDudaOption) => void,
    closeModal: () => void
}

let selectedOption;

const Normalizacion = ({data, isWeb, onClick, closeModal}: INormalizacionProps) => {

    const [message, setMessage] = useState({title: "", subtitle: ""})
    const [options, setOptions] = useState([])
    const [selected, setSelected] = useState(false);
    const [selectedValue, setSelectedValue] = useState("");
    
    useEffect(() => {
        setErrorMessage(data.datoInvalido, data.status)
        if(data.status === "DU"){
            let optionsArray = data.opciones.duda.map( (option, index) => ({...option, texto: `${capitalizeString(option.calle)}, ${capitalizeString(option.localidad)}`, value: index.toString(), onClick: () => handleOptionSelect(index) } ))
            setOptions(optionsArray)
        }
    }, [data])
    
    const setErrorMessage = (invalidData: IDatoInvalido, status: string) => {
        if (status === "NE") {
            switch (invalidData.nombre) {
                case "altura":
                    setMessage({ title: "¡Lo sentimos!", subtitle: `La altura ${invalidData.valor} no existe para la calle ingresada` })
                    return
                case "calle":
                    setMessage({ title: "¡Lo sentimos!", subtitle: `No hemos podido encontrar la calle ${invalidData.valor} ` })
                    return
            }
        } 
        if (status === "DU") {
            switch (invalidData.nombre) {
                case "altura":
                    setMessage({ title: "Encontramos similitud en la altura de la calle", subtitle: `Seleccioná la opción correcta.` })
                    return
                case "calle":
                    setMessage({ title: "Encontramos similitud en el nombre de la calle", subtitle: `Seleccioná la opción correcta.` })
                    return
            }
        }
        if (status === "CO") {
            const calleText = `Encontramos la calle ${capitalizeString(data.direccionNormalizada.calle)} para el domicilio ingresado`;
            const subtitleCorregido = (data.direccionNormalizada.barrio === undefined || data.direccionNormalizada.barrio === '')
                ? calleText.concat(".")
                : calleText + ` en el barrio ${capitalizeString(data.direccionNormalizada.barrio)}`.concat(".");
            setMessage({ title: "¿Es esta tu ubicaci\u00f3n?", subtitle: subtitleCorregido })
        }
    }

    const handleOptionSelect = (e) => {
        selectedOption = data.opciones.duda.at(e);
        setSelectedValue(e.toString());
        setSelected(true);
    }

    const handleSelectClick = () => {
        if(data.status === "DU"){
            onClick(selectedOption);
        }else{
            onClick();
        }
    }

    return (
        <>
            <IonContent fullscreen className={ isWeb ? 'asistencia__normalizacion' : 'asistencia__normalizacion asistencia__normalizacion-mobile'}>
                <IonGrid className='asistencia__normalizacion__header'>
                    <IonRow>
                        <IonCol size="12" className="titulo" >
                            <Titulo titulo={message.title} fontSm id="asistencia-normalizacion-mensaje" />
                        </IonCol>
                        <IonCol size="12" className="titulo" >
                            <Subtitulo className='norm_subtitulo' titulo={message.subtitle} id="subtitulo-mensaje" />
                        </IonCol>
                    </IonRow>   
                </IonGrid>
                <IonGrid className='normalizacion_body'>
                    <IonRow className='radio_container'>
                        <RadioButtonGroup
                            valueSelected={selectedValue}
                            radioList={options}
                            id="radio-button-normalizacion"
                        />  
                    </IonRow>
                </IonGrid>
                <div className='asistencia__normalizacion__footer'>
                    <IonGrid>
                        <IonRow className='norm_button_container' >
                            <IonCol size='6'>
                                <Button
                                    onClick={closeModal}
                                    className='norm_button'
                                    label={data.status === "DU" ? "Volver" : "Omitir"}
                                />
                            </IonCol>
                            <IonCol size='6'>
                                <Button
                                    primary
                                    className='norm_button'
                                    onClick={handleSelectClick}
                                    label={data.status === "DU" ? "Seleccionar" : "Corregir"}
                                    disable={data.status === "DU" && selected === false}
                                />
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </div>
            </IonContent> 
        </>
    )
};

export default Normalizacion;